export default function (client) {
    return {
        calc: async function (delivery_type, address, amount) {
            return await client.get('/delivery/calc', {
                params: {
                    delivery_type: delivery_type,
                    address: address,
                    amount: amount
                }
            });
        }
    }
}
import {api} from "@/shared/services/api";

export const Banner = {
    namespaced: true,
    state: {
        banner: {
            items: []
        }
    },
    getters: {},
    mutations: {
        setBanner: function (state, payload) {
            state.banner.items = payload.items;
        }
    },
    actions: {
        fetchBanner: async function ({commit}) {
            await api.lensmark.gallery.list({
                page: {
                    limit: 10,
                    offset: 0
                }
            }).then(rs => {
                commit('setBanner', {items: rs.data});
            });
        }
    }
}
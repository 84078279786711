<template>
  <div>
    <div class="address">
      <h3 class="address-title">Адрес доставки:</h3>
      <div class="address-body" v-if="model">
        <div class="left">
          <div class="address-body_header">
            <span class="label">{{ model.name }}</span>
            {{ model.street }}, д. {{ model.build }}
          </div>
          <div class="address-body_footer">
            <div class="address-body_footer__item">
              <div class="address-body_footer__item-header">Подъезд</div>
              <div class="address-body_footer__item-footer">{{ model.gate }}</div>
            </div>
            <div class="address-body_footer__item">
              <div class="address-body_footer__item-header">Этаж</div>
              <div class="address-body_footer__item-footer">{{ model.floor }}</div>
            </div>
            <div class="address-body_footer__item">
              <div class="address-body_footer__item-header">Квартира/Офис</div>
              <div class="address-body_footer__item-footer">{{ model.flat }}</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="address-change" v-on:click="changeAddress">Изменить</div>
        </div>
      </div>
      <div class="address-change" v-on:click="changeAddress" v-else>Выберите адрес</div>
    </div>
    <el-drawer
        v-model="drawerState"
        custom-class="address-change-widget"
        :with-header="false"
        :append-to-body="false"
        :lock-scroll="true"
        size="50%"
        direction="btt"
    >
      <el-scrollbar class="addresses" style="height: 300px" v-if="items.length >= 1">
        <div class="addresses-item"
             v-for="(address, key) in items"
             :class="{active: model && (model.id === address.id)}"
             v-on:click="chooseAddress(address.id)"
             :key="key">
          <div class="addresses-item_header">
            <span class="addresses-item_header__name">{{ address.name }}</span>
            <span class="addresses-item_header__address">г. {{ address.city.name }}, {{ address.street }}, д. {{
                address.build
              }}</span>
          </div>
          <div class="addresses-item_footer">
            <div class="addresses-item_footer__items">
              <div class="addresses-item_footer__items-item">
                <div class="addresses-item_footer__items-item_header">Подъезд</div>
                <div class="addresses-item_footer__items-item_footer">{{ address.gate }}</div>
              </div>
              <div class="addresses-item_footer__items-item">
                <div class="addresses-item_footer__items-item_header">Этаж</div>
                <div class="addresses-item_footer__items-item_footer">{{ address.floor }}</div>
              </div>
              <div class="addresses-item_footer__items-item">
                <div class="addresses-item_footer__items-item_header">Кв./Офис</div>
                <div class="addresses-item_footer__items-item_footer">{{ address.flat }}</div>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
      <div class="" v-else>
        <empty-result title="Добавьте адрес" sub-title="У вас нет сохраненных адресов"/>
      </div>
      <div class="bottom">
        <router-link :to="{name: 'CabinetAddressCreate', query: {back: 'checkout'}}"
                     class="btn btn-default add-new-address">Добавить
        </router-link>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import {RahmetApp} from "@/shared/RahmetApp";
import {api} from "@/shared/services/api";
import EmptyResult from "@/components/EmptyResult";

export default {
  name: "ChooseAddress",
  components: {EmptyResult},
  props: {
    value: Number,
    model: Object,
    items: Array,
    error: Boolean
  },
  data: () => {
    return {
      drawerState: false
    }
  },
  methods: {
    ...mapActions({
      fetchAddresses: 'address/FETCH_ADDRESS_LIST'
    }),
    changeAddress: function () {
      RahmetApp.hapticNotification('medium');
      this.drawerState = !this.drawerState;
    },
    chooseAddress: async function (id) {
      if (this.model && (this.model.id === id)) {
        return false;
      }

      RahmetApp.hapticImpact('medium');
      return await api.lensmark.personal.address.choose(id, true).then((rs) => {
        this.$emit('address-id', rs.data.data);
        this.fetchAddresses();
        this.drawerState = false;
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.address {
  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  &-body {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    justify-content: space-between;

    &_header {
      margin-bottom: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;

      .label {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
        padding: 4px 8px;
        background: #029D95;
        border-radius: 4px;
        margin-right: 5px;
      }
    }

    &_footer {
      display: flex;
      flex-direction: row;
      column-gap: 12px;

      &__item {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;

        &-header {
          color: #A6A6A6;
        }

        &-footer {
          color: #000000;
        }
      }
    }
  }

  &-change {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #029D95;
  }
}

.address-change-widget {
  .addresses {
    display: flex;
    flex-direction: column;
    padding: 16px 0;

    &-item {
      padding: 10px 16px;
      box-shadow: inset 0px -0.3px 0px #C4C4C4;

      &.active {
        background: #E3F1F0;
        box-shadow: inset 0px -0.3px 0px #C4C4C4;
      }

      &_header {
        margin-bottom: 8px;

        &__name {
          background: #029D95;
          border-radius: 4px;
          padding: 4px 8px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: #FFFFFF;
          margin-right: 5px;
        }

        &__address {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
        }
      }

      &_footer {
        &__items {
          display: flex;
          flex-direction: row;
          column-gap: 24px;

          &-item {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;

            &_header {
              color: #A6A6A6;
              margin-bottom: 4px;
            }

            &_footer {
              color: black;
            }
          }
        }
      }
    }
  }

  .bottom {
    padding: 0 16px;

    .add-new-address {
      background-color: #029D95;
      color: white;
      text-decoration: none;
    }
  }
}
</style>
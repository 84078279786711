<template>
  <section class="catalog-filter-page">
    <catalog-filter-index />
  </section>
</template>

<script>
import CatalogFilterIndex from "@/components/catalog/filter/Index";

export default {
  name: "CatalogFilterPage",
  components: {
    CatalogFilterIndex
  }
}
</script>

<style lang="scss" scoped>
.catalog-filter-page {
  background-color: #E5E5E5;
}
</style>
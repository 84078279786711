import '@/registerServiceWorker'
import {createApp} from 'vue'
import App from '@/App.vue'

import router from '@/router'
import store from '@/store'

import {RahmetApp} from "@/shared/RahmetApp"
import {installAuthGuard} from "@/middleware/authGuard";

import bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
import ElementPlus from "element-plus"
import "@/assets/scss/index.scss";
import 'bootstrap/dist/css/bootstrap.min.css'
import "element-plus/packages/theme-chalk/src/base.scss"
import "element-plus/packages/theme-chalk/src/index.scss"
import moment from "moment";
import Maska from 'maska'
import Guest from "@/shared/services/Guest";

RahmetApp.setupWebViewApi()
installAuthGuard(router, store)
if (!Guest.checkGuestID()) {
    Guest.initGuestID();
}

const app = createApp(App)
app.use(store)
app.use(router)
app.use(bootstrap)
app.use(ElementPlus)
app.use(Maska)
app.config.globalProperties.$filters = {
    priceFormat: (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    },
    phoneFormat: (value) => {
        //Filter only numbers from the input
        let cleaned = ('' + value).replace(/\D/g, '');

        //Check if the input is of correct
        let match = cleaned.match(/^(7|)?(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            //Remove the matched extension code
            //Change this to format for any country code.
            let intlCode = (match[1] ? '7 ' : '')
            return ['+', intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        }

        return null;
    },
    datetimeToDate: function (value) {
        return moment(value).format('Y.MM.DD');
    }
}
app.mount("#app")
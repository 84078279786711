import {api} from "@/shared/services/api";

export default {
    namespaced: true,
    state: {
        meta: {},
        orders: []
    },
    getters: {
        ordersCount: function (state) {
            return state.orders.length;
        }
    },
    mutations: {
        SET_ORDERS: function (state, payload) {
            state.meta = payload.meta;
            state.orders = payload.data;
        }
    },
    actions: {
        FETCH_ORDERS_BY_API: async function ({commit}, query) {
            return await api.lensmark.order.getList(query).then(rs => {
                commit('SET_ORDERS', rs.data);
                return rs;
            });
        }
    }
}
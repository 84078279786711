<template>
  <section class="catalog-search-page">
    <div class="container">
      <div class="catalog-search-page_form">
        <span class="icon">
          <icon-base icon-name="search" width="16" height="16" view-box="0 0 16 16">
            <icon-search/>
          </icon-base>
        </span>
        <div class="catalog-search-page_form__inputs">
          <input placeholder="Поиск" v-model="queryString" v-on:input="refreshData" autofocus/>
          <button class="btn btn-close" v-on:click="discard"></button>
        </div>
      </div>
      <div class="catalog-search-page_result">
        <div v-if="query.length > 0">
          <section v-if="meta.size > 0 && query.length > 0"
                   class="catalog-search-page_result__list"
                   v-infinite-scroll="scrollLoad"
                   :infinite-scroll-distance="100"
                   :infinite-scroll-delay="500"
                   :infinite-scroll-disabled="infinityScroll">
            <product-item
                :id="product.id"
                :img="product.image.images.small"
                :title="product.name"
                :price="product.prices.price"
                currency="₸"
                :url="`/catalog/product/${product.id}`"
                :key="key"
                v-for="(product, key) in products"
            />
          </section>
          <div class="catalog-search-page_result__empty" v-if="pageLoaded && meta.size <=0">
            <error-catalog-result-empty title="Ничего не нашлось" sub-title="Попробуйте изменить поисковый запрос"/>
            <error-catalog-result-empty-support/>
          </div>
        </div>
        <div v-else>
          <error-catalog-result-empty title="Начните поиск, введите ваш запрос" sub-title='Например "Раствор"'/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {api} from "@/shared/services/api";
import IconBase from "@/components/icons/IconBase";
import IconSearch from "@/components/icons/IconSearch";
import ProductItem from "@/components/catalog/ProductItem";
import ErrorCatalogResultEmpty from "@/components/catalog/error/ResultEmpty";
import ErrorCatalogResultEmptySupport from "@/components/catalog/error/ResultEmptySupport";

export default {
  name: "CatalogSearchPage",
  components: {
    ErrorCatalogResultEmptySupport,
    IconBase,
    ProductItem,
    ErrorCatalogResultEmpty,
    IconSearch
  },
  data() {
    return {
      queryString: "",
      infinityScroll: false,
      loaded: false,
      pageLoaded: false,
      limit: 10,
      offset: 0,
      products: [],
      meta: {}
    }
  },
  created() {
    this.setPageMeta({
      title: "Поиск по каталогу"
    })
    this.queryString = this.query
    if (this.query.length > 0) {
      this.refreshData()
    }
  },
  computed: {
    ...mapState({
      "pageMeta": state => state.pageMeta,
      "query": state => state.catalog.filter.searchQuery
    })
  },
  methods: {
    ...mapActions({
      "setPageMeta": "SET_PAGE_META",
      "setSearchQuery": "catalog/filter/setSearchQuery"
    }),
    refreshData: function () {
      this.meta = []
      this.products = []
      this.pageLoaded = false
      this.loaded = false
      this.offset = 0
      this.setSearchQuery(this.queryString)
      this.fetchProducts()
    },
    fetchProducts: function () {
      api.lensmark.catalog.product.list({
        page: {
          limit: this.limit,
          offset: this.offset,
        },
        query: this.query.replace(/[#]+/, ''),
        is_enabled: 1,
        expand: 'image,category,radius'
      }).then(response => {
        this.addProducts(response.data);
        this.meta = response.meta;
        this.loaded = true;
        this.pageLoaded = true;
      });
    },
    addProducts: function (products) {
      this.products = Array.prototype.concat(this.products, products)
    },
    scrollLoad: function () {
      if (this.meta.size === this.products.length) {
        this.infinityScroll = false;
        return true;
      } else {
        this.loaded = false;
        this.offset += this.limit;
        this.fetchProducts();
      }
    },
    discard: function () {
      this.queryString = "";
      this.setSearchQuery("");
    }
  }
}
</script>

<style lang="scss" scoped>
.catalog-search-page {
  margin-top: 16px;

  &_form {
    display: flex;
    flex-direction: row;
    position: relative;
    background-color: #F2F2F2;
    border-radius: 8px;

    &__inputs {
      width: 100%;

      .btn {
        position: absolute;
        top: 8px;
        right: 8px;
        cursor: pointer;
        z-index: 99;
      }
    }

    .icon {
      margin-left: 13px;
      position: relative;
      top: 9px;
      left: 13px;
      width: 16px;
      height: 16px;
      color: #818C99;
      fill: #818C99;
    }

    input {
      width: 100%;
      height: 40px;
      padding-left: 20px;
      position: relative;
      z-index: 1;
      border: none;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #818C99;
      background-color: transparent;
    }
  }

  &_result {
    &__list {
      padding-bottom: 8px;
      padding-top: 8px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 4px;
      row-gap: 8px;
    }

    &__empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      align-items: center;
      align-content: center;

      &-image {
        margin-top: 116px;
        margin-bottom: 24px;
      }

      &-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #2F2F2F;
        margin-bottom: 10px;
      }

      &-message {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #878787;
      }
    }
  }

  .skeleton-product_list {
    padding-bottom: 8px;
    padding-top: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;
    row-gap: 8px;
  }
}
</style>
<template>
  <g>
    <path
        d="M23.95 58.7059C17.484 58.7059 11.3729 54.7238 9.04677 48.2973C6.05035 40.0966 10.269 30.9496 18.4697 27.9138C20.9536 27.007 23.674 26.731 26.2761 27.1253C26.8281 27.2041 27.183 27.7167 27.1041 28.2292C27.0253 28.7812 26.5127 29.136 26.0002 29.0572C23.7134 28.7023 21.3478 28.9389 19.1399 29.7668C11.9249 32.4084 8.2188 40.412 10.8604 47.5876C13.502 54.8027 21.5055 58.5088 28.6812 55.8672C35.8962 53.2256 39.6023 45.222 36.9608 38.0464C36.4482 36.6271 35.6991 35.2865 34.7528 34.1038C34.398 33.6701 34.4769 33.0787 34.9106 32.7238C35.3443 32.369 35.9357 32.4478 36.2905 32.8815C37.3944 34.222 38.2224 35.7597 38.8138 37.3762C41.8102 45.5769 37.5916 54.7238 29.3909 57.7597C27.5772 58.3905 25.7242 58.7059 23.95 58.7059Z"
        fill="#07A9A1"/>
    <path
        d="M32.9786 32.0142C32.7815 32.0142 32.5449 31.9354 32.3872 31.8171C31.5593 31.1863 30.6525 30.6343 29.7062 30.2006C29.1937 29.964 28.9965 29.3726 29.2331 28.8995C29.4697 28.387 30.0611 28.1898 30.5342 28.4264C31.6381 28.9389 32.6632 29.5304 33.6095 30.2795C34.0432 30.5949 34.122 31.2257 33.8066 31.6594C33.57 31.8959 33.2546 32.0142 32.9786 32.0142Z"
        fill="#07A9A1"/>
    <path
        d="M76.6632 39.3874C74.3765 39.3874 72.1292 38.8748 70.0001 37.8892C66.1363 36.115 63.2188 32.9214 61.76 28.9394C60.3012 24.9573 60.4984 20.6598 62.2725 16.796C64.0467 12.9322 67.2403 10.0146 71.2224 8.55583C73.7062 7.64902 76.4267 7.37304 79.0288 7.7673C79.5808 7.84616 79.9356 8.3587 79.8568 8.87124C79.7779 9.42321 79.2654 9.77807 78.7528 9.69921C76.4661 9.34437 74.1005 9.58093 71.8926 10.4089C68.4231 11.6705 65.6238 14.2333 64.0467 17.6239C62.4697 20.9752 62.312 24.7601 63.6131 28.2691C64.8747 31.7386 67.4374 34.5379 70.8281 36.115C74.1793 37.692 77.9643 37.8497 81.4733 36.5487C88.6883 33.9071 92.3944 25.9035 89.7528 18.7279C89.2403 17.3085 88.4912 15.968 87.5449 14.7852C87.1901 14.3515 87.269 13.7601 87.7027 13.4053C88.1364 13.0505 88.7278 13.1293 89.0826 13.563C90.1865 14.9035 91.0145 16.4412 91.6059 18.0576C94.6023 26.2584 90.3837 35.4053 82.1829 38.4411C80.3693 39.072 78.5163 39.3874 76.6632 39.3874Z"
        fill="#07A9A1"/>
    <path
        d="M85.7313 12.6561C85.5342 12.6561 85.2976 12.5773 85.1399 12.459C84.312 11.8282 83.4051 11.2762 82.4589 10.8425C81.9464 10.6059 81.7492 10.0146 81.9858 9.54143C82.2224 9.02889 82.8137 8.83175 83.2868 9.06831C84.3908 9.58086 85.4159 10.1723 86.3621 10.9214C86.7958 11.2368 86.8747 11.8676 86.5593 12.3013C86.2833 12.5379 86.0073 12.6561 85.7313 12.6561Z"
        fill="#07A9A1"/>
    <path
        d="M23.4374 50.7027C23.0432 50.7027 22.6489 50.4661 22.5306 50.0719L17.9965 37.6525C17.7994 37.14 18.0754 36.588 18.5879 36.3909C19.1005 36.1938 19.6525 36.4697 19.8496 36.9823L24.0682 48.4554L31.0862 45.8927C31.5987 45.6955 32.1507 45.9715 32.3478 46.4841C32.5449 46.9966 32.269 47.5486 31.7564 47.7457L23.7923 50.6633C23.674 50.6633 23.5557 50.7027 23.4374 50.7027Z"
        fill="#07A9A1"/>
    <path
        d="M75.4803 32.2508C75.086 32.2508 74.6917 32.0142 74.5734 31.62L69.8423 18.6486C69.6451 18.1361 69.9211 17.5841 70.4336 17.387C70.9462 17.1899 71.4982 17.4658 71.6953 17.9784L76.4265 30.9497C76.6236 31.4622 76.3477 32.0142 75.8351 32.2114C75.6774 32.2114 75.5985 32.2508 75.4803 32.2508Z"
        fill="#07A9A1"/>
    <path
        d="M82.9715 29.1757C82.8532 29.1757 82.7349 29.1363 82.6167 29.0969L73.588 25.4302C73.1937 25.2725 72.9572 24.8782 72.9572 24.4446C72.9966 24.0109 73.2726 23.656 73.7063 23.5378C74.6131 23.3012 77.1364 22.355 77.8855 20.8173C78.1615 20.2259 78.2009 19.5951 77.8855 18.8854C77.6095 18.2152 77.1758 17.7815 76.5844 17.5449C74.8496 16.9141 72.1292 18.0575 71.2224 18.5306C70.7493 18.7671 70.1579 18.6094 69.8819 18.1363C69.6453 17.6632 69.803 17.0718 70.2761 16.7958C70.4338 16.717 74.3765 14.6274 77.2547 15.6919C78.398 16.0862 79.2654 16.9535 79.7385 18.1363C80.2511 19.3585 80.2116 20.5808 79.6597 21.6847C79.0289 22.9463 77.7672 23.8532 76.6239 24.484L83.4052 27.2439C83.9177 27.441 84.1543 28.0324 83.9572 28.5449C83.7206 28.9392 83.3658 29.1757 82.9715 29.1757Z"
        fill="#07A9A1"/>
    <path
        d="M76.4267 46.5628C66.6884 46.5628 57.8962 40.4122 54.545 31.3047C50.1292 19.2402 56.3192 5.83514 68.3837 1.41937C70.9859 0.473133 73.6668 0 76.3873 0C86.1256 0 94.9177 6.15054 98.269 15.2581C102.685 27.3226 96.4948 40.7276 84.4303 45.1434C81.8676 46.0896 79.1472 46.5628 76.4267 46.5628ZM76.4267 1.93191C73.9429 1.93191 71.459 2.3656 69.0934 3.23298C58.0539 7.29391 52.3765 19.5556 56.4375 30.595C59.5127 38.9534 67.5558 44.5914 76.4662 44.5914C78.95 44.5914 81.4339 44.1577 83.7995 43.2904C94.8389 39.2294 100.516 26.9678 96.4554 15.9283C93.3801 7.5699 85.3371 1.93191 76.4267 1.93191Z"
        fill="#07A9A1"/>
    <path
        d="M23.3979 66C13.6596 66 4.86743 59.8494 1.51618 50.7419C-2.89959 38.6774 3.29039 25.2724 15.3549 20.8566C17.9571 19.9104 20.6381 19.4373 23.3585 19.4373C33.0968 19.4373 41.889 25.5878 45.2402 34.6953C47.3692 40.5304 47.1327 46.8781 44.4911 52.5161C41.889 58.1541 37.2366 62.4516 31.3621 64.5806C28.7993 65.5269 26.1183 66 23.3979 66ZM23.3979 21.4086C20.914 21.4086 18.4302 21.8423 16.0646 22.7097C5.02515 26.7706 -0.652286 39.0322 3.40865 50.0717C6.48392 58.4301 14.5269 64.0681 23.4373 64.0681C25.9212 64.0681 28.4051 63.6344 30.7707 62.767C36.1327 60.7957 40.3908 56.8924 42.7563 51.7276C45.1614 46.5627 45.3979 40.767 43.4266 35.4444C40.3119 27.0071 32.2689 21.4086 23.3979 21.4086Z"
        fill="#07A9A1"/>
    <path
        d="M38.38 24.0505C36.0539 24.0505 33.7277 23.6957 31.5198 22.9072C31.0073 22.71 30.7313 22.1581 30.9284 21.6455C31.1256 21.133 31.6775 20.857 32.1901 21.0541C40.6273 24.0111 50.6811 20.5021 55.57 12.8928C55.846 12.4197 56.4768 12.3014 56.9499 12.5774C57.4231 12.8534 57.5413 13.4842 57.2653 13.9573C53.0861 20.305 45.7528 24.0505 38.38 24.0505Z"
        fill="#07A9A1"/>
    <path
        d="M42.9535 54.448C42.7564 54.448 42.5198 54.3691 42.3621 54.2508C41.9284 53.9354 41.8496 53.3046 42.165 52.8709C48.5521 44.5519 57.1471 41.2401 67.7528 43.0537C68.3048 43.1325 68.6596 43.6451 68.5414 44.1971C68.4625 44.749 67.9499 45.1039 67.398 44.9856C57.5808 43.3297 49.6166 46.3655 43.7026 54.0537C43.5449 54.3297 43.2295 54.448 42.9535 54.448Z"
        fill="#07A9A1"/>
    <path
        d="M56.1585 62.3774C52.5924 62.3774 49.2221 60.1812 47.9392 56.6368C46.2866 52.114 48.6132 47.0693 53.136 45.395C54.5059 44.8949 56.0063 44.7427 57.4414 44.9601C57.7458 45.0036 57.9416 45.2863 57.8981 45.569C57.8546 45.8734 57.5719 46.0691 57.2892 46.0256C56.0281 45.8299 54.7234 45.9604 53.5057 46.417C49.5265 47.8739 47.4825 52.288 48.9394 56.2455C50.3963 60.2247 54.8104 62.2686 58.7678 60.8118C62.7471 59.3549 64.791 54.9408 63.3342 50.9833C63.0515 50.2005 62.6383 49.4612 62.1165 48.8089C61.9208 48.5697 61.9643 48.2435 62.2034 48.0478C62.4426 47.8521 62.7688 47.8956 62.9645 48.1348C63.5733 48.8741 64.03 49.7222 64.3561 50.6137C66.0087 55.1365 63.6821 60.1812 59.1592 61.8555C58.159 62.2034 57.137 62.3774 56.1585 62.3774Z"
        fill="white"/>
    <path
        d="M61.1381 47.6566C61.0293 47.6566 60.8989 47.6131 60.8119 47.5478C60.3553 47.1999 59.8552 46.8955 59.3333 46.6563C59.0506 46.5259 58.9419 46.1997 59.0724 45.9388C59.2028 45.6561 59.529 45.5474 59.7899 45.6778C60.3988 45.9605 60.9641 46.2867 61.486 46.6998C61.7252 46.8738 61.7687 47.2217 61.5947 47.4609C61.4642 47.5913 61.2903 47.6566 61.1381 47.6566Z"
        fill="white"/>
    <path
        d="M85.2305 51.7227C83.9694 51.7227 82.7299 51.44 81.5557 50.8964C79.4248 49.9179 77.8157 48.1566 77.0112 45.9604C76.2066 43.7642 76.3154 41.3941 77.2939 39.2631C78.2724 37.1322 80.0336 35.5231 82.2298 34.7186C83.5997 34.2185 85.1001 34.0662 86.5352 34.2837C86.8396 34.3272 87.0353 34.6098 86.9918 34.8925C86.9483 35.1969 86.6657 35.3927 86.383 35.3492C85.1218 35.1535 83.8172 35.2839 82.5995 35.7406C80.686 36.4364 79.1421 37.8498 78.2724 39.7198C77.4026 41.568 77.3156 43.6555 78.0332 45.5907C78.729 47.5043 80.1424 49.0481 82.0124 49.9179C83.8606 50.7877 85.9481 50.8746 87.8833 50.1571C91.8626 48.7002 93.9065 44.2861 92.4497 40.3286C92.167 39.5458 91.7538 38.8065 91.232 38.1542C91.0363 37.915 91.0798 37.5888 91.319 37.3931C91.5582 37.1974 91.8843 37.2409 92.08 37.4801C92.6889 38.2194 93.1455 39.0675 93.4717 39.959C95.1242 44.4818 92.7976 49.5265 88.2748 51.2008C87.2745 51.5487 86.2525 51.7227 85.2305 51.7227Z"
        fill="white"/>
    <path
        d="M90.2318 36.9799C90.1231 36.9799 89.9926 36.9364 89.9057 36.8711C89.449 36.5232 88.9489 36.2188 88.427 35.9796C88.1444 35.8492 88.0356 35.523 88.1661 35.2621C88.2966 34.9794 88.6227 34.8707 88.8837 35.0011C89.4925 35.2838 90.0579 35.61 90.5797 36.0231C90.8189 36.1971 90.8624 36.545 90.6884 36.7842C90.5362 36.9146 90.384 36.9799 90.2318 36.9799Z"
        fill="white"/>
    <path
        d="M55.8756 57.9632C55.6581 57.9632 55.4407 57.8328 55.3755 57.6153L52.8749 50.7659C52.7661 50.4832 52.9183 50.1788 53.201 50.07C53.4837 49.9613 53.7881 50.1135 53.8968 50.3962L56.2235 56.7238L60.094 55.3104C60.3767 55.2017 60.6811 55.3539 60.7898 55.6366C60.8985 55.9193 60.7463 56.2237 60.4636 56.3324L56.0713 57.9415C56.006 57.9415 55.9408 57.9632 55.8756 57.9632Z"
        fill="white"/>
    <path
        d="M84.5783 47.7868C84.3609 47.7868 84.1434 47.6564 84.0782 47.4389L81.4689 40.285C81.3601 40.0024 81.5123 39.6979 81.795 39.5892C82.0777 39.4805 82.3821 39.6327 82.4909 39.9154L85.1002 47.0693C85.2089 47.3519 85.0567 47.6564 84.774 47.7651C84.687 47.7651 84.6435 47.7868 84.5783 47.7868Z"
        fill="white"/>
    <path
        d="M88.7097 46.0909C88.6445 46.0909 88.5793 46.0691 88.514 46.0474L83.5346 44.0251C83.3171 43.9382 83.1867 43.7207 83.1867 43.4815C83.2084 43.2423 83.3606 43.0466 83.5998 42.9814C84.0999 42.8509 85.4916 42.3291 85.9047 41.4811C86.0569 41.1549 86.0787 40.807 85.9047 40.4156C85.7525 40.0459 85.5133 39.8067 85.1871 39.6763C84.2304 39.3284 82.73 39.9589 82.2299 40.2199C81.969 40.3503 81.6428 40.2634 81.4906 40.0024C81.3601 39.7415 81.4471 39.4153 81.708 39.2631C81.795 39.2196 83.9695 38.0672 85.5568 38.6543C86.1874 38.8717 86.6658 39.3501 86.9267 40.0024C87.2094 40.6765 87.1876 41.3506 86.8832 41.9594C86.5353 42.6552 85.8395 43.1554 85.2089 43.5033L88.9489 45.0254C89.2316 45.1341 89.3621 45.4603 89.2534 45.7429C89.1229 45.9604 88.9272 46.0909 88.7097 46.0909Z"
        fill="white"/>
    <path
        d="M85.1001 55.6801C79.7292 55.6801 74.8802 52.288 73.0319 47.265C70.5966 40.6112 74.0104 33.2182 80.6642 30.7828C82.0993 30.2609 83.5779 30 85.0783 30C90.4492 30 95.2981 33.3921 97.1464 38.4151C99.5818 45.0688 96.1679 52.4619 89.5141 54.8973C88.1008 55.4191 86.6004 55.6801 85.1001 55.6801ZM85.1001 31.0655C83.7302 31.0655 82.3603 31.3047 81.0556 31.783C74.9672 34.0227 71.836 40.7852 74.0757 46.8736C75.7717 51.4834 80.2076 54.5929 85.1218 54.5929C86.4917 54.5929 87.8616 54.3537 89.1662 53.8753C95.2547 51.6356 98.3858 44.8731 96.1462 38.7847C94.4501 34.1749 90.0143 31.0655 85.1001 31.0655Z"
        fill="white"/>
    <path
        d="M55.854 66.4C50.4831 66.4 45.6341 63.0079 43.7858 57.985C41.3505 51.3312 44.7643 43.9381 51.4181 41.5028C52.8532 40.9809 54.3319 40.72 55.8322 40.72C61.2031 40.72 66.0521 44.1121 67.9003 49.135C69.0745 52.3532 68.9441 55.854 67.4872 58.9635C66.0521 62.0729 63.4862 64.4431 60.2463 65.6173C58.8329 66.1391 57.3543 66.4 55.854 66.4ZM55.854 41.8072C54.4841 41.8072 53.1142 42.0464 51.8095 42.5247C45.7211 44.7644 42.5899 51.5269 44.8296 57.6153C46.5256 62.2251 50.9615 65.3346 55.8757 65.3346C57.2456 65.3346 58.6155 65.0954 59.9201 64.617C62.8774 63.5298 65.2258 61.3771 66.5304 58.5286C67.8568 55.6801 67.9873 52.4837 66.9001 49.5482C65.1823 44.8949 60.7464 41.8072 55.854 41.8072Z"
        fill="white"/>
    <path
        d="M64.1169 43.2641C62.8339 43.2641 61.551 43.0684 60.3333 42.6335C60.0507 42.5248 59.8985 42.2203 60.0072 41.9377C60.1159 41.655 60.4203 41.5028 60.703 41.6115C65.3563 43.2423 70.9011 41.3071 73.5974 37.1104C73.7496 36.8495 74.0975 36.7842 74.3585 36.9365C74.6194 37.0887 74.6846 37.4366 74.5324 37.6975C72.2275 41.1984 68.1831 43.2641 64.1169 43.2641Z"
        fill="white"/>
    <path
        d="M66.6391 60.0289C66.5304 60.0289 66.3999 59.9854 66.3129 59.9202C66.0737 59.7463 66.0302 59.3984 66.2042 59.1592C69.7268 54.5711 74.4671 52.7446 80.3163 53.7448C80.6207 53.7883 80.8164 54.071 80.7512 54.3754C80.7077 54.6798 80.425 54.8755 80.1206 54.8103C74.7062 53.897 70.3139 55.5713 67.0522 59.8115C66.9652 59.9637 66.7913 60.0289 66.6391 60.0289Z"
        fill="white"/>
  </g>
</template>
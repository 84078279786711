export function cart(client) {
    return {
        get: async function () {
            return await client.get('/cart').then(response => response.data);
        },
        add: async function (data) {
            return await client.post('/cart', data).then(response => response.data);
        },
        update: async function (data) {
            return await client.put('/cart', data).then(response => response.data);
        }
    }
}
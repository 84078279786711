<template>
  <div class="checkout-block block-grey">
    <h3 class="title">Итого:</h3>
    <div class="body" v-if="state">
      <p>Стоимость доставки:
        <strong>{{ deliveryCost === 0 ? 'бесплатно' : $filters.priceFormat(deliveryCost) + ' ₸' }}</strong>
      </p>
      <p v-if="amountBeforeFreeShipping !== 0">До бесплатной доставки: <strong>осталось
        {{ $filters.priceFormat(amountBeforeFreeShipping) }} ₸</strong></p>
      <p>Сроки доставки:
        <strong>{{ calcSchedule(schedule) }}</strong>
      </p>
      <p>Итого: <strong>{{ $filters.priceFormat(cartTotalAmount + deliveryCost) }} ₸</strong></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalcDeliveryAmount",
  props: [
    'state',
    'deliveryCost',
    'cartTotalAmount',
    'schedule',
    'amountBeforeFreeShipping'
  ],
  methods: {
    calcSchedule: function (schedule) {
      var result = '';
      if (schedule.minDay === 0) {
        result = `сегодня до ${schedule.time}`;
      } else if (schedule.minDay === 1) {
        result = `завтра до ${schedule.time}`;
      } else if (schedule.minDay >= 2 && schedule.minDay <= 3 && schedule.maxDay <= 3) {
        result = `в понедельник до ${schedule.time}`;
      } else {
        result = `от ${schedule.minDay} до ${schedule.maxDay} рабочих дней.`;
      }
      return result;
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
import {api} from "@/shared/services/api";

export default {
    namespaced: true,
    state: {
        cities: []
    },
    getters: {},
    mutations: {
        setCities: function (state, payload) {
            state.cities = payload.data;
        }
    },
    actions: {
        getList: async function ({commit}) {
            return await api.lensmark.personal.city.getList().then(rs => {
                commit('setCities', rs.data);
                return rs;
            })
        }
    }
}
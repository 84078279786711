<template>
  <div class="filter-item" v-if="type === 'buttons'">
    <div class="filter-item_header">
      <h3 class="filter-item_header__title">{{ title }}</h3>
    </div>
    <div class="filter-item_body">
      <div class="filter-item_body__els" :class="direction">
        <button class="els_el btn" :class="selectedItems.includes(value) ? 'active' : ''"
                :key="value"
                v-for="(label, value) in items"
                v-on:click="select(value)"
                :data-value="value">
          {{ label }}
        </button>
      </div>
    </div>
    <div class="filter-item_footer">
      <button class="filter-item_footer__item btn">Сбросить</button>
    </div>
  </div>
  <div class="filter-item" v-if="type === 'checkbox'">
    <div class="filter-item_header">
      <h3 class="filter-item_header__title">{{ title }}</h3>
    </div>
    <div class="filter-item_body">
      <div class="filter-item_body__els"
           :class="direction"
           :key="value"
           v-for="(label, value) in items">
        <div class="els_el checkbox" :data-value="value" :class="selectedItems.includes(value) ? 'active' : ''" v-on:click="choose">
          <span class="checkbox-value"></span>
          <span class="checkbox-label">{{ label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";

export default {
  name: "CatalogFilterElement",
  props: ['type', 'title', 'items', 'direction'],
  data() {
    return {
      selectedItems: []
    }
  },
  methods: {
    select: function (val) {
      if (!this.selectedItems.includes(val)) {
        this.selectedItems = lodash.concat(this.selectedItems, val);
      } else {
        var i = this.selectedItems.indexOf(val)
        this.selectedItems.splice(i, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-item {
  background-color: #FFFFFF;
  padding: 16px;
  margin-bottom: 8px;

  &_header {
    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 20px;
      color: #333333;
    }
  }

  &_body {
    &__els {
      display: flex;
      justify-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;

      &.flex-row {
        flex-direction: row;
        align-content: center;
      }

      &.flex-column {
        flex-direction: column;
        align-items: flex-start;
        align-content: flex-start;
      }

      .els {
        &_el {
          flex: 1;

          &.btn {
            background-color: #F2F2F2;
            border-radius: 4px;
            margin-bottom: 12px;
            margin-right: 8px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #A6A6A6;
            padding: 7px 12px;

            &.active {
              background-color: #029D95;
              color: #FFFFFF;
            }
          }

          &.checkbox {
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            justify-items: flex-start;
            align-content: center;
            align-items: center;

            .checkbox {
              &-label {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.1px;
                color: #000000;
              }

              &-value {
                position: relative;
                width: 48px;
                height: 44px;
                padding: 11px;

                &:before {
                  display: block;
                  content: '';
                  width: 22px;
                  height: 22px;
                  border: 1px solid #B8C1CC;
                  border-radius: 100%;
                }
              }
            }

            &.active {
              .checkbox {
                &-value {
                  position: relative;

                  &:before {
                    background-color: #029D95;
                  }

                  &:after {
                    position: absolute;
                    display: block;
                    top: 13px;
                    left: 13px;
                    content: '';
                    width: 18px;
                    height: 18px;
                    border-radius: 20px;
                    border: 2px solid white;
                  }
                }
              }
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  &_footer {
    border-top: 1px solid #E7E7E7;

    &__item {
      &.btn {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #029D95;
        padding: 0;
        text-align: left;
      }
    }
  }
}
</style>
import {api} from "@/shared/services/api";
import {RahmetApp} from "@/shared/RahmetApp";

const trackIdOrAuthorize = trackId => trackId !== undefined ? Promise.resolve(trackId) : RahmetApp.authorize();

export default {
    loginByTrackId: async function (trackId, callback) {
        let result = {
            state: false,
            message: ""
        };
        await trackIdOrAuthorize(trackId).then(async trackId => {
            await api.ecosystem.auth.getTokenByTrackId(trackId).then(async rs => {
                await api.lensmark.user.login({
                    access_token: rs.data.access_token,
                    refresh_token: rs.data.refresh_token,
                    token_type: rs.data.token_type,
                    expires_in: rs.data.expires_in,
                }).then(res => {
                    result.state = true;
                    result.message = res.data.message;
                }).catch(async err => {
                    result.state = false;
                    result.message = err.response.data.message;
                    result.errors = err.response.data.errors;

                    if (err.response.data.status !== 200 &&
                        err.response.data.errors.user === 'Not found.') {
                        await api.lensmark.user.registration({
                            access_token: rs.data.access_token,
                            refresh_token: rs.data.refresh_token,
                            token_type: rs.data.token_type,
                            expires_in: rs.data.expires_in,
                        }).then(res => {
                            result.state = true;
                            result.message = res.data.message;
                        }).catch(err => {
                            result.state = false;
                            result.message = err.response.data.message;
                            result.errors = err.response.data.errors
                        });
                    }
                });
            });
        }).catch(err => {
            result.state = false;
            result.message = err.response.data.errors[0].title;
            result.errors = err.response.data.errors
        }).finally(() => {
            callback(result);
        });
    }
}
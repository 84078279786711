<template>
  <section class="router-top-bar-navigation">
    <div class="container">
      <div class="router-top-bar-navigation_items">
        <button class="back" v-on:click="back">
          <img src="../../assets/icons/icon-back.svg" alt="Назад">
        </button>
        <div class="router-top-bar-navigation_items__title">{{ pageMeta.title ? pageMeta.title : $route.meta.title }}</div>
        <div class="pull-right"
             v-if="$route.name === 'CatalogFilter' || $route.name === 'CartIndex' || $route.name === 'CatalogSearch'">
<!--          <button class="refresh" v-if="$route.name === 'CatalogSearch'">Сбросить</button>-->
<!--          <button class="refresh" v-if="$route.name === 'CatalogFilter'">Сбросить</button>-->
          <button class="refresh" v-if="$route.name === 'CartIndex'">Очистить корзину</button>
        </div>
        <div class="pull-right" v-if="$route.name === 'CatalogIndex'" style="margin-right: 70px">
<!--          <button class="filter" v-on:click="openFilterPage">-->
<!--            <img src="../../assets/icons/icon-filter.svg" alt="Фильтр">-->
<!--          </button>-->
          <button class="search" v-on:click="openSearchPage">
            <img src="../../assets/icons/icon-search.svg" alt="Поиск">
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "RouterTopBar",
  computed: {
    ...mapState({
      pageMeta: state => state.pageMeta
    })
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    openFilterPage: function () {
      this.$router.push('/catalog/filter/' + this.$route.params.id)
    },
    openSearchPage: function () {
      this.$router.push('/catalog/search')
    }
  }
}
</script>

<style lang="scss" scoped>
.router-top-bar-navigation {
  flex: 1;

  &_items {
    display: flex;
    justify-content: flex-start;
    justify-items: flex-start;
    align-items: center;
    align-content: center;

    &__title {
      padding-top: 16px;
      text-align: center;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 16px;
      margin-left: -5px;
    }

    button {
      border: none;
      background-color: transparent;
      height: 54px;

      &.back {
        padding-left: 0;
        padding-right: 15px;
      }

      &.refresh {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #989898;
      }
    }

    > div {
      button {
        &:last-child {
          margin-left: 6px;
        }
      }
    }
  }
}
</style>
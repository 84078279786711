<template>
  <div class="error-page">
    <h2 class="error-page_title">Не нашли то, что искали?</h2>
    <h3 class="error-page_subtitle">Обратитесь к нам напрямую и мы обязательно что-нибудь подберем :)</h3>
    <div class="error-page_buttons">
      <a href="https://wa.me/77052632217" class="btn">Написать в WhatsApp</a>
      <a href="tel:+77273132320" class="btn">+7 (727) 313-23-20 </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorCatalogResultEmptySupport"
}
</script>

<style lang="scss" scoped>
.error {
  &-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: -18px;
    padding-bottom: 28px;

    &_img {
    }

    &_title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }

    &_subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
    }

    &_buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .btn {
        margin: 0 5px;
        color: #FFFFFF;
        background-color: #029D95;
        padding: 7px 12px;
        border-radius: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
}
</style>
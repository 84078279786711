import {createRouter, createWebHistory} from 'vue-router'
import Home from '@/views/Home'

import CatalogIndex from "@/views/catalog/Index";
import CatalogFilter from "@/views/catalog/Filter";
import CatalogSearch from "@/views/catalog/Search";
import CatalogDetail from "@/views/catalog/Detail";
import CatalogAddToCart from "@/views/catalog/AddToCart";

import CartIndex from "@/views/cart/Index";
// import CartDis from "@/views/cart/Dis";
import CheckoutIndex from "@/views/checkout/Index";
import ThankYou from "@/views/checkout/ThankYou";

import CabinetIndex from "@/views/cabinet/Index";
import CabinetProfileChoose from "@/views/cabinet/ProfileChoose"

import AddressIndex from "@/views/address/Index";
import AddressCreate from "@/views/address/Create";
import AddressUpdate from "@/views/address/Update";

import CabinetOrderIndex from "@/views/cabinet/order/Index";
import CabinetOrderDetail from "@/views/cabinet/order/Detail";

import ProfileIndex from "@/views/profile/Index";

import CabinetSupportIndex from "@/views/cabinet/support/Index";
import CabinetSupportChat from "@/views/cabinet/support/Chat";
// import CabinetSupportFaq from "@/views/cabinet/support/Faq";
import BlogPage from "@/views/blog/Post1";

import BannerDetail from "@/views/banner/Detail";
import Error from "@/views/Error";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'Главная'
        }
    },
    {
        path: '/catalog/:id',
        name: 'CatalogIndex',
        component: CatalogIndex,
        meta: {
            title: ''
        },
        props: true
    },
    {
        path: '/catalog/filter/:id',
        name: 'CatalogFilter',
        component: CatalogFilter,
        meta: {
            title: 'Фильтр'
        }
    },
    {
        path: '/catalog/product/:id',
        name: 'CatalogDetail',
        component: CatalogDetail,
        props: true,
        meta: {
            protected: false,
            title: ''
        }
    },
    {
        path: '/catalog/product/add-to-cart/:id',
        name: 'CatalogAddToCart',
        component: CatalogAddToCart,
        meta: {
            protected: false,
            title: 'Выбор параметров'
        },
        props: true
    },
    {
        path: '/catalog/search',
        name: 'CatalogSearch',
        component: CatalogSearch,
        meta: {
            title: 'Поиск'
        }
    },
    {
        path: '/cart',
        name: 'CartIndex',
        component: CartIndex,
        meta: {
            protected: false,
            title: 'Корзина'
        }
    },
    {
        path: '/checkout',
        name: 'CheckoutIndex',
        component: CheckoutIndex,
        meta: {
            protected: true,
            title: 'Оформление заказа'
        }
    },
    {
        path: '/order/thank/:id',
        name: 'ThankYouPage',
        component: ThankYou,
        meta: {
            protected: true,
            title: 'Спасибо за заказ'
        }
    },
    {
        path: '/cabinet',
        name: 'CabinetIndex',
        component: CabinetIndex,
        meta: {
            protected: true,
            title: 'Профиль'
        }
    },
    {
        path: '/cabinet/profile-choose',
        name: 'CabinetProfileChoose',
        component: CabinetProfileChoose,
        meta: {
            title: 'Выберите профиль'
        }
    },
    {
        path: '/cabinet/address',
        name: 'CabinetAddress',
        component: AddressIndex,
        meta: {
            protected: true,
            title: 'Мои адреса'
        }
    },
    {
        path: '/cabinet/address/create',
        name: 'CabinetAddressCreate',
        component: AddressCreate,
        meta: {
            protected: true,
            title: 'Добавление адреса'
        },
        props: true
    },
    {
        path: '/cabinet/address/update/:id',
        name: 'CabinetAddressUpdate',
        component: AddressUpdate,
        meta: {
            protected: true,
            title: 'Редактирование адреса'
        },
        props: true
    },
    {
        path: '/cabinet/support/chat',
        name: 'CabinetSupportChat',
        component: CabinetSupportChat,
        meta: {
            protected: true,
            title: 'Chat'
        }
    },
    {
        path: '/cabinet/support/faq',
        name: 'CabinetSupportIndex',
        component: CabinetSupportIndex,
        meta: {
            protected: true,
            title: 'Обратная связь'
        }
    },
    {
        path: '/cabinet/order',
        name: 'CabinetOrderIndex',
        component: CabinetOrderIndex,
        meta: {
            protected: true,
            title: 'Мои заказы'
        }
    },
    {
        path: '/cabinet/order/:id',
        name: 'CabinetOrderDetail',
        component: CabinetOrderDetail,
        meta: {
            protected: true,
            title: 'Заказ #'
        }
    },
    {
        path: '/user/profile',
        name: 'ProfileIndex',
        component: ProfileIndex,
        meta: {
            protected: true,
            title: 'Профиль'
        }
    },
    {
        path: '/banner/:id',
        name: 'BannerDetail',
        component: BannerDetail,
        meta: {
            title: 'Акция'
        }
    },
    {
        path: '/post/1',
        name: 'BlogPage',
        component: BlogPage,
        meta: {
            title: 'Уважаемые клиенты'
        }
    },
    {
        path: '/error',
        name: 'Error',
        component: Error,
        meta: {
            title: 'Что то пошло не так'
        }
    },
    {
        path: '/rahmet',
        name: 'Rahmet'
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    mode: 'history',
    routes
})

router.beforeEach((to) => {
    // to.meta.protected = !!(to.meta.protected && process.env.NODE_ENV === 'production');
    if (to.name === 'Rahmet') {
        return window.RahmetApp.backToRahmetApp()
    }
})

export default router

<template>
  <section class="catalog-index-page">
    <div class="container">
      <catalog-filter-on-page-index :fetch-products="refreshData"/>
    </div>
    <section class="block-grey">
      <div class="container">
        <div v-if="!pageLoaded" class="skeleton-product_list">
          <skeleton-product-item v-for="item in 3" :key="item"/>
        </div>
        <div v-else>
          <section
              v-if="meta.size > 0"
              class="page-catalog_products"
              v-infinite-scroll="scrollLoad"
              :infinite-scroll-distance="100"
              :infinite-scroll-delay="500"
              :infinite-scroll-disabled="infinityScroll">
            <product-item
                :id="product.id"
                :img="product.image.images.small"
                :title="product.name"
                :price="product.prices.price"
                currency="₸"
                :key="key"
                v-for="(product, key) in products"
            />
          </section>
          <div v-else>
            <error-catalog-result-empty title="Ничего не нашлось" sub-title="Попробуйте изменить параметры фильтра"/>
            <error-catalog-result-empty-support/>
          </div>
        </div>
        <h2 v-if="!loaded" style="text-align: center"><i class="el-icon-loading"></i></h2>
      </div>
    </section>
  </section>
</template>

<script>
import lodash from "lodash";
import {mapActions, mapState} from "vuex";
import {api} from "@/shared/services/api";
import ProductItem from "@/components/catalog/ProductItem";
import CatalogFilterOnPageIndex from "@/components/catalog/filterOnPage/Index";
import SkeletonProductItem from "@/components/skeleton/ProductItem";
import ErrorCatalogResultEmpty from "@/components/catalog/error/ResultEmpty";
import ErrorCatalogResultEmptySupport from "@/components/catalog/error/ResultEmptySupport";

export default {
  name: "CatalogIndexPage",
  props: ['title'],
  data() {
    return {
      products: [],
      meta: {},
      infinityScroll: false,
      limit: 10,
      offset: 0,
      loaded: false,
      pageLoaded: false
    }
  },
  computed: {
    ...mapState({
      filter: state => state.catalog.filter.filter
    })
  },
  components: {
    ErrorCatalogResultEmptySupport,
    ErrorCatalogResultEmpty,
    SkeletonProductItem,
    CatalogFilterOnPageIndex,
    ProductItem
  },
  beforeMount() {
    this.setPageMeta({
      title: ''
    });
    api.lensmark.catalog.category.one(this.$route.params.id).then(rs => {
      this.setPageMeta({
        title: rs.data.name
      });
    });
  },
  created() {
    this.refreshData()
  },
  methods: {
    ...mapActions({
      setPageMeta: "SET_PAGE_META"
    }),
    refreshData: function () {
      this.meta = {}
      this.products = []
      this.pageLoaded = false
      this.loaded = false
      this.offset = 0
      this.fetchProducts()
    },
    fetchProducts: function () {
      let filter = lodash.has(this.filter, this.$route.params.id) ? lodash.pickBy(lodash.mapValues(this.filter[this.$route.params.id], (o) => {
        return o.toString()
      }), lodash.identity()) : []
      api.lensmark.catalog.product.list({
        page: {
          limit: this.limit,
          offset: this.offset,
        },
        category_id: this.$route.params.id,
        is_enabled: 1,
        expand: 'image,category,radius',
        filter: filter
      }).then(response => {
        this.addProducts(response.data);
        this.meta = response.meta;
        this.loaded = true;
        if (this.products.length <= this.limit) {
          this.pageLoaded = true;
        }
      });
    },
    addProducts: function (products) {
      this.products = Array.prototype.concat(this.products, products)
    },
    scrollLoad: function () {
      if (this.meta.size === this.products.length) {
        this.infinityScroll = false;
        return true;
      } else {
        this.loaded = false;
        this.offset += this.limit;
        this.fetchProducts();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-catalog {
  &_products {
    padding-bottom: 8px;
    padding-top: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;
    row-gap: 8px;
  }
}

.skeleton-product_list {
  padding-bottom: 8px;
  padding-top: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 4px;
  row-gap: 8px;
}
</style>
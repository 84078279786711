export default function (client) {
    return {
        getList: async function (query) {
            return await client.get('/order', {params: query});
        },
        getOne: async function (id) {
            return await client.get(`/order/${id}`);
        },
        create: async function (
            state_id,
            address_id,
            payment_type_id,
            delivery_type_id,
            delivery_cost
        ) {
            return await client.post('/order', {
                state_id: state_id,
                address_id: address_id,
                payment_type_id: payment_type_id,
                delivery_type_id: delivery_type_id,
                delivery_cost: delivery_cost
            })
        }
    }
}
<template>
  <HeaderBar></HeaderBar>
  <router-view :key="$route.path"/>
  <BasketBottomWidget/>
  <BottomBar></BottomBar>
</template>
<script>
import BottomBar from "@/components/BottomBar.vue";
import HeaderBar from "@/components/HeaderBar";
import BasketBottomWidget from "@/views/cart/components/BasketBottomWidget";

export default {
  components: {HeaderBar, BottomBar, BasketBottomWidget},
  data() {
    return {
      pageIsLoaded: false,
    }
  }
}
</script>
export function catalog(client) {
    return {
        category: {
            list: async function (query) {
                return await client.get(`/category`, {
                    params: query
                }).then(response => response.data);
            },
            one: async function (id) {
                return await client.get(`/category/${id}`).then(response => response.data);
            }
        },
        product: {
            list: async function (query) {
                return await client.get("/product", {
                    params: query
                }).then(response => response.data);
            },
            one: async function (id) {
                return await client.get(`/product/${id}`).then(response => response.data);
            }
        },
        filter: {
            one: async function list(id) {
                switch (id) {
                    case '1':
                        id = 'lenses'
                        break;
                    case '2':
                        id = 'fluids'
                        break;
                    case '3':
                        id = 'drops'
                        break;
                }
                return await client.get(`/filter/${id}`).then(response => response.data);
            }
        }
    }
}
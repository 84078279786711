<template>
  <router-link :to="{name: 'CatalogDetail', params: {id: id}}" class="product">
    <el-image class="product-img" :src="img" lazy fit="contain">
      <template #placeholder>
        <div class="image-slot">
          <i class="el-icon-picture-outline"></i>
        </div>
      </template>
    </el-image>
    <div class="product-title">{{ title }}</div>
    <div class="product-price">{{ $filters.priceFormat(price) }} {{ currency }}</div>
    <div class="product-btn">Выбрать</div>
  </router-link>
</template>

<script>
export default {
  name: "CatalogProductItem",
  props: ['id', 'img', 'title', 'price', 'currency'],
}
</script>

<style lang="scss" scoped>
.product {
  background-color: white;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.1));
  height: 273px;
  border-radius: 8px;
  text-decoration: none;
  color: #000000;

  &:nth-child(2n) {
    margin-left: 4px;
    margin-right: 0;
  }

  &-img {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    width: inherit;
    height: 160px;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 8px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &-title {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;
    height: 2.5em;
  }

  &-price {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 19px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  &-btn {
    width: inherit;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #029D95;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    height: 30px;
    border: 1px solid #029D95;
    box-sizing: border-box;
    border-radius: 4px;
    text-decoration: none;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 8px;

    &:hover {
      color: #029D95;
    }
  }
}
</style>
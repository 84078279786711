<template>
  <section class="catalog-detail-page" v-if="product">
    <div class="container">
      <div class="catalog-detail-page_image">
        <img :src="product.images.links.big" :alt="product.name">
      </div>
      <div class="catalog-detail-page_title">
        {{ product.name }}
      </div>
      <div class="catalog-detail-page_rating">
        <el-rate disabled :model-value="product.rating"></el-rate>
        <div class="catalog-detail-page_rating__reviews">
          {{ product.reviews.length }} отзывов
        </div>
      </div>
      <div class="catalog-detail-page_price">
        <span class="catalog-detail-page_price__val">{{ $filters.priceFormat(product.prices.price) }} ₸</span>
        <span class="catalog-detail-page_price__info"
              v-if="product.category.name === 'Линзы' && lensesCountInBox.length > 0">
          Цена за 1 упаковку ({{ lensesCountInBox[0].value }})
        </span>
      </div>
    </div>
    <div class="catalog-detail-page_action"
         v-if="product.category.id === 1 && Object.keys(product.properties).length > 0">
      <router-link
          :to="{name: 'CatalogAddToCart', params: {id: product.id}}"
          class="btn-default btn-success catalog-detail-page_action__select">
        Выбрать параметры
      </router-link>
    </div>
    <div class="catalog-detail-page_action" v-else>
      <button class="btn-default btn-success catalog-detail-page_action__select" v-on:click="addToCart" v-if="!cartItem">
        Добавить в корзину
      </button>
      <el-input-number class="active" size="large" v-else v-model="cartItem.quantity" v-on:change="changeCartItemQuantity" :min="0" :max="10"></el-input-number>
    </div>
    <div class="container">
      <div class="catalog-detail-page_characteristics">
        <div class="catalog-detail-page_characteristics__title">Характеристики</div>
        <div class="catalog-detail-page_characteristics__items">
          <div class="catalog-detail-page_characteristics__items-item"
               v-for="property in product.features"
               :key="property.id">
            <span class="icon">
              <icon-base icon-name="purpose" width="28" height="28" view-box="0 0 28 28"
                         v-if="property.feature.name === 'Назначение'">
                <icon-purpose/>
              </icon-base>
              <icon-base icon-name="mode" width="28" height="28" view-box="0 0 28 28"
                         v-if="property.feature.name === 'Режим ношения'">
                <icon-mode/>
              </icon-base>
              <icon-base icon-name="material" width="28" height="28" view-box="0 0 28 28"
                         v-if="property.feature.name === 'Материал'">
                <icon-material/>
              </icon-base>
              <icon-base icon-name="water" width="28" height="28" view-box="0 0 28 28"
                         v-if="property.feature.name === 'Влагосодержание'">
                <icon-water/>
              </icon-base>
              <icon-base icon-name="diameter" width="28" height="28" view-box="0 0 28 28"
                         v-if="property.feature.name === 'Диаметр'">
                <icon-diameter/>
              </icon-base>
              <icon-base icon-name="box" width="28" height="28" view-box="0 0 28 28"
                         v-if="property.feature.name === 'Упаковка'">
                <icon-box/>
              </icon-base>
            </span>
            <span class="title">{{ property.feature.name }}</span>
            <span class="value">{{ property.value }}</span>
          </div>
        </div>
      </div>

      <el-tabs class="catalog-detail-page_info" model-value="description">
        <el-tab-pane class="catalog-detail-page_info__item" label="Описание" name="description">
          <Description :description="product.description"/>
        </el-tab-pane>
        <el-tab-pane class="catalog-detail-page_info__item" label="Отзывы" name="reviews">
          <Comment v-for="comment in product.reviews" :key="comment.id" :comment="comment"/>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>

<script>
import lodash from "lodash";
import {api} from "@/shared/services/api";
import {mapActions, mapGetters} from "vuex";
import IconBase from "@/components/icons/IconBase";
import IconPurpose from "@/components/icons/characteristics/IconPurpose";
import Comment from "@/components/catalog/Comment";
import Description from "@/components/catalog/Description";
import IconMode from "@/components/icons/characteristics/IconMode";
import IconMaterial from "@/components/icons/characteristics/IconMaterial";
import IconWater from "@/components/icons/characteristics/IconWater";
import IconDiameter from "@/components/icons/characteristics/IconDiameter";
import IconBox from "@/components/icons/characteristics/IconBox";
import {NotifyCenter} from "@/shared/services/notify";
import {RahmetApp} from "@/shared/RahmetApp";

export default {
  name: "CatalogDetailPage",
  props: ['id'],
  data() {
    return {
      product: null,
      pageLoaded: false
    }
  },
  components: {
    IconBox,
    IconDiameter,
    IconWater,
    IconMaterial,
    IconMode,
    Description,
    Comment,
    IconBase,
    IconPurpose
  },
  beforeMount() {
    this.setPageMeta({
      title: ""
    });
    this.fetchOne(this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      getCartItem: 'cart/cartItem'
    }),
    cartItem: function () {
      let items = this.getCartItem(this.product.id);
      if (items.length > 0) {
        return items[0];
      }
      return null;
    },
    lensesCountInBox: function () {
      return lodash.filter(this.product.productProperty, {'property': {'name': 'Упаковка'}});
    }
  },
  methods: {
    ...mapActions({
      "setPageMeta": "SET_PAGE_META",
      "fetchCart": "cart/FETCH_MY_CART"
    }),
    fetchOne: function (id) {
      api.lensmark.catalog.product.one(id).then(response => {
        this.product = response.data;
        this.$route.meta.title = this.product.name;
      }).finally(() => {
        this.pageLoaded = true;
      })
    },
    addToCart: async function () {
      RahmetApp.hapticImpact('medium')
      api.lensmark.cart.add({
        item_id: this.product.id,
        quantity: 1,
        price: this.product.prices.price,
      }).then(rs => {
        NotifyCenter.push('success', rs.message, true);
        this.fetchCart()
      })
    },
    changeCartItemQuantity: function (currentValue) {
      api.lensmark.cart.update({
        item_id: this.cartItem.item_id,
        quantity: currentValue,
        price: this.cartItem.price,
        options: this.cartItem.options
      }).then(() => {
        this.fetchCart();
      });
    }
  }
}
</script>

<style lang="scss">
.el-input-number {
  &.active {
    width: 100%;

    .el-input-number__decrease, .el-input-number__increase {
      background-color: #029D95;
      color: white;
    }
  }
}
</style>

<style lang="scss" scoped>
.catalog-detail-page {
  &_image {
    height: 250px;
    text-align: center;

    img {
      width: inherit;
      height: 100%;
    }
  }

  &_title {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 20px;
  }

  &_rating {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    justify-items: flex-start;
    align-content: center;
    align-items: center;
    margin-bottom: 4px;


    &__reviews {
      margin-left: 11px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #029D95;
    }
  }

  &_price {
    display: flex;
    flex-direction: row;
    padding: 13px 0;
    border-bottom: 1px solid #E7E7E7;

    > span {
      flex: auto;
    }

    &__val {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;
    }

    &__info {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 25px;
      color: #989898;
    }
  }

  &_action {
    display: flex;
    flex-direction: column;
    margin: 14px 0;
    padding: 0 16px;

    &__select {
      display: flex;
      justify-items: center;
      justify-content: center;
      align-items: center;
      align-content: center;
      text-decoration: none;
      height: 44px;
      border: none;
      border-radius: 0;
      flex: auto;
      background-color: #029D95;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      color: #FFFFFF;
    }
  }

  &_characteristics {
    &__title {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 32px;
      color: #193048;
      margin-bottom: 20px;
    }

    &__items {
      &-item {
        display: flex;
        flex-direction: row;
        margin-bottom: 16px;
        justify-items: flex-start;
        justify-content: flex-start;
        align-items: center;
        align-content: center;

        .icon {
          width: 28px;
          height: 28px;
          margin-right: 10px;
          color: #5CB9B6;
          fill: #5CB9B6;
        }

        .title {
          display: flex;
          position: relative;
          justify-content: flex-start;
          justify-items: flex-start;
          flex-direction: row;
          flex: 1;
          font-style: normal;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          color: #333333;

          &:after {
            position: relative;
            top: 15px;
            content: '';
            width: 100%;
            height: 1px;
            display: block;
            background-color: #DCE3EA;
          }
        }

        .value {
          flex: 1;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 17px;
          color: #193048;
        }
      }
    }
  }

  &_info {
    &.el-tabs {
      &__item {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #151522;

        &.is-active {
          color: #029D95;
        }
      }
    }
  }
}
</style>
<template>
  <section class="address-edit-page">
    <form-address
        v-if="loaded"
        v-loading="!loaded"
        :prop-city-value="data.city.value"
        :prop-city-label="data.city.label"
        :prop-address-full-address="data.address.fullAddress"
        :prop-address-street="data.address.street"
        :prop-address-build="data.address.build"
        :prop-address-coordinate="data.address.coordinate"
        :prop-gate="data.gate"
        :prop-floor="data.floor"
        :prop-flat="data.flat"
        :prop-name="data.name"
        :prop-comment="data.comment"/>
  </section>
</template>

<script>
import {api} from "@/shared/services/api";
import FormAddress from "@/views/address/components/FormAddress";

export default {
  name: "AddressUpdate",
  components: {FormAddress},
  data() {
    return {
      loaded: false,
      data: {
        city: {
          value: null,
          label: null
        },
        address: {
          fullAddress: null,
          street: null,
          build: null,
          coordinate: null
        },
        gate: null,
        floor: null,
        flat: null,
        name: null,
        isMain: null,
        isSelected: null,
        comment: null
      }
    }
  },
  created() {
    api.lensmark.personal.address.fetchOne(this.$route.params.id).then(rs => {
      this.data = {
        city: {
          value: rs.data.data.city.id,
          label: rs.data.data.city.name,
        },
        address: {
          fullAddress: `${rs.data.data.street}, ${rs.data.data.build}`,
          street: rs.data.data.street,
          build: rs.data.data.build,
          coordinate: [rs.data.data.la, rs.data.data.lo]
        },
        gate: rs.data.data.gate,
        floor: rs.data.data.floor,
        flat: rs.data.data.flat,
        name: rs.data.data.name,
        isMain: rs.data.data.isMain,
        isSelected: rs.data.data.isSelected,
        comment: rs.data.data.comment
      }
      this.loaded = true;
    });
  }
}
</script>

<style lang="scss" scoped>
.address-edit-page {
  padding: 24px 16px;

}
</style>
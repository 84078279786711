import {AuthToken} from "@/shared/services/storage/models/AuthToken";

export function auth(client) {
    return {
        getTokenByTrackId: async function (trackId) {
            return await client.post(`/api/v1/oauth2/tokens`, {
                client_id: process.env.VUE_APP_CLIENT_ID,
                grant_type: 'authorization_track_id',
                track_id: trackId
            }).then(rs => {
                AuthToken.setTokenType(rs.data.token_type);
                AuthToken.setAccessToken(rs.data.access_token);
                AuthToken.setRefreshToken(rs.data.refresh_token);
                AuthToken.setExpireIn(rs.data.expires_in);
                AuthToken.setTokenValidated(true);
                return rs;
            });
        },
        validateToken: function () {
            return client.get('/api/v1/oauth2/tokens').then(() => {
                AuthToken.setTokenValidated(true);
            }).catch((err) => {
                AuthToken.setTokenType(undefined);
                AuthToken.setAccessToken(undefined);
                AuthToken.setRefreshToken(undefined);
                AuthToken.setExpireIn(undefined);
                AuthToken.setTokenValidated(false);
                return err;
            });
        },
        fetchProfile: async function () {
            return await client.get('/user/v2');
        }
    }
}
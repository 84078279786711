<template>
  <div id="bottom-bar">
    <router-link to="/" class="home" active-class="active" v-on:click="hapticImpact">
      <icon-base icon-name="home" width="38" height="36">
        <icon-home/>
      </icon-base>
    </router-link>
    <router-link to="/cart" class="cart" active-class="active" v-on:click="hapticImpact">
      <span class="quantity">{{ cart.totalQuantity }}</span>
      <icon-base icon-name="cart" width="38" height="36">
        <icon-cart/>
      </icon-base>
    </router-link>
    <router-link to="/cabinet" class="profile" active-class="active" v-on:click="hapticImpact">
      <icon-base icon-name="profile" width="38" height="36">
        <icon-profile/>
      </icon-base>
    </router-link>
    <router-link to="/rahmet" class="profile" active-class="active" v-on:click="hapticImpact">
      <icon-base icon-name="rakhmet" width="24" height="22" view-box="0 0 24 22">
        <icon-rakhmet/>
      </icon-base>
    </router-link>
  </div>
</template>

<script>
import {RahmetApp} from "@/shared/RahmetApp";
import IconBase from "@/components/icons/IconBase";
import IconHome from "@/components/icons/IconHome";
import IconCart from "@/components/icons/IconCart";
import IconProfile from "@/components/icons/IconProfile";
import IconRakhmet from "@/components/icons/IconRakhmet"
import {mapState} from "vuex";

export default {
  name: "BottomBar",
  components: {IconBase, IconHome, IconCart, IconProfile, IconRakhmet},
  computed: {
    ...mapState({
      cart: state => state.cart.cart
    })
  },
  methods: {
    hapticImpact: function () {
      RahmetApp.hapticImpact('medium');
    }
  }
}
</script>

<template>
  <div>
    <el-form class="address-form" label-position="top">
      <el-row :gutter="16">
        <el-col :span="8" style="position: relative;">
          <el-form-item label="Город">
            <el-select
                v-model="dataForRequest.city"
                value-key="label"
                placeholder="Город"
                v-on:change="removeError('city')"
                :class="{error: checkError('city')}"
                class="city-select">
              <el-option v-for="item in lists.cities" :key="item.value" :value="item" :label="item.label"/>
            </el-select>
            <span v-if="checkError('city')" class="el-form-item__error">Укажите город</span>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="Адрес">
            <input-address
                :disabled="!dataForRequest.city.value"
                :prop-city="dataForRequest.city.label"
                :prop-address-full-address="dataForRequest.address.fullAddress"
                :prop-address-street="dataForRequest.address.street"
                :prop-address-build="dataForRequest.address.build"
                :prop-address-coordinate="dataForRequest.address.coordinate"
                :error-street="checkError('street')"
                :error-build="checkError('build')"
                v-on:select-address="selectAddress"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="16" align="top">
        <el-col :span="8">
          <el-form-item label="Подъезд">
            <el-input v-model="dataForRequest.gate" placeholder="Подъезд"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Этаж">
            <el-input v-model="dataForRequest.floor" placeholder="Этаж"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Кв./Офис">
            <el-input v-model="dataForRequest.flat" placeholder="Кв./Офис"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="Комментарий">
            <el-input v-model="dataForRequest.comment" :rows="3" type="textarea" placeholder="Комментарий"/>
          </el-form-item>
        </el-col>
      </el-row>
      <h3>Тип адреса:</h3>
      <input-names :prop-name="propName" v-on:select-name="this.dataForRequest.name = $event"/>
      <el-button class="btn-default btn-custom" v-on:click="save">Сохранить</el-button>
    </el-form>
  </div>
</template>

<script>
import {api} from "@/shared/services/api";
import {mapActions} from "vuex";
import InputAddress from "@/views/address/components/Inputs/Address";
import InputNames from "@/views/address/components/Inputs/Names";

export default {
  name: "FormAddress",
  components: {InputAddress, InputNames},
  props: [
    'propCityValue',
    'propCityLabel',
    'propAddressFullAddress',
    'propAddressStreet',
    'propAddressBuild',
    'propAddressCoordinate',
    'propGate',
    'propFloor',
    'propFlat',
    'propName',
    'propComment'
  ],
  beforeMount() {
    this.fetchCityList();
  },
  data() {
    return {
      lists: {
        cities: []
      },
      errors: [],
      dataForRequest: {
        city: {
          value: this.propCityValue,
          label: this.propCityLabel
        },
        address: {
          fullAddress: this.propAddressFullAddress,
          street: this.propAddressStreet,
          build: this.propAddressBuild,
          coordinate: this.propAddressCoordinate
        },
        gate: this.propGate,
        floor: this.propFloor,
        flat: this.propFlat,
        name: this.propName,
        comment: this.propComment
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAddressList: "address/FETCH_ADDRESS_LIST"
    }),
    fetchCityList: function () {
      api.lensmark.personal.city.getList().then(rs => {
        this.lists.cities = rs.data.data.map((item) => {
          return {
            value: item.id,
            label: item.name
          }
        });
      });
    },
    selectAddress: function (address) {
      this.dataForRequest.address = address;
      this.removeError('street');
      this.removeError('build');
    },
    validateData: function () {
      this.errors.forEach((error) => {
        let index = this.errors.indexOf(error);
        if (index > -1) {
          this.errors.splice(index, 1);
        }
      });

      if (!this.dataForRequest.city.value && (this.errors.indexOf('city') <= -1)) {
        this.errors.push('city');
      }
      if (!this.dataForRequest.address.street && (this.errors.indexOf('street') <= -1)) {
        this.errors.push('street');
      }
      if (!this.dataForRequest.address.build && (this.errors.indexOf('build') <= -1)) {
        this.errors.push('build');
      }

      return this.errors.length > 0;
    },
    checkError: function (error) {
      return !!this.errors.find(function (item) {
        return item === error
      });
    },
    removeError: function (error) {
      let p = this.errors.indexOf(error);
      if (p > -1) {
        this.errors.splice(p, 1);
      }
    },
    addNew: function (data) {
      api.lensmark.personal.address.add(data).then(() => {
        this.fetchAddressList();
      })
    },
    update: function (id, data) {
      api.lensmark.personal.address.update(id, data).then(() => {
        this.fetchAddressList();
      });
    },
    save: function () {
      let data = {
        name: this.dataForRequest.name,
        city_id: this.dataForRequest.city.value,
        street: this.dataForRequest.address.street,
        build: this.dataForRequest.address.build,
        gate: this.dataForRequest.gate,
        floor: this.dataForRequest.floor,
        flat: this.dataForRequest.flat,
        comment: this.dataForRequest.comment,
        is_main: 1,
        is_selected: 0
      };

      if (this.$route.name === 'CabinetAddressCreate') {
        data.is_selected = 1;
      }

      if (this.validateData()) {
        console.debug(this.errors)
      } else {
        if (!this.$route.params.id) {
          this.addNew(data);
        } else {
          this.update(this.$route.params.id, data);
        }
        this.$router.go(-1);
      }
    }
  }
}
</script>

<style lang="scss">
.address-form {

  .el-form-item {
    margin-bottom: 22px;

    &__label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #A6A6A6;
    }
  }

  .el-input, .el-textarea {
    &__inner {
      &:focus, &:active {
        border-color: #029D95 !important;
      }
    }
  }

  h3 {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: #303030;
    margin-bottom: 10px;
  }

  .city {
    &-select {
      &.error {
        .el-input__inner {
          background-color: #fde2e2 !important;
          border-color: #F56C6C !important;;
          color: white !important;;
        }
      }
    }
  }

  .btn-default {
    background-color: #029D95;
    border-radius: 4px;
    height: 44px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    margin-top: 24px;

    &:focus, &:active {
      background-color: #029D95;
      color: #FFFFFF;
      border-color: #029D95;
    }
  }
}
</style>
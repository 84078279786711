<template>
  <section class="cabinet-support-chat-page"></section>
</template>

<script>
export default {
  name: "CabinetSupportChatPage"
}
</script>

<style scoped>

</style>
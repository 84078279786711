import {api} from "@/shared/services/api";
import lodash from "lodash";

export default {
    namespaced: true,
    state: {
        addresses: []
    },
    getters: {
        SELECTED_ADDRESS: function (state) {
            let p = lodash.findIndex(state.addresses, function (a) {
                return a.isSelected;
            });
            return state.addresses[p];
        }
    },
    mutations: {
        SET_ADDRESSES: function (state, payload) {
            state.addresses = payload;
        }
    },
    actions: {
        FETCH_ADDRESS_LIST: async function ({commit}) {
            return await api.lensmark.personal.address.getList().then(rs => {
                commit('SET_ADDRESSES', rs.data.data);
                return rs;
            });
        }
    }
}
<template>
  <section class="cabinet-address-page">
    <div class="address-page_items">
      <div class="address-page_items__item"
           :class="{active: address.isSelected}"
           v-for="(address, key) in addresses"
           :key="key">
        <div class="header">
          <div class="title">
            <span class="name">{{ address.name }}</span>
            {{ `${address.city.name}, ${address.street}` }}
          </div>
          <div class="trash" v-on:click="drop(address.id)">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
                    fill="#029D95"/>
            </svg>
          </div>
        </div>
        <div class="middle">
          <div class="middle-item">
            <div class="label">Дом</div>
            <div class="value">{{ address.build }}</div>
          </div>
          <div class="middle-item">
            <div class="label">Подъезд</div>
            <div class="value">{{ address.gate }}</div>
          </div>
          <div class="middle-item">
            <div class="label">Этаж</div>
            <div class="value">{{ address.floor }}</div>
          </div>
          <div class="middle-item">
            <div class="label">Квартира</div>
            <div class="value">{{ address.flat }}</div>
          </div>
          <div class="middle-item"></div>
        </div>
        <div class="footer">
          <router-link
              :to="{name: 'CabinetAddressUpdate', params: {id: address.id}}"
              class="edit">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M2.25 12.9375V15.75H5.0625L13.3575 7.455L10.545 4.6425L2.25 12.9375ZM15.5325 5.28C15.825 4.9875 15.825 4.515 15.5325 4.2225L13.7775 2.4675C13.485 2.175 13.0125 2.175 12.72 2.4675L11.3475 3.84L14.16 6.6525L15.5325 5.28Z"
                  fill="#029D95"/>
            </svg>
            Редактировать
          </router-link>
          <div class="label" v-if="address.isSelected">Выбранный</div>
        </div>
      </div>
    </div>
    <div class="footer" style="padding: 16px;">
      <router-link class="btn btn-default btn-success" :to="{name: 'CabinetAddressCreate'}">Добавить</router-link>
    </div>
  </section>
</template>

<script>
import {mapState, mapActions, mapGetters} from "vuex";
import {api} from "@/shared/services/api";
import {NotifyCenter} from "@/shared/services/notify";

export default {
  name: "AddressIndexPage",
  methods: {
    ...mapActions({
      getAddress: "address/FETCH_ADDRESS_LIST",
    }),
    drop: function (id) {
      api.lensmark.personal.address.drop(id).then(rs => {
        NotifyCenter.push('success', rs.data.message, true);
        this.getAddress();
      });
    }
  },
  computed: {
    ...mapState({
      addresses: state => state.address.addresses
    }),
    ...mapGetters({
      selectedAddress: "address/SELECTED_ADDRESS"
    })
  },
  created() {
    this.getAddress();
  }
}
</script>

<style lang="scss" scoped>
.cabinet-address-page {
  .address-page_items {
    &__item {
      padding: 21px 48px;
      box-shadow: inset 0px -0.3px 0px #C4C4C4;
      background: #FFFFFF;
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      &.active {
        background: #E3F1F0;
      }

      .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;

          .name {
            display: inline-block;
            padding: 4px 8px;
            background: #029D95;
            border-radius: 4px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #FFFFFF;
            margin-right: 4px;
          }
        }

        .trash {
          width: 24px;
          height: 24px;
        }
      }

      .middle {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        column-gap: 24px;

        &-item {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          .label {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #A6A6A6;
            margin-bottom: 4px;
          }

          .value {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: #000000;
          }
        }
      }

      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .edit {
          background-color: transparent;
          border: none;
          padding: 0;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #029D95;
          text-decoration: none;
        }

        .label {
          display: block;
          padding: 4px 8px;
          background: #029D95;
          border-radius: 4px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
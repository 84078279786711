<template>
  <div>
    <el-scrollbar style="border-bottom: 1px solid #c4c4c44d;">
      <div class="orders">
        <router-link :to="{name: 'CabinetOrderDetail', params: {id: order.id}}" class="order"
                     v-for="(order, key) in orders" :key="key">
          <div class="header">
            <span class="title">Заказ #{{ order.id }}</span>
<!--            <el-button class="btn">-->
<!--              <el-icon color="#B8C1CC" :size="20">-->
<!--                <refresh-left/>-->
<!--              </el-icon>-->
<!--            </el-button>-->
          </div>
          <div class="footer">
            <div class="date">Дата: {{ $filters.datetimeToDate(order.createdAt) }}</div>
            <div class="state">Статус: <span>{{ order.state.name }}</span></div>
          </div>
        </router-link>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "OrderWidget",
  computed: {
    ...mapState({
      user: state => state.user.user,
      orders: state => state.order.orders
    })
  },
  methods: {
    ...mapActions({
      fetchOrders: "order/FETCH_ORDERS_BY_API"
    })
  }
}
</script>

<style lang="scss" scoped>
.orders {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  column-gap: 14px;
  padding: 11px 16px;

  .order {
    flex-shrink: 0;
    width: 178px;
    padding: 12px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    text-decoration: none;

    .header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;
      align-content: baseline;

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
      }

      .btn {
        border: none;
        padding: 0;
      }
    }

    .footer {
      .date {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #303030;
        margin-bottom: 4px;
      }

      .state {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        color: #303030;
        display: flex;
        flex-direction: row;

        span {
          color: #27AE60;
          margin-left: 5px;
          text-transform: capitalize;
        }
      }
    }
  }
}
</style>
import AuthService from "@/shared/services/AuthService";
import {ElLoading} from "element-plus";
import {NotifyCenter} from "@/shared/services/notify";

export function installAuthGuard(router, store) {
    router.beforeEach(async (to, from, next) => {
        if (to.meta.protected && !store.state.isAuthorized) {
            let loaderInstance = ElLoading.service({
                fullscreen: true,
                lock: true
            });

            let allowedToNext = false;

            await AuthService.loginByTrackId(to.query.track_id, (result) => {
                allowedToNext = result.state;
                NotifyCenter.push(result.state ? 'success' : 'error', result.message, true);

                if (result.state) {
                    store.dispatch('INIT_AUTHORIZED_FLOW')
                }
            });

            next(allowedToNext);
            loaderInstance.close()
        } else {
            next();
        }
    })
}

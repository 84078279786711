<template>
  <div class="skeleton-product-item">
    <el-skeleton animated>
      <template #template>
        <el-skeleton-item variant="image" class="item-image"/>
        <div style="padding: 8px;">
          <el-skeleton-item variant="h3" style="width: 100%;"/>
        </div>
        <div style="padding-left: 8px; padding-right: 8px">
          <el-skeleton-item variant="h1" style="width: 50%"/>
        </div>
        <div style="padding-right: 8px; padding-left: 8px">
          <el-skeleton-item variant="button" style="width: 100%"/>
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
export default {
  name: "SkeletonProductItem"
}
</script>

<style lang="scss" scoped>
.skeleton {
  &-product {
    &-item {
      background-color: white;
      border-radius: 8px;
      overflow: hidden;
      padding-top: 8px;

      .item {
        &-image {
          width: 100%;
          height: 160px;
        }
      }
    }
  }
}
</style>
import {api} from "@/shared/services/api";
import UserModel from "@/shared/models/User";

const User = {
    namespaced: true,
    state: {
        user: UserModel
    },
    mutations: {
        SET_PROFILE: function (state, user) {
            user = new UserModel(
                user.id, user.username,
                user.firstName,
                user.lastName,
                user.phone,
                user.email,
                user.externalId
            );
            state.user = user;
        }
    },
    actions: {
        FETCH_PROFILE: function ({commit}) {
            api.lensmark.user.fetchProfile().then(rs => {
                commit('SET_PROFILE', rs.data.data);
                return rs;
            });
        }
    },
    getters: {}
}

export default User
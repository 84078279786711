<template>
  <g>
    <path
        d="M16.9167 14.2187C16.9167 20.9562 12.5855 26.4687 7.29175 26.4687C6.8105 26.4687 6.41675 26.075 6.41675 25.5938C6.41675 25.1125 6.8105 24.7187 7.29175 24.7187C11.623 24.7187 15.1667 19.9937 15.1667 14.2187C15.1667 8.44375 11.623 3.71875 7.29175 3.71875C6.8105 3.71875 6.41675 3.325 6.41675 2.84375C6.41675 2.3625 6.8105 1.96875 7.29175 1.96875C12.5855 1.96875 16.9167 7.48125 16.9167 14.2187Z"
        fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
    <path
        d="M10.7917 14.2187C10.7917 15.5312 10.7042 26.4687 7.29175 26.4687C3.87925 26.4687 3.79175 15.4875 3.79175 14.2188C3.79175 13.7375 4.1855 13.3437 4.66675 13.3437L7.81675 13.3437L4.49175 10.0188C4.31675 9.84375 4.22925 9.58125 4.22925 9.31875C4.973 2.84375 6.198 1.96875 7.29175 1.96875C10.7042 1.96875 10.7917 12.9062 10.7917 14.2187ZM7.29175 24.675C7.948 24.0625 8.95425 20.65 9.04175 15.0937L5.54175 15.0937C5.62925 20.65 6.6355 24.0625 7.29175 24.675ZM6.023 9.1L8.998 12.075C8.77925 7.39375 7.90425 4.4625 7.3355 3.80625C7.073 4.1125 6.50425 5.25 6.023 9.1Z"
        fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
    <path
        d="M19.323 14.219C19.323 14.7002 19.7167 15.094 20.198 15.094L25.448 15.094C25.9292 15.094 26.323 14.7002 26.323 14.219C26.323 13.7377 25.9292 13.344 25.448 13.344L20.198 13.344C19.7167 13.344 19.323 13.7377 19.323 14.219Z"
        fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
    <path
        d="M19.323 8.09399C19.323 8.31274 19.4105 8.53149 19.5855 8.70649C19.9355 9.05649 20.4605 9.05649 20.8105 8.70649L24.2668 5.25024C24.6168 4.90024 24.6168 4.37524 24.2668 4.02524C23.9168 3.67524 23.3918 3.67524 23.0418 4.02524L19.5855 7.48149C19.4105 7.65649 19.323 7.87524 19.323 8.09399Z"
        fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
    <path
        d="M19.323 20.344C19.323 20.5628 19.4105 20.7815 19.5855 20.9565L23.0418 24.4128C23.3918 24.7628 23.9168 24.7628 24.2668 24.4128C24.6168 24.0628 24.6168 23.5378 24.2668 23.1878L20.8105 19.7315C20.4605 19.3815 19.9355 19.3815 19.5855 19.7315C19.4105 19.8628 19.323 20.0815 19.323 20.344Z"
        fill="currentColor" stroke="currentColor" stroke-width="0.5"/>
  </g>
</template>
<template>
  <div class="property">
    <div class="property-item" v-for="(item, key) in items" :key="key">
      <label>{{ getNameFromKey(key) }}</label>
      <el-select
          v-model="models[key]"
          @change="selectOption"
          placeholder="Выбрать"
          class="select"
      >
        <el-option
            v-for="(option, optionKey) in key === 'opticalPower' ? item.filter(item => {
              return item.colorId === filterForOpticalPower.colorId;
            }) : item"
            :key="optionKey"
            :label="option.value"
            :value="{id: key, value: option.value, valueId: option.id}"
        />
      </el-select>
    </div>
    <div class="property-action">
      <el-button
          v-if="!currentCartItem"
          @click="addItem"
          class="btn-default btn-success"
      >
        Добавить
      </el-button>
      <el-input-number v-else v-model="quantity" class="property-counter" :min="0" :max="10" @change="updateItem"/>
    </div>
  </div>
</template>

<script>
import lodash from "lodash";
import {mapActions, mapGetters} from "vuex";
import {api} from "@/shared/services/api";

export default {
  name: "PropertyAddToCart",
  props: ['propItemId', 'propItemPrice', 'propItems'],
  data() {
    return {
      filterForOpticalPower: {},
      items: this.propItems ?? {},
      quantity: 1,
      models: {},
      currentCartItem: {}
    }
  },
  computed: {
    ...mapGetters({
      cartItem: 'cart/cartItem'
    }),
    getNameFromKey: () => key => {
      let name = '';
      switch (key) {
        case 'radius':
          name = 'Базовая кривизна';
          break;
        case 'opticalPower':
          name = 'Оптическая сила';
          break;
        case 'colors':
          name = 'Цвет';
          break;
        case 'axis':
          name = 'Axis';
          break;
        case 'cyl':
          name = 'Cyl';
          break;
        default:
          name = key;
          break;
      }
      return name;
    }
  },
  methods: {
    ...mapActions({
      fetchCart: 'cart/FETCH_MY_CART'
    }),
    initFlow: function () {
      this.setFilterKeyForOpticalPower();
      this.setDefaultValues();
      this.setCurrentCartItem(this.propItemId);
      this.quantity = this.currentCartItem ? this.currentCartItem.quantity : 1;
    },
    setFilterKeyForOpticalPower: function () {
      let keys = lodash.keys(this.items);
      let opticalPowerPosition = lodash.indexOf(keys, 'opticalPower')
      if (opticalPowerPosition > -1) {
        this.filterForOpticalPower.key = lodash.nth(keys, opticalPowerPosition - 1);
      }
    },
    setDefaultValues: function () {
      lodash.each(this.items, (item, key) => {
        if (key !== 'opticalPower') {
          this.models[key] = {
            id: key,
            value: item[0].value,
            valueId: item[0].id
          };
        } else {
          this.filterForOpticalPower.colorId = this.models[this.filterForOpticalPower.key].valueId;
          let items = item.filter(item => {
            return item.colorId === this.filterForOpticalPower.colorId;
          })
          this.models[key] = {
            id: key,
            value: items[0].value,
            valueId: items[0].id
          }
        }
      });
    },
    setCurrentCartItem: function (itemId) {
      let items = this.cartItem(itemId);
      items = items.filter(item => {
        let options = item.options.filter(option => {
          return this.models[option.id].value === option.value;
        })
        return lodash.keys(this.models).length === options.length;
      });
      this.currentCartItem = items.length > 0 ? items[0] : null;
    },
    selectOption: function (option) {
      if (option.id === this.filterForOpticalPower.key) {
        this.filterForOpticalPower.colorId = option.valueId;
        let items = this.items.opticalPower.filter(item => {
          return item.colorId === option.valueId;
        });
        this.models.opticalPower = {
          id: 'opticalPower',
          value: items[0].value,
          valueId: items[0].id
        };
      }
      this.setCurrentCartItem(this.propItemId);

      this.quantity = this.currentCartItem ? this.currentCartItem.quantity : 1;
    },
    addItem: function () {
      let data = {
        item_id: this.propItemId,
        quantity: this.quantity,
        price: this.propItemPrice,
        options: lodash.values(this.models)
      };
      api.lensmark.cart.add(data).then(() => {
        this.fetchCart().finally(() => {
          this.setCurrentCartItem(this.propItemId);
        });
      });
    },
    updateItem: function (v) {
      api.lensmark.cart.update({
        item_id: this.propItemId,
        price: this.propItemPrice,
        quantity: v,
        options: lodash.values(this.models)
      }).then(() => {
        this.fetchCart().finally(() => {
          if (v === 0) {
            this.setCurrentCartItem(this.propItemId);
          }
        });
      });
    }
  },
  beforeMount() {
    this.initFlow();
  }
}
</script>

<style lang="scss" scoped>
.property {
  flex: 1;

  &-item {
    margin: 12px 0;
    display: flex;
    flex-direction: column;

    label {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #333333;
      display: block;
      margin-bottom: 12px;
    }

    .el-input, select {
      background-color: #FFFFFF;
      -webkit-appearance: none;
      border: 1px solid #DCE3EA;
      box-sizing: border-box;
      border-radius: 8px;
      width: 100%;
      height: 40px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      padding: 8px 15px;

      option {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  &-counter {
    width: 100%;
  }
}
</style>

<style lang="scss">
.property-counter {
  .el-input-number__decrease, .el-input-number__increase {
    background: #029D95;
    color: white;
    font-weight: bolder;
    font-size: 22px;
  }
}
</style>
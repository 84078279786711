<template>
  <section class="cabinet-order-detail-page" v-loading="!loaded">
    <div v-if="loaded">
      <div class="header">
        <div class="header-title">Заказ #{{ order.id }}</div>
        <div class="header-body">
          <div class="header-body_row">
            <div class="block block-left">Статус</div>
            <div class="block block-right">
              {{
                order.state.name.charAt(0).toUpperCase() + order.state.name.slice(1)
              }}
            </div>
          </div>
          <div class="header-body_row">
            <div class="block block-left">Дата</div>
            <div class="block block-right">{{ order.createdAt }}</div>
          </div>
          <div class="header-body_row">
            <div class="block block-left">Способ оплаты</div>
            <div class="block block-right">{{ order.paymentType.name }}</div>
          </div>
          <div class="header-body_row">
            <div class="block block-left">Сумма заказа</div>
            <div class="block block-right">{{ $filters.priceFormat(order.amount.sum) }} ₸</div>
          </div>
          <div class="header-body_row">
            <div class="block block-left">Доставка</div>
            <div class="block block-right">
              {{ order.deliveryType === 1 ? `Курьером` : 'Самовывоз' }} -
              {{ order.deliveryCost === 0 ? 'Бесплатно' : $filters.priceFormat(order.deliveryCost) + ' ₸' }}
            </div>
          </div>
          <div class="header-body_row">
            <div class="block block-left">Адрес</div>
            <div class="block block-right">{{fullAddress}}</div>
          </div>
          <div class="header-body_row">
            <div class="block block-left">Коментарий</div>
            <div class="block block-right">{{ order.comment }}</div>
          </div>
        </div>
      </div>
      <div class="body">
        <div class="body-reply" v-if="order.state.id === 6">
          <el-button class="btn btn-default btn-success" v-on:click="processing">Оплатить</el-button>
        </div>
        <div class="body-title">Товары в заказе</div>
        <div class="body-items">
          <div class="body-items_item" v-for="(item, key) in order.items" :key="key">
            <div class="block-left">
              <el-image :src="item.tempCart.product.image.images.small" lazy fit="contain">
                <template #placeholder>
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </template>
              </el-image>
            </div>
            <div class="block-right">
              <div class="item-title">{{ item.tempCart.product.name }}</div>
              <div class="item-options">
                <span v-if="item.tempCart.radius.length > 0">Радиус кривизны: {{ item.tempCart.radius }}</span>
                <span v-if="item.tempCart.force.length > 0">, Оптическая сила: {{ item.tempCart.force }}</span>
                <span v-if="item.tempCart.color.length > 0">, Цвет: {{ item.tempCart.color }}</span>
                <span v-if="item.tempCart.axis.length > 0">, Axis: {{ item.tempCart.axis }}</span>
                <span v-if="item.tempCart.cyl.length > 0">, Cyl: {{ item.tempCart.cyl }}</span>
              </div>
              <div class="item-price">{{ $filters.priceFormat(item.price) }} ₸ * {{ item.quantity }} |
                {{ $filters.priceFormat(item.price * item.quantity) }} ₸
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="body-reply">-->
        <!--          <el-button class="btn btn-default btn-success">Повторить заказ</el-button>-->
        <!--        </div>-->
      </div>
    </div>
  </section>
</template>

<script>
import {api} from "@/shared/services/api";
import {RahmetApp} from "@/shared/RahmetApp";
import {mapActions} from "vuex";

export default {
  name: "CabinetOrderDetail",
  data() {
    return {
      order: {},
      loaded: false
    }
  },
  created() {
    api.lensmark.order.getOne(this.$route.params.id).then(rs => {
      this.order = rs.data.data;
      this.loaded = true;
      this.setPageMeta({
        title: `Заказ #${rs.data.data.id}`
      });
    });
  },
  methods: {
    ...mapActions({
      setPageMeta: 'SET_PAGE_META'
    }),
    processing: function () {
      RahmetApp.hapticImpact('medium');
      RahmetApp.pay(this.order.payment.response.url);
    }
  },
  computed: {
    fullAddress: function () {
      var address = 'г.' + this.order.city.name;
      address += ', ул.' + this.order.address.street;
      address += ', д.' + this.order.address.build;
      if (this.order.address.gate) {
        address += ', пд.' + this.order.address.gate;
      }
      if (this.order.address.floor) {
        address += ', эт.' + this.order.address.floor;
      }
      if (this.order.address.flat) {
        address += ', кв./оф.' + this.order.address.flat;
      }
      return address;
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  padding: 16px;

  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 17px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    row-gap: 7px;

    &_row {
      display: flex;
      flex-direction: row;
      flex: auto;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;

      .block {
        flex: 1;

        &-left {
          flex: 35%;
          color: #A6A6A6;
        }

        &-right {
          flex: 65%;
          color: #303030;
        }
      }
    }
  }
}

.body {
  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 8px;
    padding: 0 16px;
  }

  &-items {
    &_item {
      display: flex;
      flex-direction: row;
      background: #FFFFFF;
      box-shadow: inset 0 -0.3px 0 #C4C4C4;
      padding: 16px;
      column-gap: 14px;
      align-items: center;
      justify-content: flex-start;

      .block {
        &-left {
          width: 64px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &-right {
          flex: 1;
        }
      }

      .item {
        &-title {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: #000000;
        }

        &-options {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: #989898;
        }

        &-price {
          margin-top: 8px;
          font-style: normal;
          font-weight: 900;
          font-size: 16px;
          line-height: 19px;
          color: #000000;
        }
      }
    }
  }

  &-reply {
    padding: 30px 16px;
  }
}
</style>
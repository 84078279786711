<template>
  <skeleton-menu-grid style="margin-top: 16px" v-if="!loaded"/>
  <section class="menu-grid" v-else>
    <router-link
        v-for="(category, key) in categories"
        class="menu-grid_item"
        :key="key"
        :to="{name: 'CatalogIndex', params: {id: category.id, title: category.name}}">
      <span class="menu-grid_item__title">{{ category.name }}</span>
      <!--      <span class="menu-grid_item__sub-title">Alcon</span>-->
      <span class="menu-grid_item__icon">
            <icon-base icon-name="cat" width="89.6" height="61.95" view-box="0 0 90 63">
              <icon-cat-lenses v-if="category.name === 'Линзы'"/>
              <icon-cat-liquor v-if="category.name === 'Растворы'"/>
              <icon-cat-drops v-if="category.name === 'Капли'"/>
              <icon-cat-accessories v-if="category.name === 'Аксессуары'"/>
              <icon-cat-other v-if="category.name === 'Прочее'"/>
            </icon-base>
          </span>
    </router-link>
  </section>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {api} from "@/shared/services/api";
import IconBase from "@/components/icons/IconBase";
import IconCatLenses from "@/components/icons/IconCatLenses";
import IconCatLiquor from "@/components/icons/IconCatLiquor";
import IconCatDrops from "@/components/icons/IconCatDrops";
import IconCatAccessories from "@/components/icons/IconCatAccessories";
import IconCatOther from "@/components/icons/IconCatOther";
import SkeletonMenuGrid from "@/components/skeleton/MenuGrid";

export default {
  name: "MenuGrid",
  data() {
    return {
      loaded: false
    }
  },
  components: {
    SkeletonMenuGrid,
    IconCatOther,
    IconCatAccessories,
    IconCatDrops,
    IconCatLiquor,
    IconBase,
    IconCatLenses,
  },
  created() {
    if (this.categories.length <= 0) {
      api.lensmark.catalog.category.list({
        page: {
          limit: 10,
          offset: 0
        }
      }).then(response => {
        this.setCategories(response.data)
        this.loaded = true;
      })
    } else {
      this.loaded = true;
    }
  },
  computed: {
    ...mapState({
      categories: state => state.catalog.category.categories
    })
  },
  methods: {
    ...mapActions({
      setCategories: "catalog/category/setCategories",
    })
  }
}
</script>

<style scoped>

</style>
export function user(client) {
    return {
        login: async function (payload)  {
            return await client.post('/auth/login', {
                access_token: payload.access_token,
                refresh_token: payload.refresh_token,
                token_type: payload.token_type,
                expires_in: payload.expires_in
            });
        },
        registration: async function (payload) {
            return await client.post('/auth/registration', {
                access_token: payload.access_token,
                refresh_token: payload.refresh_token,
                token_type: payload.token_type,
                expires_in: payload.expires_in
            });
        },
        fetchProfile: async function () {
            return await client.get('/user/profile');
        },
        updateInfo: async function (payload) {
            return await client.put('/user/profile', {
                first_name: payload.first_name,
                last_name: payload.last_name,
                email: payload.email,
                phone: payload.phone
            });
        }
    }
}
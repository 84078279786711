<template>
  <section class="address-edit-page">
    <form-address />
  </section>
</template>

<script>
import FormAddress from "@/views/address/components/FormAddress";
export default {
  name: "AddressCreate",
  components: {FormAddress}
}
</script>

<style lang="scss" scoped>
.address-edit-page {
  padding: 24px 16px;
}
</style>
import lodash from "lodash";

export const filter = {
    namespaced: true,
    state: {
        filter: {},
        searchQuery: ""
    },
    getters: {
        getElementValues: state => payload => {
            if (state.filter[payload.index] && state.filter[payload.index][payload.itemIndex]) {
                return state.filter[payload.index][payload.itemIndex];
            }
            return []
        }
    },
    mutations: {
        update: function (state, payload) {
            let filter = state.filter

            if (!lodash.has(filter, payload.index)) {
                filter = lodash.merge(filter, {[payload.index]: {}})
            }

            if (!lodash.has(filter[payload.index], payload.item.index)) {
                filter[payload.index] = lodash.merge(filter[payload.index], {[payload.item.index]: []})
            }

            if (!filter[payload.index][payload.item.index].includes(payload.item.value)) {
                filter[payload.index][payload.item.index] = lodash.concat(filter[payload.index][payload.item.index], payload.item.value)
            } else {
                let p = filter[payload.index][payload.item.index].indexOf(payload.item.value)
                filter[payload.index][payload.item.index].splice(p, 1)
            }

            state.filter = filter
        },
        discard: function (state) {
            state.filter = {}
        },
        setSearchQuery: function (state, payload) {
            state.searchQuery = payload
        }
    },
    actions: {
        update: function ({commit}, payload) {
            commit('update', payload)
        },
        discard: function ({commit}) {
            commit("discard")
        },
        setSearchQuery: function ({commit}, payload) {
            commit("setSearchQuery", payload)
        }
    }
}
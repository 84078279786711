<template>
  <div class="support-page">
    <div class="support-page_header">
      <div class="support-items">
        <a href="tel:+77273132320" class="support-items_item">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.3333 14.1V16.6C18.3343 16.8321 18.2867 17.0618 18.1937 17.2745C18.1008 17.4871 17.9644 17.678 17.7934 17.8349C17.6224 17.9918 17.4205 18.1113 17.2006 18.1856C16.9808 18.26 16.7478 18.2876 16.5166 18.2667C13.9523 17.9881 11.4891 17.1118 9.32498 15.7084C7.31151 14.4289 5.60443 12.7219 4.32498 10.7084C2.91663 8.53438 2.04019 6.0592 1.76665 3.48337C1.74583 3.25293 1.77321 3.02067 1.84707 2.80139C1.92092 2.58211 2.03963 2.38061 2.19562 2.20972C2.35162 2.03883 2.54149 1.9023 2.75314 1.80881C2.9648 1.71532 3.1936 1.66692 3.42498 1.66671H5.92498C6.32941 1.66273 6.72148 1.80594 7.02812 2.06965C7.33476 2.33336 7.53505 2.69958 7.59165 3.10004C7.69717 3.9001 7.89286 4.68565 8.17498 5.44171C8.2871 5.73998 8.31137 6.06414 8.24491 6.37577C8.17844 6.68741 8.02404 6.97347 7.79998 7.20004L6.74165 8.25837C7.92795 10.3447 9.65536 12.0721 11.7416 13.2584L12.8 12.2C13.0266 11.976 13.3126 11.8216 13.6242 11.7551C13.9359 11.6887 14.26 11.7129 14.5583 11.825C15.3144 12.1072 16.0999 12.3029 16.9 12.4084C17.3048 12.4655 17.6745 12.6694 17.9388 12.9813C18.203 13.2932 18.3435 13.6914 18.3333 14.1Z"
                stroke="#2F2F2F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>Позвонить</span>
        </a>
        <a href="https://wa.me/77052632217" class="support-items_item">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.5024 1H10.4976C5.25956 1 1 5.26075 1 10.5C1 12.5781 1.66975 14.5043 2.80856 16.0682L1.62463 19.5974L5.27619 18.4301C6.77838 19.4253 8.57031 20 10.5024 20C15.7404 20 20 15.7381 20 10.5C20 5.26194 15.7404 1 10.5024 1Z"
                fill="#4CAF50"/>
            <path
                d="M16.0302 14.4152C15.801 15.0624 14.8913 15.5992 14.1658 15.7559C13.6694 15.8616 13.021 15.9459 10.8384 15.041C8.04659 13.8844 6.24872 11.0475 6.10859 10.8634C5.97441 10.6793 4.98047 9.36122 4.98047 7.99797C4.98047 6.63472 5.67278 5.97091 5.95184 5.68591C6.18103 5.45197 6.55984 5.34509 6.92322 5.34509C7.04078 5.34509 7.14647 5.35103 7.24147 5.35578C7.52053 5.36766 7.66066 5.38428 7.84472 5.82484C8.07391 6.37703 8.63203 7.74028 8.69853 7.88041C8.76622 8.02053 8.83391 8.21053 8.73891 8.39459C8.64985 8.58459 8.57147 8.66891 8.43134 8.83041C8.29122 8.99191 8.15822 9.11541 8.01809 9.28878C7.88984 9.43959 7.74497 9.60109 7.90647 9.88016C8.06797 10.1533 8.62609 11.0641 9.44784 11.7956C10.5083 12.7397 11.368 13.0413 11.6756 13.1695C11.9048 13.2645 12.1779 13.242 12.3453 13.0638C12.5579 12.8347 12.8203 12.4547 13.0875 12.0806C13.2775 11.8122 13.5174 11.779 13.7692 11.874C14.0257 11.963 15.383 12.634 15.662 12.7729C15.9411 12.913 16.1252 12.9795 16.1928 13.0971C16.2593 13.2147 16.2593 13.7668 16.0302 14.4152Z"
                fill="#FAFAFA"/>
          </svg>
          <span>Написать<br/>WhatsApp</span>
        </a>
        <!--        <router-link to="mailto" class="support-items_item">-->
        <!--          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--            <path-->
        <!--                d="M17.4724 2.67505H2.52761C1.13391 2.67505 0 3.80896 0 5.20266V14.7973C0 16.191 1.13391 17.3249 2.52761 17.3249H17.4724C18.8661 17.3249 20 16.191 20 14.7973V5.20266C20 3.80896 18.8661 2.67505 17.4724 2.67505ZM17.0775 4.30548L10 9.77277L2.9225 4.30548H17.0775ZM17.4724 15.6946H2.52761C2.03293 15.6946 1.63043 15.2921 1.63043 14.7974V5.36766L9.50163 11.4481C9.64837 11.5615 9.82424 11.6181 10 11.6181C10.1758 11.6181 10.3516 11.5615 10.4984 11.4481L18.3696 5.36766V14.7973C18.3696 15.2921 17.9671 15.6946 17.4724 15.6946Z"-->
        <!--                fill="#1D1D1B"/>-->
        <!--          </svg>-->
        <!--          <span>Написать<br/>на почту</span>-->
        <!--        </router-link>-->
      </div>
    </div>
    <div class="support-page_faq">
      <h2 class="support-page_faq__title">
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M14 2C20.624 2 26 7.376 26 14C26 20.624 20.624 26 14 26C7.376 26 2 20.624 2 14C2 7.376 7.376 2 14 2ZM14 4C8.48056 4 4 8.48056 4 14C4 19.5194 8.48056 24 14 24C19.5194 24 24 19.5194 24 14C24 8.48056 19.5194 4 14 4ZM14 18.1004C14.7732 18.1004 15.4 18.7272 15.4 19.5004C15.4 20.2736 14.7732 20.9004 14 20.9004C13.2268 20.9004 12.6 20.2736 12.6 19.5004C12.6 18.7272 13.2268 18.1004 14 18.1004ZM13.934 6.99C15.9848 6.99 17.6458 8.66812 17.6458 10.74C17.6458 11.565 17.3118 12.315 16.7828 12.8494L15.9477 13.7119C15.451 14.2206 15.0928 14.6621 14.9416 15.4328C14.928 15.5023 14.918 15.6057 14.9117 15.7428C14.8887 16.2367 14.4816 16.6254 13.9871 16.6254H13.9779C13.4651 16.6255 13.0494 16.2098 13.0494 15.697L13.0494 15.6946C13.0501 15.4345 13.0582 15.2641 13.0737 15.1834C13.2173 14.4363 13.5814 13.7659 14.0918 13.2431L15.2424 12.0619C15.5717 11.7306 15.7899 11.2556 15.7899 10.74C15.7899 9.70875 14.9548 8.865 13.934 8.865C13.2854 8.865 12.7117 9.20568 12.3796 9.71866C12.3139 9.82013 12.2313 9.98186 12.1318 10.2039C11.9356 10.6412 11.4388 10.8574 10.9851 10.7027L10.9496 10.6906C10.4866 10.5328 10.2391 10.0295 10.3969 9.56653C10.4027 9.5496 10.409 9.53285 10.4157 9.51629C10.4818 9.35503 10.5453 9.21248 10.6062 9.08867C11.2436 7.79205 12.4437 6.99 13.934 6.99Z"
              fill="#303030"/>
        </svg>
        <span>Часто задаваемые вопросы</span>
      </h2>
      <el-collapse class="support-page_faq__items">
        <el-collapse-item v-for="(node, k) in faqNodes" :key="k" class="item">
          <template #title>
            <span class="item-title">{{ node.question }}</span>
          </template>
          <p v-html="node.answer" />
        </el-collapse-item>
      </el-collapse>
      <!--      <div class="support-page_faq__items">-->
      <!--        <router-link to="#" class="item" v-for="(node, k) in faqNodes" :key="k">-->
      <!--          <span class="item-title">{{ node.question }}</span>-->
      <!--          <span class="item-icon">-->
      <!--            <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
      <!--<path-->
      <!--    d="M4.70581 7.70575C4.31602 7.31596 4.31815 6.68186 4.70612 6.2939L4.79396 6.20605C5.18392 5.81609 5.81355 5.81348 6.20803 6.20796L11.2921 11.292C11.6831 11.683 11.6865 12.3135 11.2921 12.708L6.20803 17.792C5.81702 18.183 5.18193 18.1819 4.79396 17.7939L4.70612 17.706C4.31616 17.3161 4.31281 16.6872 4.70581 16.2942L9.00004 12L4.70581 7.70575Z"-->
      <!--    fill="#B8C1CC"/>-->
      <!--</svg>-->
      <!--          </span>-->
      <!--        </router-link>-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import {api} from "@/shared/services/api";

export default {
  name: "CabinetSupportIndexPage",
  data() {
    return {
      faqNodes: []
    }
  },
  methods: {
    fetchFaqList: function () {
      api.lensmark.faq.fetchList().then(rs => {
        this.faqNodes = rs.data.data;
      });
    }
  },
  beforeMount() {
    this.fetchFaqList();
  }
}
</script>

<style lang="scss" scoped>
.support-page {
  &_header {
    padding: 28px 16px 42px;

    .support {
      &-items {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 27px;

        &_item {
          display: flex;
          text-decoration: none;
          flex-direction: column;
          justify-content: center;
          justify-items: center;
          align-items: center;
          row-gap: 14px;
          text-align: center;

          > svg {
            display: block
          }

          > span {
            display: block;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 18px;
            color: #878787;
          }
        }
      }
    }
  }

  &_faq {
    padding: 0 16px;

    &__title {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #878787;

      > span {
        display: block;
        padding-left: 9px;
      }
    }

    &__items {
      display: flex;
      flex-direction: column;

      .item {
        text-decoration: none;

        &-title {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: #000000;
        }
      }
    }
  }
}
</style>
import {v4 as uuidv4} from "uuid";
import {Storage} from "@/shared/services/storage";

export default {
    getId: function () {
        return Storage.get('user::guestID')
    },
    initGuestID: function () {
        Storage.set('user::guestID', uuidv4());
    },
    checkGuestID: function () {
        return !!this.getId();
    }
}
import axios from "axios";
import {stringify} from "qs";
import {v4 as uuidv4} from "uuid"

import {catalog} from "@/shared/services/api/catalog/catalog";
import {gallery} from "@/shared/services/api/catalog/gallery";
import {cart} from "@/shared/services/api/order/cart";
import {user} from "@/shared/services/api/user/user";
import {auth as authEcosystem} from "@/shared/services/api/ecosystem/auth";
import address from "@/shared/services/api/personal/address";
import geo from "@/shared/services/api/geo/geo";
import order from "@/shared/services/api/order/order";
import {AuthToken} from "@/shared/services/storage/models/AuthToken";
import city from "@/shared/services/api/personal/city";
import delivery from "@/shared/services/api/delivery/delivery";
import {faq} from "@/shared/services/api/faq";
import Guest from "@/shared/services/Guest";

const client = axios.create({
    baseURL: process.env.VUE_APP_ECOSYSTEM_API_HOST
})

const clientLensmark = axios.create({
    baseURL: process.env.VUE_APP_API_HOST,
    paramsSerializer: stringify,
    withCredentials: true,
    transformRequest: [
        data => stringify(data)
    ]
});

client.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json';
        config.headers['X-Idempotency-key'] = uuidv4();
        config.headers['X-Fingerprint'] = `lensmark-fingerprint-`+uuidv4();
        if (AuthToken.getAccessToken() && AuthToken.getTokenValidated()) {
            config.headers['Authorization'] = `Bearer ${AuthToken.getAccessToken()}`
        }
        return config;
    },
    err => Promise.reject(err)
)

clientLensmark.interceptors.request.use(
    config => {
        config.headers['X-Guest-Id'] = Guest.getId()
        if (AuthToken.getAccessToken() && AuthToken.getTokenValidated()) {
            config.headers['Authorization'] = `Bearer ${AuthToken.getAccessToken()}`
        }
        return config;
    },
    err => Promise.reject(err)
)

export const api = {
    lensmark: {
        faq: faq(clientLensmark),
        catalog: catalog(clientLensmark),
        gallery: gallery(clientLensmark),
        cart: cart(clientLensmark),
        order: order(clientLensmark),
        user: user(clientLensmark),
        geo: geo(clientLensmark),
        personal: {
            city: city(clientLensmark),
            address: address(clientLensmark),
        },
        delivery: delivery(clientLensmark)
    },
    ecosystem: {
        auth: authEcosystem(client)
    }
}
<template>
  <div class="page thank-you-page" v-loading="!loaded">
    <div v-if="loaded">
      <el-result
          class="page-body"
          icon="success"
          :title="`Спасибо за заказ ${ order.id }`"
          subTitle="Заказ успешно оплачен"
      >
        <template #extra>
          <router-link :to="{name: 'CabinetOrderDetail', params: {id: order.id}}" class="btn-default">Перейти в заказ
          </router-link>
        </template>
      </el-result>
    </div>
  </div>
  <!--  <div class="page thank-you-page" v-loading="!loaded">-->
  <!--    <div class="page-header">-->
  <!--      <h2 class="page-header_title">Спасибо за заказ #{{ order.id }}</h2>-->
  <!--      <h3 class="page-header_subtitle">Заказ успешно оплачен</h3>-->
  <!--    </div>-->
  <!--    <div class="page-body">-->
  <!--      <router-link :to="{name: 'CabinetOrderDetail', params: {id: order.id}}" class="btn-default">Перейти в заказ</router-link>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
import {api} from "@/shared/services/api";

export default {
  name: "ThankYou",
  data: () => {
    return {
      loaded: false,
      order: Object
    }
  },
  methods: {
    fetchOrder: function () {
      api.lensmark.order.getOne(this.$route.params.id).then(rs => {
        this.order = rs.data.data;
        this.loaded = true;
      });
    }
  },
  created() {
    this.fetchOrder();
  }
}
</script>

<style lang="scss">
.page {
  &-header {
    padding: 156px 15px 0;
    text-align: center;

    &_title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    &_subtitle {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #A6A6A6;
    }
  }

  &-body {
    padding: 0 16px;

    .el-result__icon {
      svg {
        fill: #029D95;
      }
    }

    > div {
      width: 100%;
    }
  }

  .btn-default {
    text-decoration: none;
    background-color: #029D95;
    border-radius: 4px;
    height: 44px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    margin-top: 24px;

    &:focus, &:active {
      background-color: #029D95;
      color: #FFFFFF;
      border-color: #029D95;
    }
  }
}
</style>
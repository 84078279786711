<template>
  <g>
    <svg width="88" height="77" viewBox="0 0 88 77" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.4218 54.485C46.129 54.485 45.8361 54.4484 45.5432 54.3386C44.7745 54.1189 44.1888 53.6065 43.8227 52.9109L28.6674 24.211C28.3013 23.5155 28.2281 22.7101 28.4478 21.978C28.6674 21.2092 29.1799 20.6235 29.8755 20.2574L67.5441 0.343185C68.2397 -0.0228855 69.045 -0.0960907 69.7771 0.123552C70.5459 0.343194 71.1316 0.855702 71.4977 1.55124L86.653 30.2512C87.4217 31.6788 86.8726 33.4726 85.4083 34.2413L59.1611 48.1154C58.7218 48.335 58.1727 48.1886 57.9164 47.7493C57.6968 47.31 57.8432 46.7609 58.2825 46.5047L84.5298 32.6306C85.0789 32.3378 85.2985 31.6788 85.0057 31.1297L69.8503 2.42979C69.7039 2.17354 69.4843 1.99051 69.1914 1.88069C68.8986 1.80747 68.6057 1.80748 68.3495 1.9539L30.6808 21.8682C30.4245 22.0146 30.2415 22.2342 30.1317 22.5271C30.0585 22.8199 30.0585 23.1128 30.2049 23.369L45.3602 52.069C45.5066 52.3252 45.7263 52.5083 46.0192 52.6181C46.312 52.6913 46.6049 52.6913 46.8611 52.5449L52.7915 49.3966C53.2307 49.177 53.7799 49.3234 54.0361 49.7627C54.2558 50.202 54.1093 50.7511 53.67 51.0074L47.7397 54.1556C47.3736 54.3752 46.8977 54.485 46.4218 54.485Z" fill="#07A9A1"/>
      <path d="M25.2263 59.6098C24.8969 59.6098 24.5674 59.4268 24.421 59.1339L13.6219 38.5974C13.4023 38.1581 13.5487 37.609 13.988 37.3527L25.2263 31.459C25.6656 31.2393 26.2147 31.3858 26.471 31.8617L37.2701 52.3982C37.3799 52.6179 37.4165 52.8741 37.3433 53.0938C37.2701 53.3134 37.1236 53.533 36.904 53.6429L25.6656 59.5366C25.5192 59.5732 25.3728 59.6098 25.2263 59.6098ZM15.6719 38.5608L25.5924 57.4866L35.1835 52.4348L25.263 33.5456L15.6719 38.5608Z" fill="#07A9A1"/>
      <path d="M23.4692 57.3768L7.03262 57.0108C6.70316 57.0108 6.37371 56.7911 6.22729 56.4983L5.49514 54.9608C5.34872 54.6679 5.38531 54.3018 5.56834 54.009L15.013 41.2331C15.3058 40.8304 15.8915 40.7572 16.2942 41.0501C16.6969 41.3429 16.7701 41.9286 16.4773 42.3313L7.3621 54.6679L7.61833 55.217L23.4692 55.5465C23.9817 55.5465 24.3844 55.9858 24.3478 56.4983C24.3844 56.9742 23.9817 57.3768 23.4692 57.3768Z" fill="#07A9A1"/>
      <path d="M6.00762 76.0831C3.3353 76.0831 1.13891 74.6189 0.370165 72.2028C-1.0209 67.8466 1.79784 63.9662 4.03087 60.8546C4.61658 60.0493 5.12907 59.3171 5.56835 58.6216C5.8246 58.1823 6.41032 58.0725 6.813 58.3287C7.25228 58.585 7.36208 59.1707 7.10583 59.5734C6.66654 60.3055 6.08087 61.0742 5.49515 61.9162C3.37194 64.8448 0.95586 68.1394 2.09068 71.6537C2.78621 73.8501 4.98264 74.3992 6.66656 74.2162C7.17906 74.143 7.61836 74.509 7.69158 75.0215C7.76479 75.534 7.39869 75.9733 6.88619 76.0465C6.59334 76.0831 6.30047 76.0831 6.00762 76.0831Z" fill="#07A9A1"/>
      <path d="M6.30042 76.1195C5.93435 76.1195 5.56829 76.0829 5.16561 76.0463C4.65311 75.9731 4.32366 75.4971 4.39687 75.0213C4.47009 74.5088 4.94595 74.1793 5.42184 74.2525C7.06916 74.5088 9.30222 74.0329 10.071 71.8365C11.279 68.3954 9.00936 64.9909 6.95937 61.9891C6.37366 61.1472 5.86113 60.3418 5.42184 59.6097C5.1656 59.1704 5.31203 58.6213 5.75132 58.365C6.1906 58.1088 6.73972 58.2552 6.99597 58.6945C7.39864 59.39 7.91114 60.1588 8.46025 60.9642C10.6201 64.149 13.2924 68.1025 11.7549 72.4222C11.0227 74.6918 8.86292 76.1195 6.30042 76.1195Z" fill="#07A9A1"/>
      <path d="M40.8211 53.4234C40.4916 53.4234 40.1621 53.2404 40.0157 52.9475L27.3131 28.9333C27.0934 28.494 27.2399 27.9449 27.6791 27.6887C28.1184 27.469 28.6675 27.6155 28.9238 28.0547L41.6264 52.069C41.8461 52.5083 41.6996 53.0574 41.2603 53.3136C41.1139 53.3868 40.9675 53.4234 40.8211 53.4234Z" fill="#07A9A1"/>
      <path d="M58.3923 40.062H58.1726C54.5851 39.9888 51.2539 38.5611 48.7646 35.962C46.2753 33.3629 44.9208 29.9951 44.994 26.4076C45.1039 19.1228 51.144 13.1924 58.4288 13.1924H58.6485C66.0431 13.3023 71.9734 19.4156 71.8636 26.8469C71.6806 34.1317 65.6404 40.062 58.3923 40.062ZM58.3556 15.0594C52.0592 15.0594 46.861 20.1844 46.7512 26.4442C46.7146 29.5558 47.8494 32.4844 50.0092 34.6808C52.169 36.9138 55.061 38.1584 58.136 38.1951H58.319C64.6154 38.1951 69.8137 33.0701 69.9235 26.8103C70.0333 20.404 64.9083 15.1326 58.5386 15.0228L58.3556 15.0594Z" fill="#07A9A1"/>
      <path d="M19.9549 40.721C19.6254 40.721 19.2959 40.5379 19.1495 40.2085C18.9298 39.7692 19.1129 39.2201 19.5521 38.9638L23.8352 36.8406C24.2745 36.621 24.8236 36.804 25.0798 37.2433C25.2995 37.6826 25.1164 38.2317 24.6771 38.4879L20.3941 40.6111C20.2111 40.721 20.0647 40.721 19.9549 40.721Z" fill="#07A9A1"/>
      <path d="M22.2246 45.1867C21.8951 45.1867 21.5657 45.0037 21.4192 44.6742C21.1996 44.235 21.3826 43.6858 21.8219 43.4296L26.1049 41.3064C26.5442 41.0867 27.0933 41.2698 27.3496 41.7091C27.5692 42.1483 27.3862 42.6975 26.9469 42.9537L22.6638 45.0769C22.5174 45.1501 22.371 45.1867 22.2246 45.1867Z" fill="#07A9A1"/>
      <path d="M24.5308 49.9457C24.2013 49.9457 23.8718 49.7627 23.7254 49.4332C23.5058 48.9939 23.6888 48.4448 24.1281 48.1886L28.4111 46.0654C28.8504 45.8457 29.3995 46.0287 29.6558 46.468C29.8754 46.9073 29.6923 47.4564 29.2531 47.7127L24.97 49.8359C24.787 49.9091 24.6772 49.9457 24.5308 49.9457Z" fill="#07A9A1"/>
      <path d="M26.4709 54.3754C26.1415 54.3754 25.812 54.1924 25.6656 53.8629C25.4459 53.4236 25.629 52.8745 26.0683 52.6183L30.3513 50.495C30.7905 50.2754 31.3397 50.4585 31.5959 50.8977C31.8155 51.337 31.6325 51.8861 31.1932 52.1424L26.9102 54.2656C26.7638 54.3388 26.6173 54.3754 26.4709 54.3754Z" fill="#07A9A1"/>
      <path d="M64.0586 63.7591C63.8879 63.7591 63.7172 63.7378 63.5465 63.6737C63.0984 63.5457 62.757 63.247 62.5436 62.8416L53.7098 46.1128C53.4964 45.7073 53.4538 45.2379 53.5818 44.8112C53.7098 44.3631 54.0085 44.0217 54.414 43.8083L76.3705 32.2005C76.776 31.9871 77.2454 31.9445 77.6721 32.0725C78.1202 32.2005 78.4616 32.4993 78.675 32.9047L87.5088 49.6335C87.9569 50.4657 87.6369 51.5112 86.7834 51.9593L71.4842 60.0463C71.2281 60.1743 70.9081 60.089 70.7587 59.8329C70.6307 59.5769 70.716 59.2568 70.9721 59.1075L86.2712 51.0204C86.5913 50.8497 86.7193 50.4657 86.5486 50.1456L77.7148 33.4168C77.6294 33.2674 77.5014 33.1607 77.3307 33.0967C77.16 33.054 76.9893 33.054 76.84 33.1394L54.8834 44.7471C54.734 44.8325 54.6273 44.9605 54.5633 45.1312C54.5206 45.3019 54.5207 45.4726 54.606 45.622L63.4398 62.3508C63.5252 62.5002 63.6532 62.6069 63.8239 62.6709C63.9946 62.7136 64.1653 62.7135 64.3147 62.6282L67.7714 60.7931C68.0275 60.6651 68.3475 60.7505 68.4969 61.0065C68.6249 61.2626 68.5396 61.5826 68.2835 61.732L64.8268 63.5671C64.6134 63.6951 64.336 63.7591 64.0586 63.7591Z" fill="white"/>
      <path d="M51.7042 66.7463C51.5122 66.7463 51.3202 66.6396 51.2348 66.4689L44.9402 54.4984C44.8121 54.2424 44.8975 53.9223 45.1535 53.7729L51.7042 50.3376C51.9603 50.2095 52.2804 50.2949 52.4297 50.5723L58.7244 62.5428C58.7884 62.6708 58.8097 62.8202 58.7671 62.9482C58.7244 63.0762 58.639 63.2042 58.511 63.2683L51.9603 66.7036C51.8749 66.725 51.7896 66.7463 51.7042 66.7463ZM46.1351 54.4771L51.9176 65.5087L57.5081 62.5641L51.7256 51.5538L46.1351 54.4771Z" fill="white"/>
      <path d="M50.6799 65.4448L41.0992 65.2314C40.9072 65.2314 40.7152 65.1034 40.6298 64.9327L40.2031 64.0365C40.1177 63.8658 40.139 63.6524 40.2457 63.4817L45.7509 56.0348C45.9216 55.8001 46.263 55.7574 46.4977 55.9281C46.7324 56.0988 46.7751 56.4402 46.6044 56.6749L41.2913 63.8658L41.4406 64.1858L50.6799 64.3779C50.9786 64.3779 51.2134 64.6339 51.192 64.9327C51.2134 65.21 50.9786 65.4448 50.6799 65.4448Z" fill="white"/>
      <path d="M40.5018 76.3483C38.9441 76.3483 37.6639 75.4948 37.2158 74.0865C36.4049 71.5473 38.0479 69.2855 39.3495 67.4718C39.6909 67.0023 39.9897 66.5756 40.2457 66.1702C40.3951 65.9141 40.7365 65.8501 40.9712 65.9995C41.2273 66.1488 41.2913 66.4902 41.1419 66.725C40.8858 67.1517 40.5445 67.5998 40.2031 68.0906C38.9655 69.7976 37.5572 71.718 38.2186 73.7664C38.624 75.0467 39.9043 75.3667 40.8859 75.2601C41.1846 75.2174 41.4406 75.4308 41.4833 75.7295C41.526 76.0282 41.3126 76.2843 41.0139 76.3269C40.8432 76.3483 40.6725 76.3483 40.5018 76.3483Z" fill="white"/>
      <path d="M40.6726 76.3696C40.4592 76.3696 40.2459 76.3483 40.0111 76.327C39.7124 76.2843 39.5204 76.0069 39.563 75.7295C39.6057 75.4308 39.8831 75.2387 40.1605 75.2814C41.1207 75.4308 42.4223 75.1534 42.8704 73.8731C43.5745 71.8674 42.2516 69.883 41.0567 68.1333C40.7153 67.6425 40.4165 67.1731 40.1605 66.7463C40.0111 66.4903 40.0965 66.1702 40.3525 66.0208C40.6086 65.8715 40.9287 65.9568 41.078 66.2129C41.3127 66.6183 41.6115 67.0664 41.9315 67.5358C43.1905 69.3922 44.7481 71.6967 43.8519 74.2145C43.4252 75.5375 42.1662 76.3696 40.6726 76.3696Z" fill="white"/>
      <path d="M60.794 63.1403C60.602 63.1403 60.4099 63.0336 60.3246 62.8629L52.9204 48.8653C52.7924 48.6092 52.8777 48.2892 53.1338 48.1398C53.3898 48.0118 53.7099 48.0971 53.8593 48.3532L61.2635 62.3508C61.3915 62.6068 61.3061 62.9269 61.0501 63.0763C60.9647 63.1189 60.8794 63.1403 60.794 63.1403Z" fill="white"/>
      <path d="M71.0361 55.352H70.9081C68.817 55.3094 66.8753 54.4772 65.4243 52.9622C63.9733 51.4472 63.1838 49.4842 63.2265 47.3931C63.2905 43.1468 66.8113 39.6901 71.0575 39.6901H71.1855C75.4957 39.7541 78.9524 43.3175 78.8884 47.6491C78.7817 51.8953 75.261 55.352 71.0361 55.352ZM71.0148 40.7783C67.3447 40.7783 64.3147 43.7656 64.2507 47.4144C64.2294 49.2281 64.8909 50.9351 66.1498 52.2154C67.4087 53.517 69.0944 54.2425 70.8868 54.2638H70.9935C74.6635 54.2638 77.6935 51.2765 77.7575 47.6278C77.8216 43.8937 74.8342 40.821 71.1215 40.757L71.0148 40.7783Z" fill="white"/>
      <path d="M48.6316 55.7362C48.4396 55.7362 48.2475 55.6295 48.1622 55.4374C48.0341 55.1814 48.1408 54.8613 48.3969 54.7119L50.8934 53.4744C51.1495 53.3463 51.4695 53.453 51.6189 53.7091C51.7469 53.9651 51.6402 54.2852 51.3842 54.4346L48.8876 55.6721C48.781 55.7362 48.6956 55.7362 48.6316 55.7362Z" fill="white"/>
      <path d="M49.9543 58.3392C49.7623 58.3392 49.5703 58.2325 49.4849 58.0405C49.3569 57.7844 49.4636 57.4643 49.7196 57.315L52.2161 56.0774C52.4722 55.9494 52.7923 56.056 52.9416 56.3121C53.0696 56.5681 52.963 56.8882 52.7069 57.0376L50.2104 58.2752C50.125 58.3178 50.0397 58.3392 49.9543 58.3392Z" fill="white"/>
      <path d="M51.2986 61.1132C51.1066 61.1132 50.9145 61.0065 50.8292 60.8145C50.7011 60.5585 50.8078 60.2384 51.0639 60.089L53.5604 58.8514C53.8165 58.7234 54.1365 58.8301 54.2859 59.0861C54.4139 59.3422 54.3072 59.6623 54.0512 59.8116L51.5546 61.0492C51.448 61.0919 51.384 61.1132 51.2986 61.1132Z" fill="white"/>
      <path d="M52.4297 63.6951C52.2376 63.6951 52.0456 63.5884 51.9603 63.3964C51.8322 63.1403 51.9389 62.8202 52.195 62.6709L54.6915 61.4333C54.9475 61.3052 55.2676 61.4119 55.417 61.668C55.545 61.9241 55.4383 62.2441 55.1823 62.3935L52.6857 63.6311C52.6004 63.6738 52.515 63.6951 52.4297 63.6951Z" fill="white"/>
    </svg>
  </g>
</template>
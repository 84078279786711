<template>
  <g>
    <g clip-path="url(#clip0)">
      <path
          d="M23.0013 11.5667C22.8022 11.2943 18.0585 4.89807 11.9999 4.89807C5.94138 4.89807 1.19747 11.2943 0.998597 11.5664C0.810038 11.8248 0.810038 12.1752 0.998597 12.4336C1.19747 12.7059 5.94138 19.1022 11.9999 19.1022C18.0585 19.1022 22.8022 12.7059 23.0013 12.4338C23.19 12.1754 23.19 11.8248 23.0013 11.5667ZM11.9999 17.6328C7.53716 17.6328 3.67193 13.3874 2.52774 11.9996C3.67045 10.6105 7.52758 6.36745 11.9999 6.36745C16.4625 6.36745 20.3274 10.612 21.4721 12.0006C20.3294 13.3896 16.4723 17.6328 11.9999 17.6328Z"
          fill="currentColor"/>
      <path
          d="M12.1226 7.59216C9.69187 7.59216 7.71436 9.56975 7.71436 12.0003C7.71436 14.4309 9.69187 16.4085 12.1226 16.4085C14.5532 16.4085 16.5308 14.4309 16.5308 12.0003C16.5308 9.56975 14.5532 7.59216 12.1226 7.59216ZM12.1226 12.8572C10.502 12.8572 13.4096 13.6205 13.4096 12.0001C13.4096 10.3797 10.9321 11.1429 12.5525 11.1429C14.173 11.1429 11.2668 10.3797 11.2668 12.0001C11.2668 13.6205 13.7431 12.8572 12.1226 12.8572Z"
          fill="currentColor"/>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="22.2857" height="22.2857" fill="white" transform="translate(0.857178 0.857178)"/>
      </clipPath>
    </defs>
  </g>
</template>
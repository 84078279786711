import {Storage} from "@/shared/services/storage";

export const AuthToken = {
    getTokenType: function () {
        return Storage.get('auth::token_type');
    },
    setTokenType: function (value) {
        return Storage.set('auth::token_type', value);
    },
    getAccessToken: function () {
        return Storage.get('auth::access_token');
    },
    setAccessToken: function (value) {
        return Storage.set('auth::access_token', value);
    },
    getRefreshToken: function () {
        return Storage.get('auth::refresh_token');
    },
    setRefreshToken: function (value) {
        return Storage.set('auth::refresh_token', value);
    },
    getExpireIn: function () {
        return Storage.get('auth::expire_in');
    },
    setExpireIn: function (value) {
        return Storage.set('auth::expire_in', value);
    },
    getTokenValidated: function () {
        return Storage.get('auth::token_validated');
    },
    setTokenValidated: function (value) {
        return Storage.set('auth::token_validated', value);
    }
}
<template>
  <div class="filter">
    <catalog-filter-element
        :type="item.type"
        :title="item.title"
        :items="item.items"
        :direction="item.type === 'buttons' ? 'flex-row' : 'flex-column'"
        :key="key"
        v-for="(item, key) in items"
    />
    <div class="container">
      <div class="filter-actions">
        <button class="filter-actions_btn btn success" v-on:click="toCatalog">Показать 10 результатов</button>
      </div>
    </div>
  </div>
</template>

<script>
import CatalogFilterElement from "@/components/catalog/filter/Element";

export default {
  name: "CatalogFilterIndex",
  components: {
    CatalogFilterElement
  },
  created() {
  },
  methods: {
    toCatalog: function () {
      this.$router.go(-1)
    }
  },
  data() {
    return {
      items: []
    }
  }
}
</script>

<style lang="scss" scoped>
.filter {
  min-height: 100vh;

  &-actions {
    margin: 16px 0;

    &_btn {
      width: 100%;
      height: 44px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      margin-bottom: 10px;
      border-radius: 4px;
      color: #FFFFFF;
      background-color: #029D95;
    }
  }
}
</style>
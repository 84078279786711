export default class User {
    id;
    username;
    firstName;
    lastName;
    phone;
    email;
    externalId;

    constructor(id, username, firstName, lastName, phone, email, externalId) {
        this.id = id;
        this.username = username;
        this.firstName = firstName;
        this.lastName = lastName;
        this.phone = phone;
        this.email = email;
        this.externalId = externalId;
    }

    get fullName() {
        return `${this.firstName ?? 'Не указан'} ${this.lastName ?? ''}`;
    }
}
<template>
  <g>
    <path
        d="M50.3079 52.9555H40.2893C39.7884 52.9555 39.3948 52.5619 39.3948 52.061V41.3268C39.3948 40.8258 39.7884 40.4323 40.2893 40.4323H50.3079C50.8089 40.4323 51.2024 40.8258 51.2024 41.3268V52.061C51.2024 52.5619 50.8089 52.9555 50.3079 52.9555ZM41.1838 51.1665H49.4134V42.2213H41.1838V51.1665Z"
        fill="#07A9A1"/>
    <path
        d="M53.1703 73.3505H37.4267C36.9258 73.3505 36.5322 72.9569 36.5322 72.456V52.0609C36.5322 51.56 36.9258 51.1664 37.4267 51.1664H53.1703C53.6712 51.1664 54.0648 51.56 54.0648 52.0609V72.456C54.0648 72.9569 53.6712 73.3505 53.1703 73.3505ZM38.3213 71.5614H52.2758V52.9554H38.3213V71.5614Z"
        fill="#07A9A1"/>
    <path
        d="M42.7939 41.6847C42.293 41.6847 41.8994 41.2911 41.8994 40.7902V33.8487C41.8994 32.3102 43.0086 31.1294 44.4398 31.1294H45.6922C47.1592 31.1294 48.3042 32.3102 48.3042 33.8487V40.4324C48.3042 40.9333 47.9106 41.3269 47.4097 41.3269C46.9087 41.3269 46.5151 40.9333 46.5151 40.4324V33.8487C46.5151 33.2762 46.0858 32.9184 45.6922 32.9184H44.4398C44.0105 32.9184 43.6885 33.312 43.6885 33.8487V40.7902C43.6885 41.2911 43.2949 41.6847 42.7939 41.6847Z"
        fill="#07A9A1"/>
    <path
        d="M23.8301 11.2351C23.7943 11.2351 23.5438 11.2351 23.3291 11.0919H7.08461C6.65525 11.0919 6.29743 10.9488 6.04696 10.6626C5.76072 10.3405 5.76074 9.98271 5.76074 9.73224V2.86234C5.76074 1.25221 6.90573 -0.00012207 8.4443 -0.00012207H21.8263C23.4365 -0.00012207 24.7246 1.28799 24.7246 2.86234V9.37443C24.7246 9.66068 24.7246 9.87537 24.7246 10.0543C24.7246 10.1258 24.7246 10.1974 24.7246 10.269C24.6888 11.1635 23.9732 11.2351 23.8301 11.2351ZM8.4443 1.78892C7.83603 1.78892 7.54978 2.32563 7.54978 2.86234V9.30289H22.9355V2.86234C22.9355 2.28985 22.4346 1.78892 21.8263 1.78892H8.4443Z"
        fill="#07A9A1"/>
    <path
        d="M24.6172 72.9932H5.47444C2.46885 72.9932 0 70.5601 0 67.5187V18.6779C0 15.6723 2.43307 13.2034 5.47444 13.2034H15.3857C15.8867 13.2034 16.2803 13.597 16.2803 14.098C16.2803 14.5989 15.8867 14.9925 15.3857 14.9925H5.47444C3.43493 14.9925 1.78904 16.6384 1.78904 18.6779V67.5187C1.78904 69.5582 3.43493 71.2041 5.47444 71.2041H24.6172C26.6567 71.2041 28.3026 69.5582 28.3026 67.5187V18.6779C28.3026 16.6384 26.6567 14.9925 24.6172 14.9925H18.9996C18.4987 14.9925 18.1051 14.5989 18.1051 14.098C18.1051 13.597 18.4987 13.2034 18.9996 13.2034H24.6172C27.6228 13.2034 30.0917 15.6365 30.0917 18.6779V67.5187C30.0917 70.5243 27.6585 72.9932 24.6172 72.9932Z"
        fill="#07A9A1"/>
    <path
        d="M10.2334 14.8497C9.73246 14.8497 9.33887 14.4561 9.33887 13.9552V11.4506C9.33887 10.9496 9.73246 10.556 10.2334 10.556C10.7343 10.556 11.1279 10.9496 11.1279 11.4506V13.9552C11.1279 14.4561 10.7343 14.8497 10.2334 14.8497Z"
        fill="#07A9A1"/>
    <path
        d="M19.894 12.7025C19.3931 12.7025 18.9995 12.3089 18.9995 11.808V11.0924C18.9995 10.5915 19.3931 10.1979 19.894 10.1979C20.395 10.1979 20.7886 10.5915 20.7886 11.0924V11.808C20.7886 12.3089 20.395 12.7025 19.894 12.7025Z"
        fill="#07A9A1"/>
    <path
        d="M67.5549 54.3756H60.8622C60.5276 54.3756 60.2646 54.1127 60.2646 53.7781V46.6073C60.2646 46.2727 60.5276 46.0098 60.8622 46.0098H67.5549C67.8895 46.0098 68.1525 46.2727 68.1525 46.6073V53.7781C68.1525 54.1127 67.8895 54.3756 67.5549 54.3756ZM61.4598 53.1805H66.9573V47.2049H61.4598V53.1805Z"
        fill="white"/>
    <path
        d="M69.4672 68.0001H58.9501C58.6155 68.0001 58.3525 67.7371 58.3525 67.4025V53.7781C58.3525 53.4435 58.6155 53.1805 58.9501 53.1805H69.4672C69.8018 53.1805 70.0647 53.4435 70.0647 53.7781V67.4025C70.0647 67.7371 69.8018 68.0001 69.4672 68.0001ZM59.5477 66.8049H68.8696V54.3757H59.5477V66.8049Z"
        fill="white"/>
    <path
        d="M62.5355 46.8464C62.2009 46.8464 61.938 46.5835 61.938 46.2488V41.6117C61.938 40.5839 62.679 39.7952 63.6351 39.7952H64.4716C65.4516 39.7952 66.2165 40.5839 66.2165 41.6117V46.0098C66.2165 46.3444 65.9536 46.6074 65.619 46.6074C65.2843 46.6074 65.0214 46.3444 65.0214 46.0098V41.6117C65.0214 41.2293 64.7346 40.9903 64.4716 40.9903H63.6351C63.3482 40.9903 63.1331 41.2532 63.1331 41.6117V46.2488C63.1331 46.5835 62.8702 46.8464 62.5355 46.8464Z"
        fill="white"/>
    <path
        d="M49.8674 26.5054C49.8435 26.5054 49.6761 26.5054 49.5327 26.4098H38.681C38.3942 26.4098 38.1551 26.3142 37.9878 26.1229C37.7966 25.9078 37.7966 25.6688 37.7966 25.5015V20.9122C37.7966 19.8366 38.5615 19 39.5893 19H48.5288C49.6044 19 50.4649 19.8605 50.4649 20.9122V25.2624C50.4649 25.4536 50.4649 25.5971 50.4649 25.7166C50.4649 25.7644 50.4649 25.8122 50.4649 25.86C50.441 26.4575 49.963 26.5054 49.8674 26.5054ZM39.5893 20.1951C39.183 20.1951 38.9918 20.5537 38.9918 20.9122V25.2146H49.2698V20.9122C49.2698 20.5298 48.9352 20.1951 48.5288 20.1951H39.5893Z"
        fill="white"/>
    <path
        d="M50.3931 67.7608H37.6053C35.5975 67.7608 33.9482 66.1354 33.9482 64.1037V31.4769C33.9482 29.4691 35.5736 27.8198 37.6053 27.8198H44.2263C44.5609 27.8198 44.8239 28.0828 44.8239 28.4174C44.8239 28.752 44.5609 29.0149 44.2263 29.0149H37.6053C36.2429 29.0149 35.1434 30.1144 35.1434 31.4769V64.1037C35.1434 65.4661 36.2429 66.5657 37.6053 66.5657H50.3931C51.7555 66.5657 52.8551 65.4661 52.8551 64.1037V31.4769C52.8551 30.1144 51.7555 29.0149 50.3931 29.0149H46.6404C46.3058 29.0149 46.0429 28.752 46.0429 28.4174C46.0429 28.0828 46.3058 27.8198 46.6404 27.8198H50.3931C52.4009 27.8198 54.0502 29.4452 54.0502 31.4769V64.1037C54.0502 66.1115 52.4248 67.7608 50.3931 67.7608Z"
        fill="white"/>
    <path
        d="M40.7843 28.9196C40.4497 28.9196 40.1868 28.6567 40.1868 28.3221V26.6489C40.1868 26.3143 40.4497 26.0513 40.7843 26.0513C41.119 26.0513 41.3819 26.3143 41.3819 26.6489V28.3221C41.3819 28.6567 41.119 28.9196 40.7843 28.9196Z"
        fill="white"/>
    <path
        d="M47.2379 27.4852C46.9033 27.4852 46.6404 27.2223 46.6404 26.8877V26.4096C46.6404 26.075 46.9033 25.8121 47.2379 25.8121C47.5726 25.8121 47.8355 26.075 47.8355 26.4096V26.8877C47.8355 27.2223 47.5726 27.4852 47.2379 27.4852Z"
        fill="white"/>
  </g>
</template>
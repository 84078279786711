import {ElMessage} from "element-plus";

export const NotifyCenter = {
    push: function (type, message, showClose) {
        ElMessage({
            type: type,
            message: message,
            showClose: showClose,
            offset: 40
        })
    }
}
<template>
  <div class="home">
    <div class="container">
      <SearchLink></SearchLink>
    </div>
    <banner-slider/>
    <div class="container">
      <menu-grid/>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex"
import SearchLink from "../components/catalog/SearchLink";
import BannerSlider from "@/components/BannerSlider";
import MenuGrid from "@/components/MenuGrid";

export default {
  name: 'Home',
  components: {
    SearchLink,
    BannerSlider,
    MenuGrid
  },
  methods: {
    ...mapActions({
      filterDiscard: "catalog/filter/discard"
    })
  },
  created() {
    this.filterDiscard()
  },
  computed: {
    ...mapState({
      catalogFilter: state => state.filter.filter
    })
  },
  mounted() {

  }
}
</script>
<template>
  <section class="cabinet-order-index-page" v-loading="!pageLoaded" v-if="pageLoaded">
    <el-scrollbar>
      <div class="order-filter states">
        <div class="item" v-for="(state, key) in states" :key="key" :class="state.id === filter.state ? 'active' : ''"
             v-on:click="selectFilter('state', state.id)">{{ state.name }}
        </div>
      </div>
    </el-scrollbar>
    <div class="order-items" v-infinite-scroll="scrollLoad" v-if="orders.length > 0">
      <router-link :to="{name: 'CabinetOrderDetail', params: {id: order.id}}" class="order-items_item"
                   v-for="(order, key) in orders" :key="key">
        <div class="left">
          <div class="header">
            <div class="title">Заказ #{{ order.id }}</div>
          </div>
          <div class="body">
            <div class="date">Дата: {{ order.createdAt }}</div>
            <div class="state">Дата: <span>{{ order.state.name }}</span></div>
          </div>
        </div>
        <div class="right">
          <span class="icon">
         <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M0.705812 2.70575C0.316024 2.31596 0.31815 1.68186 0.706116 1.2939L0.793961 1.20605C1.18392 0.816094 1.81355 0.813481 2.20803 1.20796L7.29205 6.29199C7.68306 6.683 7.68653 7.31348 7.29205 7.70796L2.20803 12.792C1.81702 13.183 1.18193 13.1819 0.793961 12.7939L0.706116 12.706C0.31616 12.3161 0.312811 11.6872 0.705812 11.2942L5.00004 6.99997L0.705812 2.70575Z"
    fill="#B8C1CC"/>
</svg>
        </span>
        </div>
        <!--        <div class="footer">-->
        <!--          <router-link class="link-item" :to="{name: 'CabinetOrderDetail', params: {id: order.id}}">-->
        <!--            Детали заказа-->
        <!--          </router-link>-->
        <!--&lt;!&ndash;          <div class="link-item">Поторить заказ</div>&ndash;&gt;-->
        <!--        </div>-->
      </router-link>
    </div>
    <div class="" v-if="loaded && orders.length <= 0">
      <EmptyResult title="К сожелению" sub-title="тут пока пусто =)"></EmptyResult>
    </div>
  </section>
</template>

<script>
import {api} from "@/shared/services/api";
import {mapState} from "vuex";
import EmptyResult from "@/components/EmptyResult";

export default {
  name: "CabinetOrderIndex",
  components: {EmptyResult},
  data() {
    return {
      loaded: false,
      pageLoaded: false,
      states: [
        {
          id: 0,
          name: 'Активные'
        },
        {
          id: 4,
          name: 'Доставленные'
        },
        {
          id: 6,
          name: 'Ожидают оплату'
        }
      ],
      orders: [],
      meta: {},
      page: {
        limit: 5,
        offset: 0
      },
      filter: {
        state: 0
      }
    }
  },
  methods: {
    selectFilter: function (filter, itemId) {
      this.filter[filter] = itemId;
      this.orders = [];
      this.meta = {};
      this.page.offset = 0;
      this.loaded = false;
      this.fetchOrderList();
    },
    fetchOrderList: function () {
      api.lensmark.order.getList({
        page: {
          limit: this.page.limit,
          offset: this.page.offset
        },
        filter: {
          user_id: this.user.id,
          state_id: this.filter.state
        }
      }).then(rs => {
        this.meta = rs.data.meta;
        this.addOrders(rs.data.data);
        this.pageLoaded = true;
      }).finally(() => {
        this.loaded = true;
      });
    },
    addOrders: function (orders) {
      if (this.orders.length <= 0) {
        this.orders = orders;
      } else {
        this.orders = this.orders.concat(orders);
      }
    },
    scrollLoad: function () {
      if (this.orders.length === this.meta.size) {
        this.infinityScroll = false;
        return true;
      } else {
        this.loaded = false;
        this.fetchOrderList();
        this.page.offset += this.page.limit;
      }
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  beforeMount() {
    this.fetchOrderList();
  }
}
</script>

<style lang="scss" scoped>
.cabinet-order-index-page {
  .order {
    &-filter {
      padding: 16px 16px 16px;
      display: flex;
      flex-direction: row;
      column-gap: 8px;

      .item {
        background: #F2F2F2;
        border-radius: 4px;
        padding: 7px 12px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #303030;

        &.active {
          color: white;
          background: #029D95;
        }
      }
    }

    &-items {
      &_item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        position: relative;
        text-decoration: none;
        padding: 16px;
        background-color: #FFFFFF;
        border-bottom: 1px solid #c4c4c44d;

        .header {
          margin-bottom: 8px;

          .title {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #000000;
          }
        }

        .body {
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 14px;
          color: #303030;
          margin-bottom: 6px;

          .date {
            margin-bottom: 4px;
          }

          .state {
            margin-bottom: 4px;
          }
        }

        .footer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .link-item {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #029D95;
            text-decoration: none;
          }
        }
      }
    }
  }
}
</style>
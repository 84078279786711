<template>
  <section class="banner-slider">
    <router-link
        :to="`#`" class="banner-slider_item"
        v-for="(item, key) in banner.items" :key="key">
      <el-image class="product-img" :src="`//images.lensmark.kz/upload/${item.image.name}`" lazy fit="cover">
        <template #placeholder>
          <el-skeleton style="width: inherit; height: inherit" :loading="true" animated :count="1">
            <template #template>
              <el-skeleton-item
                  variant="image"
                  style="width: 100%; height: 140px;"
              />
            </template>
          </el-skeleton>
        </template>
      </el-image>
      <!--      <span class="banner-slider_item__title" v-if="item.title">{{ item.title }}</span>-->
      <!--      <span class="banner-slider_item__sub-title" v-if="item.description">Скидка на всю продукцию</span>-->
      <!--      <span class="banner-slider_item__button">Подробнее</span>-->
    </router-link>
  </section>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "BannerSlider",
  methods: {
    ...mapActions({
      fetchBanner: "banner/fetchBanner"
    })
  },
  computed: {
    ...mapState({
      banner: state => state.banner.banner
    })
  },
  created() {
    this.fetchBanner()
  }
}
</script>

<style scoped>

</style>
<template>
  <section class="catalog-filter_els__el">
    <div class="catalog-filter_els__el-title">{{ title }}</div>
    <div class="catalog-filter_els__el-items">
        <span class="catalog-filter_els__el-items_item"
              :class="selectedItems({
                index: $route.params.id,
                itemIndex: type
              }).includes(item.value) ? 'active' : ''"
              v-on:click="select({
                index: parseInt($route.params.id),
                item: {
                  index: type,
                  value: item.value
                }
              })"
              v-for="(item, key) in items" :key="key">
          {{ item.name }}
        </span>
    </div>
  </section>
</template>

<script>
import {mapActions, mapState, mapGetters} from "vuex";

export default {
  name: "CatalogFilterOnPageElement",
  props: ['title', 'type', 'items', 'updateFilter', 'selectedItemsFromState'],
  computed: {
    ...mapState({
      filter: state => state.catalog.filter.filter
    }),
    ...mapGetters({
      selectedItems: "catalog/filter/getElementValues"
    })
  },
  methods: {
    ...mapActions({
      update: "catalog/filter/update"
    }),
    select: function (payload) {
      this.update(payload)
      this.updateFilter()
    }
  }
}
</script>

<style lang="scss" scoped>
.catalog {
  &-filter {
    &_els {
      &__el {
        margin-top: 10px;

        &-title {
          font-style: normal;
          font-weight: bold;
          font-size: 18px;
          line-height: 20px;
          color: #303030;
          margin-bottom: 12px;
        }

        &-items {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;

          &_item {
            text-align: center;
            display: block;
            background-color: #F2F2F2;
            border-radius: 4px;
            align-items: center;
            padding: 7px 12px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #A6A6A6;
            margin-right: 8px;
            margin-bottom: 12px;
            cursor: pointer;

            &.active {
              color: #FFFFFF;
              background-color: #029D95;
            }
          }
        }
      }
    }
  }
}
</style>
<template>
  <div class="cart-page">
    <div class="cart-offers" v-if="cart.totalQuantity > 0">
      <div class="cart-offers_item" v-for="(item, key) in cart.items" :key="key">
        <el-image class="cart-offers_item__img" :src="item.item.images.small" lazy fit="contain">
          <template #placeholder>
            <div class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </template>
        </el-image>
        <div class="cart-offers_item__info">
          <div class="info-head">{{ item.item.name }}</div>
          <div class="info-middle">
            <div class="info-middle_item" v-for="(option, key) in item.options" :key="key">
              <div class="left">
                <span v-if="option.id === `radius`">Базовая кривизна: </span>
                <span v-else-if="option.id === `opticalPower`">Оптическая сила: </span>
                <span v-else-if="option.id === `colors`">Цвет: </span>
                <span v-else-if="option.id === `axis`">Axis: </span>
                <span v-else-if="option.id === `cyl`">Cyl: </span>
              </div>
              <div class="right">
                {{ option.value }}
              </div>
            </div>
          </div>
          <div class="info-footer">
            <div class="price">
              <span style="font-size: 12px; font-weight: 300">
                {{ $filters.priceFormat(item.price) }} ₸ × {{ item.quantity }}
              </span>
              | {{ $filters.priceFormat(item.totalAmount) }} ₸
            </div>
            <div class="count">
              <span class="btn left" v-on:click="itemQuantityDecrease(item)">
                <img src="./../../assets/icons/icon-trash.svg" v-if="item.quantity <= 1">
                <img src="./../../assets/icons/icon-minus.svg" v-else>
              </span>
              <span class="value">{{ item.quantity }}</span>
              <span class="btn right" v-on:click="itemQuantityRaise(item)">
                <img src="./../../assets/icons/icon-plus.svg">
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div style="padding: 16px" v-else>
      <error-catalog-result-empty title="Ваша корзину пуста" sub-title="Добавьте товары в корзину"/>
      <router-link class="btn btn-default btn-success" to="/">Начать покупку</router-link>
    </div>
    <!--    <div class="cart-offers_footer">-->
    <!--      <div class="footer-delivery">-->
    <!--        <div class="left">Срок доставки:</div>-->
    <!--        <div class="right">2-4 дня</div>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="cart-page_footer" v-if="cart.items.length >= 1">
      <div class="cart-page_footer__result">
        <div class="result-row">
          <div class="left">{{ cart.totalQuantity }} товара</div>
          <div class="right">{{ $filters.priceFormat(cart.totalAmount) }} ₸</div>
        </div>
      </div>
      <router-link to="/checkout" class="btn btn-default btn-success">Оформить</router-link>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";
import {api} from "@/shared/services/api";
import ErrorCatalogResultEmpty from "@/components/catalog/error/ResultEmpty";

export default {
  name: "CartIndexPage",
  components: {ErrorCatalogResultEmpty},
  computed: {
    ...mapState({
      cart: state => state.cart.cart
    })
  },
  methods: {
    ...mapActions({
      fetchCart: "cart/FETCH_MY_CART"
    }),
    itemQuantityDecrease: function (item) {
      if (item.quantity >= 1) {
        item.quantity -= 1;

        api.lensmark.cart.update({
          item_id: item.item_id,
          quantity: item.quantity,
          price: item.price,
          options: item.options
        }).then(() => {
          this.fetchCart();
        })
      }
    },
    itemQuantityRaise: function (item) {
      if (item.quantity >= 1 && item.quantity < 10) {
        item.quantity += 1;
        api.lensmark.cart.update({
          item_id: item.item_id,
          quantity: item.quantity,
          price: item.price,
          options: item.options
        }).then(() => {
          this.fetchCart();
        })
      }
    }
  },
  beforeMount() {
    this.fetchCart();
  }
}
</script>

<style lang="scss" scoped>
.cart {
  padding-left: 5px;
  padding-right: 5px;

  &-page {
    background-color: #F2F2F2;

    &_footer {
      padding: 16px;

      &__result {
        margin-bottom: 16px;

        .result {
          &-row {
            display: flex;
            justify-content: space-between;
            align-content: center;
            align-items: center;
            margin-bottom: 6px;

            .left {
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 14px;
              color: #989898;
            }

            .right {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 16px;
              color: #000000;
            }
          }
        }
      }

      .checkout {
        text-align: center;
        width: 100%;
        background: #029D95;
        border-radius: 4px;
        padding: 14px 44px;
        color: white;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }
    }
  }

  &-offers {
    background-color: white;

    &_item {
      background: #FFFFFF;
      box-shadow: inset 0px -0.3px 0px #C4C4C4;
      display: grid;
      padding: 16px;
      grid-template-columns: 64px 1fr;
      column-gap: 16px;

      &__img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 64px;
      }

      &__info {
        .info {
          &-head {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 8px;
          }

          &-middle {
            &_item {
              font-size: 14px;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              row-gap: 5px;
              column-gap: 5px;

              .left {
                font-weight: 400;
              }

              .right {
                font-weight: 300;
              }
            }
          }

          &-footer {
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: space-between;

            .price {
              font-style: normal;
              font-weight: 900;
              font-size: 16px;
              line-height: 19px;
            }

            .count {
              display: flex;
              align-items: center;
              align-content: center;

              .btn {
                display: flex;
                justify-items: center;
                justify-content: center;
                align-items: center;
                align-content: center;
                width: 30px;
                height: 30px;
                background: #029D95;
                border-radius: 4px;
                color: white;
                padding: 0;
                cursor: pointer;

                img {
                  width: 24px;
                  height: 24px;
                  display: block;
                }
              }

              .value {
                margin: 0 13px;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 16px;
              }
            }
          }
        }
      }
    }


    &_footer {
      padding: 16px;
      background-color: white;

      .footer {
        &-delivery {
          display: flex;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;

          .left {
            color: #989898;
          }

          .right {
            color: #000000;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
</style>
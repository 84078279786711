export const category = {
    namespaced: true,
    state: {
        categories: [],
    },
    mutations: {
        setCategories: function (state, payload) {
            state.categories = payload
        }
    },
    actions: {
        setCategories: function ({commit}, payload) {
            commit("setCategories", payload)
        },
        toggleCatalogFilter: function ({commit}) {
            commit("toggleCatalogFilter")
        }
    },
    getters: {}
}

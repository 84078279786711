<template>
  <section class="catalog-add-to-cart-page" v-loading="!loaded">
    <div class="container">
      <div class="catalog-add-to-cart-page_title">Параметры глаз</div>
      <div class="catalog-add-to-cart-page_options">
        <div class="catalog-add-to-cart-page_options__type">
          <div class="catalog-add-to-cart-page_options__type-item"
               v-for="(item, key) in propertyVarious"
               :key="key"
               :class="{active: item.active}"
               v-on:click="selectOption(item.key)">
            <icon-base icon-name="eye" width="24" height="24" view-box="0 0 24 24">
              <icon-eye/>
            </icon-base>
            <span class="name">{{ item.name }}</span>
          </div>
        </div>
        <div class="catalog-add-to-cart-page_options__form" v-if="loaded">
          <property-add-to-cart
              v-for="key in propertyVariousCount"
              :key="key"
              :prop-item-id="product.id"
              :prop-item-price="product.prices.price"
              :prop-items="product.properties"
              :prop-cart-items="getCartItem(product.id)"
          />
        </div>
      </div>
      <div class="catalog-add-to-cart-page_warning">
        <i class="el-icon-warning-outline"></i>
        <p>
          Линзы продаются только упаковками
        </p>
        <p>
          Перед первой покупкой или сменой линз обязательно пройдите осмотр у офтальмолога. Контактные линзы обмену и
          возврату не подлежат.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import IconBase from "@/components/icons/IconBase";
import IconEye from "@/components/icons/IconEye";
import {mapActions, mapGetters} from "vuex";
import PropertyAddToCart from "@/views/catalog/components/PropertyAddToCart";
import {api} from "@/shared/services/api";

export default {
  name: "CatalogAddToCartPage",
  components: {
    PropertyAddToCart,
    IconBase,
    IconEye
  },
  data() {
    return {
      loaded: false,
      propertyVarious: [
        {
          name: 'Одинаковые',
          key: 'single',
          active: true
        },
        {
          name: 'Разные',
          key: 'different',
          active: false,
        }
      ],
      propertyVariousCount: 1,
      product: {}
    }
  },
  methods: {
    ...mapActions({
      fetchCart: "cart/FETCH_MY_CART"
    }),
    selectOption: function (type) {
      this.propertyVarious.forEach(item => {
        item.active = item.key === type;
        this.propertyVariousCount = type === 'different' ? 2 : 1;
      });
    },
    initFlowAfterComponentCreated: function (product) {
      this.product = product;
      this.fetchCart();
      this.loaded = !this.loaded;
    },
  },
  computed: {
    ...mapGetters({
      getCartItem: 'cart/cartItem'
    })
  },
  created() {
    api.lensmark.catalog.product.one(this.$route.params.id).then(rs => {
      this.initFlowAfterComponentCreated(rs.data)
    });
  }
}
</script>

<style lang="scss" scoped>
.catalog-add-to-cart-page {
  &_title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: #333333;
    margin-top: 40px;
  }

  &_options {
    &__type {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      margin: 20px 0;
      cursor: pointer;
      flex: 1 1;
      column-gap: 15px;

      &-item {
        display: flex;
        justify-content: flex-start;
        justify-items: flex-start;
        align-content: center;
        align-items: center;
        flex: 1;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 16px;
        color: #757C99;
        fill: #BAC7D5;

        &:nth-child(2n) {
          margin-left: 40px;
        }

        .name {
          margin-left: 5px;
          line-height: 24px;
          padding-top: 2px;
        }

        &.active {
          color: #029D95;
          fill: #029D95;
        }
      }
    }

    &__form {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      flex: 1 1;
      row-gap: 15px;
      column-gap: 15px;
    }
  }

  &_warning {
    position: relative;
    padding-left: 30px;
    margin: 20px 0;

    i {
      position: absolute;
      left: 0;
      top: 0;
      font-weight: bold;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #333333;
    }
  }
}
</style>
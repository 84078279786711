<template>
  <div>
    <el-skeleton animated>
      <template #template>
        <div style="display: grid; grid-template-columns: 1fr 1fr; grid-gap: 16px">
          <el-skeleton-item variant="rect" style="height: 124px" />
          <el-skeleton-item variant="rect" style="height: 124px" />
        </div>
      </template>
    </el-skeleton>
  </div>
</template>

<script>
export default {
  name: "SkeletonMenuGrid"
}
</script>

<style scoped>

</style>
if (process.env.NODE_ENV === 'development') {
    window.RahmetApp = {
        authorize: function () {
            setTimeout(() => window.RahmetWebApp.onAuthSuccess('trackID'), 1500)
        },
        pay() {
            setTimeout(() => window.RahmetWebApp.onPaySuccess(), 1500);
        }
    }
}

const connect = callback => {
    return new Promise((resolve, reject) => {
        try {
            callback(resolve);
        } catch (err) {
            reject(err);
        }
    });
};

export const RahmetApp = {
    fetchRemoteConfigProperty(key) {
        return connect(resolve => {
            window.RahmetApp.fetchRemoteConfigProperty(key);
            window.RahmetWebApp.didGetRemoteConfigProperty = property =>
                resolve(property);
        });
    },
    authorize() {
        return connect(resolve => {
            window.RahmetApp.authorize();
            window.RahmetWebApp.onAuthSuccess = (trackId, deviceId) => resolve(trackId, deviceId);
        });
    },
    pay(deeplink) {
        return connect(resolve => {
            window.RahmetApp.pay(deeplink);
            window.RahmetWebApp.onPaySuccess = orderId => resolve(orderId);
        });
    },
    requestGeoPermissionStatus() {
        return connect(resolve => {
            window.RahmetApp.requestGeoPermissionStatus();
            window.RahmetWebApp.onGeoPermissionStatusDefined = type => resolve(type);
        });
    },
    getGeoPermissionStatus() {
        return window.RahmetApp.getGeoPermissionStatus();
    },
    getReferralCode() {
        return connect(resolve => {
            window.RahmetApp.getReferralCode();
            window.RahmetWebApp.didGetReferralCode = referral => resolve(referral);
        });
    },
    shareReferralCode(text) {
        window.RahmetApp.shareReferralCode(text);
    },
    failedReferralCode(method) {
        window.RahmetWebApp.didFailReferralCode = method;
    },
    backToRahmetApp() {
        window.RahmetApp.backToRahmetApp();
    },
    setOnNativePayViewClosed(method) {
        window.RahmetWebApp.onNativePayViewClosed = method;
    },
    applicationBecomeActive(method) {
        window.RahmetWebApp.didBecomeActive = method;
    },
    androidKeyboardClosed(method) {
        window.RahmetWebApp.onKeyboardClosed = method;
    },
    hapticSelection() {
        if (window.RahmetApp && window.RahmetApp.hapticSelection) {
            window.RahmetApp.hapticSelection();
        }
    },
    hapticNotification(type) {
        // success, warning, error
        if (window.RahmetApp && window.RahmetApp.hapticNotification) {
            window.RahmetApp.hapticNotification(type);
        }
    },
    hapticImpact(type) {
        // light, medium, heavy
        if (window.RahmetApp && window.RahmetApp.hapticImpact) {
            window.RahmetApp.hapticImpact(type);
        }
    },
    setupWebViewApi() {
        window.RahmetWebApp = {
            onAuthSuccess() {},
            onPaySuccess() {},
            onGeoPermissionStatusDefined() {},
            onNativePayViewClosed() {},
            didBecomeActive() {},
            loadReferralSuccess() {},
            onKeyboardClosed() {},
            didFailReferralCode() {},
            didGetRemoteConfigProperty() {}
        };
    }
}
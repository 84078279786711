<template>
  <router-link to="/catalog/search" class="search-link">
    <icon-base icon-name="search" width="16" height="16" view-box="0 0 16 16">
      <icon-search />
    </icon-base>
    <span class="text">Поиск</span>
  </router-link>
</template>

<script>
import IconBase from "@/components/icons/IconBase";
import IconSearch from "@/components/icons/IconSearch";
export default {
  name: "SearchLink",
  components: {
    IconBase,
    IconSearch
  }
}
</script>
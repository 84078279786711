export function gallery(client) {
    return {
        list: async function (query) {
            return await client.get(`/gallery`, {
                params: query
            }).then(response => response.data);
        },
        one: async function (id, query) {
            return await client.get(`/gallery/${id}`, {
                params: query
            }).then(response => response.data);
        }
    }
}
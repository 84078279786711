<template>
  <div class="blog-page">
    <div class="container">
      <h4>Уважаемые клиенты!</h4>
      <p>1) По наличию товара просим обращаться к консультантам <a href="tel:+77273132320">+7 (727) 313-23-20</a>,<br>
        <a href="https://api.whatsapp.com/send?phone=77052632217">+7 705 263 22 17</a> (whatsapp).</p>
      <p>2) <strong>Доставка по Алматы:</strong></p>
      <p>Доставка день в день осуществляется при оформлении заказа с 9.00-12.00</p>
      <p>Сроки доставки: с понедельника - пятницу с 15.00 - 21.00</p>
      <p>Доставка в субботу осуществляется при оформлении заказа в понедельник - четверг с 9.00-21.00 и в пятницу до
        16.00.</p>
      <p><strong>Доставка по Казахстану:</strong></p>
      <p>К сожалению будут наблюдаться задержки по доставке. Тем самым экспресс отправки будут отправляться наземными
        видами транспорта. По дополнительным вопрос +7 (727) 313-23-20, +7 705 263 22 17 (whatsapp).</p>
      <p>3) Опция самовывоза временно не работает.</p>
      <p>Просим прощения за доставленные неудобства.</p>
      <p>Команда Lensmark!</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Post1"
}
</script>

<style lang="scss" scoped>
.blog {
  &-page {
    padding-top: 16px;
    padding-bottom: 16px;
    background-color: #F2F2F2;
  }
}
</style>
<template>
  <div class="checkout-page" v-loading="orderProcessingState">
    <el-form ref="form" label-position="top">
      <div class="checkout-user-info">
        <user-info v-if="!user.firstName || !user.phone" />
      </div>
      <div class="checkout-delivery">
        <div class="checkout-block checkout-delivery_type">
          <h3 class="title">Способ доставки:</h3>
          <div class="body">
            <el-radio-group class="checkout-delivery_type__items"
                            fill="#029D95"
                            text-color="#029D95"
                            v-on:change="switchDeliveryType"
                            v-model="dataForOrder.delivery_type_id">
              <el-radio class="checkout-delivery_type__items-item"
                        v-if="selectedAddress && (selectedAddress.city.id !== 1)" :label="3">Доставка
              </el-radio>
              <el-radio class="checkout-delivery_type__items-item" v-else :label="1">Доставка</el-radio>
              <el-radio class="checkout-delivery_type__items-item" :label="2">Самовывоз</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="checkout-block" v-if="dataForOrder.delivery_type_id === 2" style="background-color: #FCF6ED;">
          <h3 class="title">Адрес самовывоза:</h3>
          <div class="body">
            <div class="header">
              <div class="address">г. Алмата, ул. Байтурсынова, д. 79</div>
            </div>
          </div>
        </div>
        <choose-address class="checkout-block"
                        v-else
                        v-loading="!addressFetchingState"
                        :class="{error: dataForOrderErrors.includes('address_id')}"
                        v-on:address-id="changeAddress($event)"
                        :model="selectedAddress"
                        :items="addresses"
                        :error="dataForOrderErrors.includes('address_id')"/>
        <calc-delivery-amount v-loading="!deliveryCalcState"
                              v-if="selectedAddress || dataForOrder.delivery_type_id === 2"
                              :state="deliveryCalcState"
                              :schedule="deliverySchedule"
                              :amount-before-free-shipping="amountBeforeFreeShipping"
                              :delivery-cost="dataForOrder.delivery_cost"
                              :cart-total-amount="cart.totalAmount"/>
      </div>
      <div class="checkout-info">
        <el-input v-model="dataForOrder.comment" type="textarea" placeholder="Коментарий к заказу"/>
      </div>
      <el-button class="btn btn-default btn-success"
                 v-on:click="processing"
                 style="width: 100%;">
        Оплатить
      </el-button>
    </el-form>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapState} from "vuex";
import {RahmetApp} from "@/shared/RahmetApp";
import {NotifyCenter} from "@/shared/services/notify";
import {api} from "@/shared/services/api";
import ChooseAddress from "@/views/checkout/components/ChooseAddress";
import CalcDeliveryAmount from "@/views/checkout/components/CalcDeliveryAmount";
import UserInfo from "@/views/checkout/components/UserInfo";

export default {
  name: "CheckoutIndex",
  components: {UserInfo, CalcDeliveryAmount, ChooseAddress},
  data: () => {
    return {
      addressFetchingState: false,
      orderProcessingState: false,
      deliveryCalcState: false,
      amountBeforeFreeShipping: 0,
      deliverySchedule: {},
      dataForOrder: {
        state_id: 6,
        address_id: null,
        payment_type_id: 7,
        delivery_type_id: 1,
        delivery_cost: 0,
        comment: ''
      },
      dataForOrderErrors: []
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user,
      addresses: state => state.address.addresses,
      cart: state => state.cart.cart
    }),
    ...mapGetters({
      selectedAddress: 'address/SELECTED_ADDRESS'
    })
  },
  methods: {
    ...mapActions({
      fetchAddresses: 'address/FETCH_ADDRESS_LIST',
      fetchCart: 'cart/FETCH_MY_CART',
      setPageMeta: 'SET_PAGE_META'
    }),
    switchDeliveryType: function () {
      this.deliveryCalcState = false;
      if (this.dataForOrder.delivery_type_id === 2) {
        NotifyCenter.push('warning', 'Внимание самовывоз только в г. Алматы!', true);
        this.dataForOrder.address_id = 0;
        this.dataForOrder.delivery_cost = 0;
      } else {
        if (this.selectedAddress) {
          this.dataForOrder.address_id = (this.selectedAddress) ? this.selectedAddress.id : null;
        }
      }
      this.calcDelivery(
          this.selectedAddress.fullAddress, this.dataForOrder.delivery_type_id, this.cart.totalAmount
      );
    },
    changeAddress: function (address) {
      this.deliveryCalcState = false;
      this.dataForOrder.address_id = address.id;
      this.validateDataForOrder();
      let fullAddress = `${address.city.name}, ${address.street}, ${address.build}`
      if (address.city.id === 1) {
        this.dataForOrder.delivery_type_id = 1;
      } else {
        this.dataForOrder.delivery_type_id = 3;
      }
      this.calcDelivery(fullAddress, this.dataForOrder.delivery_type_id, this.cart.totalAmount);
    },
    validateDataForOrder: function () {
      this.dataForOrderErrors = [];
      Object.entries(this.dataForOrder).forEach((property) => {
        if ([null, undefined].includes(property[1])) {
          this.dataForOrderErrors.push(property[0]);
        }
      });
      return this.dataForOrderErrors;
    },
    calcDelivery: function (address, delivery_type_id, totalAmount) {
      api.lensmark.delivery.calc(
          delivery_type_id,
          address,
          totalAmount
      ).then(rs => {
        this.dataForOrder.delivery_cost = rs.data.meta.size === 1 ? rs.data.data.cost : 0;
        this.deliverySchedule = rs.data.data.schedule;
        this.amountBeforeFreeShipping = rs.data.data.amountBeforeFreeShipping;
        this.deliveryCalcState = true;
      });
    },
    processing: function () {
      this.validateDataForOrder();

      if (this.dataForOrderErrors.length > 0 || (this.user.phone.length <= 0 || this.user.firstName <= 0)) {
        return false;
      }

      RahmetApp.hapticImpact('medium');
      this.orderProcessingState = true;

      api.lensmark.order.create(
          this.dataForOrder.state_id,
          this.dataForOrder.address_id,
          this.dataForOrder.payment_type_id,
          this.dataForOrder.delivery_type_id,
          this.dataForOrder.delivery_cost
      ).then(rs => {
        let orderId = rs.data.data.id;
        this.$router.push({
          name: 'CabinetOrderDetail',
          params: {id: orderId}
        })
        this.fetchCart();
        RahmetApp.pay(rs.data.data.payment.response.pay_uri).then(() => {
          this.$router.push({
            name: 'ThankYouPage',
            params: {
              id: this.$route.params.id
            }
          });
        }).catch(err => {
          NotifyCenter.push('error', err.message, true);
        });
      }).catch(err => {
        RahmetApp.hapticNotification('medium');
        NotifyCenter.push('error', err.message, true);
      }).finally(() => {
        this.orderProcessingState = false;
      });
    },
  },
  beforeMount: async function () {
    await this.fetchCart();
    await this.fetchAddresses().then(() => {
      if (this.selectedAddress) {
        this.dataForOrder.address_id = this.selectedAddress.id;
        if (this.selectedAddress.city.id === 1) {
          this.dataForOrder.delivery_type_id = 1;
        } else {
          this.dataForOrder.delivery_type_id = 3;
        }
        this.calcDelivery(this.selectedAddress.fullAddress, this.dataForOrder.delivery_type_id, this.cart.totalAmount);
      }
      this.addressFetchingState = true;
    });
  }
}
</script>

<style lang="scss">
.checkout {
  &-page {
    padding: 16px;

    &_step {
      padding-left: 16px;
      padding-right: 16px;

      &__title {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 6px;
      }

      &__inputs {
        .input-item {
          margin-bottom: 16px;
        }
      }
    }
  }

  &-block {
    margin-bottom: 16px;
    padding: 21px 12px;
    background: #F2F2F2;
    border-radius: 4px;

    &.error {
      background: #fde2e2;
    }

    .title {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
    }

    .body {
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #989898;

        strong {
          font-weight: 500;
          color: #000000;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-delivery {
    &_type {
      &__items {
        &-item {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #000000;
        }
      }
    }
  }

  &-info {
    margin-bottom: 16px;
  }
}
</style>

<template>
  <div class="profile-page">
    <el-form class="profile-page_form" label-position="top">
      <el-form-item label="Имя" :error="errors.first_name">
        <el-input v-model="user.firstName" type="text" placeholder="Введите имя"/>
      </el-form-item>
      <el-form-item label="Фамилия" :error="errors.last_name">
        <el-input v-model="user.lastName" type="text" placeholder="Введите фамилия"/>
      </el-form-item>
      <el-form-item label="Телефон"  :error="errors.phone">
        <el-input v-model="user.phone" v-maska="{mask: '+7(###)###-##-##', tokens: { '#': { pattern: /[0-9]/ }}}"
                  type="tel" placeholder="Телефон"/>
      </el-form-item>
      <el-form-item label="Email" :error="errors.email">
        <el-input v-model="user.email" type="email" placeholder="Email"/>
      </el-form-item>
      <el-button class="btn-default btn-success" v-on:click="save">Сохранить</el-button>
    </el-form>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {api} from "@/shared/services/api";
import {maska} from "maska";

export default {
  name: "ProfileIndex",
  directives: {maska},
  data() {
    return {
      errors: {
        first_name: "",
        last_name: "",
        phone: "",
        email: ""
      }
    }
  },
  methods: {
    ...mapActions({
      fetchProfile: "user/FETCH_PROFILE"
    }),
    save: function () {
      api.lensmark.user.updateInfo({
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        email: this.user.email,
        phone: this.user.phone.replace(/\D/g, "")
      }).then(() => {
        this.$router.go(-1);
      }).catch(err => {
        this.errors = err.response.data.errors;
      });
    }
  },
  computed: {
    ...mapState({
      user: state => state.user.user
    })
  },
  beforeMount() {
    this.fetchProfile();
  }
}
</script>

<style lang="scss" scoped>
.profile-page {
  &_form {
    padding: 24px 16px;
  }
}
</style>
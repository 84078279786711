<template>
  <section class="catalog-filter" v-if="meta.size > 0">
    <button class="catalog-filter_btn" v-on:click="toggle">
      {{ hide ? `Фильтр` : 'Свернуть' }}
      <i :class="hide ? `el-icon-arrow-down` : `el-icon-arrow-up`"></i>
    </button>
    <transition name="linear">
      <div class="catalog-filter_els" :class="hide ? `hide` : ``" v-if="loaded">
        <catalog-filter-on-page-element v-for="(el, key) in items"
                                        :update-filter="updateFilter"
                                        :key="key"
                                        :type="key"
                                        :title="el.title"
                                        :items="el.items"/>
      </div>
      <div v-else style="margin-top: 25px">
        <skeleton-filter-item/>
      </div>
    </transition>
  </section>
</template>

<script>
import {mapState} from "vuex";
import {api} from "@/shared/services/api";
import CatalogFilterOnPageElement from "@/components/catalog/filterOnPage/Element";
import SkeletonFilterItem from "@/components/skeleton/FilterItem";

export default {
  name: "CatalogFilterOnPageIndex",
  components: {SkeletonFilterItem, CatalogFilterOnPageElement},
  props: ['fetchProducts'],
  data() {
    return {
      hide: true,
      loaded: false,
      meta: {},
      items: []
    }
  },
  computed: {
    ...mapState({
      filter: state => state.catalog.filter.filter
    })
  },
  created() {
    this.fetchFilter(this.$route.params.id)
  },
  mounted() {
    this.$emit('fetchData')
  },
  methods: {
    toggle: function () {
      this.hide = !this.hide;
    },
    fetchFilter: function (id) {
      api.lensmark.catalog.filter.one(id).then(response => {
        this.meta = response.meta
        this.items = response.data
        this.loaded = !this.loaded
      })
    },
    updateFilter: function () {
      this.fetchProducts()
    }
  }
}
</script>

<style scoped lang="scss">
.catalog-filter {
  padding: 16px 0 4px;

  &_btn {
    display: flex;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    align-items: center;
    color: #303030;
    border: none;
    background-color: #F2F2F2;
    padding: 7px 12px;
    border-radius: 4px;
    margin-bottom: 10px;

    > i {
      display: block;
      margin-left: 8.5px;
      color: #303030;
      font-weight: bold;
    }
  }

  &_els {
    display: block;
    overflow: hidden;
    max-height: 760px;
    transition: .3s linear;

    &.hide {
      margin: 0;
      max-height: 0;
    }
  }
}
</style>
<template>
  <div class="error-page">
    <div class="error-page_img">
      <svg width="65" height="90" viewBox="0 0 65 90" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4">
          <mask id="path-1-inside-1" fill="white">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M32.5 0C14.5507 0 0 14.5507 0 32.5V71V83V83.5C0 87.0899 2.91015 90 6.5 90C10.0899 90 13 87.0899 13 83.5C13 87.0899 15.9101 90 19.5 90C23.0899 90 26 87.0899 26 83.5C26 87.0899 28.9101 90 32.5 90C36.0899 90 39 87.0899 39 83.5C39 87.0899 41.9101 90 45.5 90C49.0899 90 52 87.0899 52 83.5C52 87.0899 54.9101 90 58.5 90C62.0899 90 65 87.0899 65 83.5V83V71V32.5C65 14.5507 50.4493 0 32.5 0Z"/>
          </mask>
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M32.5 0C14.5507 0 0 14.5507 0 32.5V71V83V83.5C0 87.0899 2.91015 90 6.5 90C10.0899 90 13 87.0899 13 83.5C13 87.0899 15.9101 90 19.5 90C23.0899 90 26 87.0899 26 83.5C26 87.0899 28.9101 90 32.5 90C36.0899 90 39 87.0899 39 83.5C39 87.0899 41.9101 90 45.5 90C49.0899 90 52 87.0899 52 83.5C52 87.0899 54.9101 90 58.5 90C62.0899 90 65 87.0899 65 83.5V83V71V32.5C65 14.5507 50.4493 0 32.5 0Z"
                fill="#F3F3F3"/>
          <path
              d="M1 32.5C1 15.103 15.103 1 32.5 1V-1C13.9985 -1 -1 13.9985 -1 32.5H1ZM1 71V32.5H-1V71H1ZM1 83V71H-1V83H1ZM1 83.5V83H-1V83.5H1ZM6.5 89C3.46243 89 1 86.5376 1 83.5H-1C-1 87.6421 2.35786 91 6.5 91V89ZM12 83.5C12 86.5376 9.53757 89 6.5 89V91C10.6421 91 14 87.6421 14 83.5H12ZM19.5 89C16.4624 89 14 86.5376 14 83.5H12C12 87.6421 15.3579 91 19.5 91V89ZM25 83.5C25 86.5376 22.5376 89 19.5 89V91C23.6421 91 27 87.6421 27 83.5H25ZM32.5 89C29.4624 89 27 86.5376 27 83.5H25C25 87.6421 28.3579 91 32.5 91V89ZM38 83.5C38 86.5376 35.5376 89 32.5 89V91C36.6421 91 40 87.6421 40 83.5H38ZM45.5 89C42.4624 89 40 86.5376 40 83.5H38C38 87.6421 41.3579 91 45.5 91V89ZM51 83.5C51 86.5376 48.5376 89 45.5 89V91C49.6421 91 53 87.6421 53 83.5H51ZM58.5 89C55.4624 89 53 86.5376 53 83.5H51C51 87.6421 54.3579 91 58.5 91V89ZM64 83.5C64 86.5376 61.5376 89 58.5 89V91C62.6421 91 66 87.6421 66 83.5H64ZM64 83V83.5H66V83H64ZM64 71V83H66V71H64ZM64 32.5V71H66V32.5H64ZM32.5 1C49.897 1 64 15.103 64 32.5H66C66 13.9985 51.0015 -1 32.5 -1V1Z"
              fill="black" mask="url(#path-1-inside-1)"/>
        </g>
        <circle cx="24.5775" cy="42.5775" r="2.57752" fill="#878787"/>
        <circle cx="40.7791" cy="42.5775" r="2.57752" fill="#878787"/>
        <rect x="31" y="48" width="4" height="2" rx="1" fill="#878787"/>
      </svg>
    </div>
    <h2 class="error-page_title">{{ title }}</h2>
    <h3 class="error-page_subtitle">{{ subTitle }}</h3>
  </div>
</template>

<script>
export default {
  name: "EmptyResult",
  props: ['title', 'subTitle']
}
</script>

<style lang="scss" scoped>
.error {
  &-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 28px;

    &_img {
    }

    &_title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }

    &_subtitle {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
</style>
<template>
  <section class="banner-detail-page">
    <iframe :src="model.link" class="responsive-iframe" frameborder="0"></iframe>
  </section>
</template>

<script>
import {api} from "@/shared/services/api";

export default {
  name: "BannerDetailPage",
  data() {
    return {
      model: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      api.lensmark.gallery.one(this.$route.params.id).then(response => {
        this.model = response.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
<template>
  <g>
    <path
        d="M14.4256 24.8496C13.0994 24.8496 12.0244 25.9247 12.0244 27.2508C12.0244 28.5769 13.0995 29.6519 14.4256 29.6519C15.7517 29.6519 16.8267 28.5769 16.8267 27.2508C16.8268 25.9247 15.7517 24.8496 14.4256 24.8496ZM14.4256 28.5848C13.6888 28.5848 13.0916 27.9875 13.0916 27.2508C13.0916 26.5141 13.6888 25.9169 14.4256 25.9169C15.1623 25.9169 15.7595 26.5141 15.7595 27.2508C15.7596 27.9875 15.1623 28.5848 14.4256 28.5848Z"
        fill="none" stroke="currentColor"/>
    <path
        d="M24.5637 24.8496C23.2376 24.8496 22.1626 25.9247 22.1626 27.2508C22.1626 28.5769 23.2377 29.6519 24.5637 29.6519C25.8898 29.6519 26.9649 28.5769 26.9649 27.2508C26.9649 25.9247 25.8899 24.8496 24.5637 24.8496ZM24.5637 28.5848C23.827 28.5848 23.2298 27.9875 23.2298 27.2508C23.2298 26.5141 23.827 25.9169 24.5637 25.9169C25.3005 25.9169 25.8977 26.5141 25.8977 27.2508C25.8978 27.9875 25.3005 28.5848 24.5637 28.5848Z"
        fill="none" stroke="currentColor"/>
    <path
        d="M29.98 10.8426C29.8689 10.7213 29.7169 10.6453 29.5532 10.6292L12.1847 10.3891L11.7045 8.92167C11.3662 7.94073 10.4476 7.2785 9.41 7.26752H7.62246C7.32776 7.26752 7.08887 7.50641 7.08887 7.80111C7.08887 8.09582 7.32776 8.33471 7.62246 8.33471H9.41C9.99017 8.34752 10.501 8.72005 10.6906 9.26851L14.079 19.4868L13.8122 20.1004C13.5146 20.8678 13.604 21.7314 14.0523 22.4216C14.4963 23.0994 15.2433 23.5178 16.0533 23.5421H26.4317C26.7264 23.5421 26.9653 23.3032 26.9653 23.0085C26.9653 22.7138 26.7264 22.4749 26.4317 22.4749H16.0533C15.5959 22.4635 15.1748 22.2229 14.9327 21.8346C14.6933 21.4511 14.6441 20.9786 14.7993 20.554L15.0128 20.0737L26.2449 18.8998C27.4787 18.7639 28.4936 17.8666 28.7795 16.6587L30.0602 11.2961C30.1179 11.1417 30.0872 10.968 29.98 10.8426ZM27.739 16.4186C27.5658 17.1962 26.9056 17.7697 26.1115 17.8327L15.0128 18.9799L12.5315 11.4563L28.8862 11.6964L27.739 16.4186Z"
        fill="none" stroke="currentColor"/>
  </g>
</template>
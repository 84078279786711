<template>
  <header>
    <main-top-bar v-if="$route.name === 'Home'"/>
    <router-top-bar v-else-if="topRouterBarShowPages.includes($route.name)"/>
    <div v-else class="title">{{ $route.meta.title }}</div>
  </header>
</template>

<script>
import {mapActions} from "vuex";
import MainTopBar from "@/components/navigation/MainTopBar";
import RouterTopBar from "@/components/navigation/RouterTopBar";

export default {
  name: "HeaderBar",
  data() {
    return {
      topRouterBarShowPages: [
        'CatalogIndex',
        'CatalogFilter',
        'CatalogDetail',
        'CatalogAddToCart',
        'CheckoutIndex',
        'CatalogSearch',
        'CabinetAddress',
        'CabinetAddressCreate',
        'CabinetAddressUpdate',
        'CabinetSupportChat',
        'CabinetSupportFaq',
        'CabinetSupportIndex',
        'CabinetOrderIndex',
        'CabinetOrderDetail',
        'BannerDetail',
        'BlogPage',
        'Error',
        'ProfileIndex'
      ]
    }
  },
  components: {
    RouterTopBar,
    MainTopBar
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    ...mapActions({
      'toggleCatalogFilter': "catalog/toggleCatalogFilter"
    })
  }
}
</script>

<style lang="scss" scoped>
header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  background: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 56px;

  .title {
    padding-top: 16px;
    padding-left: 16px;
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 16px;
  }
}
</style>
<template>
    <g>
      <path d="M33.6752 14.9333C24.9115 14.9333 16.7043 14.2842 10.491 13.1713C1.12455 11.4557 0.0117188 9.27636 0.0117188 7.79257C0.0117188 6.30878 1.07818 4.12948 10.491 2.41385C16.7043 1.25464 24.9115 0.651855 33.6752 0.651855C42.4388 0.651855 50.646 1.30101 56.8593 2.41385C66.2258 4.12948 67.3386 6.30878 67.3386 7.79257C67.3386 9.27636 66.2721 11.4557 56.8593 13.1713C50.646 14.3305 42.4388 14.9333 33.6752 14.9333ZM33.6752 2.97027C25.2361 2.97027 17.2607 3.57305 11.2328 4.63952C2.97927 6.12331 2.37649 7.79257 2.37649 7.79257C2.37649 7.79257 2.97927 9.46182 11.2328 10.9456C17.2607 12.0121 25.2361 12.6149 33.6752 12.6149C42.1142 12.6149 50.0896 12.0121 56.1175 10.9456C64.371 9.46182 64.9738 7.79257 64.9738 7.79257C64.9738 7.79257 64.371 6.12331 56.1175 4.63952C50.1359 3.57305 42.1606 2.97027 33.6752 2.97027Z" fill="#07A9A1"/>
      <path d="M34.8345 29.493C34.4635 29.493 34.0462 29.493 33.6289 29.493C17.7245 29.1685 9.42461 23.7897 5.29782 19.3384C0.753722 14.4233 0.0581851 9.55465 0.0118167 9.36917C-0.08092 8.72002 0.382741 8.1636 1.0319 8.07087C1.68106 7.97813 2.2375 8.4418 2.33024 9.04459C2.33024 9.09096 2.97936 13.496 7.10614 17.8546C12.624 23.697 21.8049 26.8964 33.6752 27.1283C45.5919 27.3601 54.7729 24.0216 60.2444 17.4836C64.3248 12.615 64.9739 7.65355 64.9739 7.60718C65.0666 6.95803 65.6231 6.49434 66.2722 6.58707C66.9214 6.67981 67.3851 7.23622 67.2923 7.88538C67.246 8.11722 66.5968 13.5887 62.0527 18.9674C58.0186 23.8361 50.0433 29.493 34.8345 29.493Z" fill="#07A9A1"/>
      <path d="M55.932 48.0403C47.1684 48.0403 38.9612 47.3912 32.7478 46.2783C23.3814 44.5627 22.2686 42.3834 22.2686 40.8996C22.2686 39.4158 23.335 37.2365 32.7478 35.5208C38.9612 34.3616 47.1684 33.7589 55.932 33.7589C64.6956 33.7589 72.9028 34.408 79.1162 35.5208C88.4826 37.2365 89.5954 39.4158 89.5954 40.8996C89.5954 42.3834 88.529 44.5627 79.1162 46.2783C72.9028 47.3912 64.6956 48.0403 55.932 48.0403ZM55.932 36.0773C47.4929 36.0773 39.5176 36.6801 33.4897 37.7465C25.2361 39.2303 24.6333 40.8996 24.6333 40.8996C24.6333 40.8996 25.2361 42.5688 33.4897 44.0526C39.5176 45.1191 47.4929 45.7219 55.932 45.7219C64.371 45.7219 72.3464 45.1191 78.3743 44.0526C86.6279 42.5688 87.2306 40.8996 87.2306 40.8996C87.2306 40.8996 86.6279 39.2303 78.3743 37.7465C72.3928 36.6337 64.4174 36.0773 55.932 36.0773Z" fill="#07A9A1"/>
      <path d="M57.0913 62.5999C56.7203 62.5999 56.303 62.5999 55.8857 62.5999C39.9814 62.2754 31.6814 56.8966 27.5547 52.4452C23.0106 47.5302 22.315 42.6615 22.2687 42.476C22.1759 41.8269 22.6396 41.2705 23.2887 41.1777C23.9379 41.085 24.4943 41.5487 24.5871 42.1515C24.5871 42.1979 25.2362 46.6029 29.363 50.9615C34.8808 56.8039 44.0618 60.0033 55.9321 60.2352C67.8024 60.467 77.0297 57.1285 82.5012 50.5905C86.5816 45.7218 87.2307 40.7604 87.2307 40.7141C87.3235 40.0649 87.8799 39.6012 88.5291 39.6939C89.1782 39.7867 89.6419 40.3431 89.5492 40.9923C89.5028 41.2241 88.8536 46.6956 84.3095 52.0743C80.2755 56.943 72.3001 62.5999 57.0913 62.5999Z" fill="#07A9A1"/>
      <path d="M57.2652 35.7461C52.512 35.7461 48.0605 35.394 44.6904 34.7904C39.6102 33.8599 39.0066 32.6778 39.0066 31.873C39.0066 31.0683 39.585 29.8862 44.6904 28.9557C48.0605 28.3269 52.512 28 57.2652 28C62.0185 28 66.47 28.3521 69.8401 28.9557C74.9203 29.8862 75.5239 31.0683 75.5239 31.873C75.5239 32.6778 74.9455 33.8599 69.8401 34.7904C66.47 35.4192 62.0185 35.7461 57.2652 35.7461ZM57.2652 29.2575C52.688 29.2575 48.3623 29.5844 45.0928 30.1629C40.6162 30.9677 40.2892 31.873 40.2892 31.873C40.2892 31.873 40.6162 32.7784 45.0928 33.5832C48.3623 34.1617 52.688 34.4886 57.2652 34.4886C61.8425 34.4886 66.1682 34.1617 69.4377 33.5832C73.9143 32.7784 74.2413 31.873 74.2413 31.873C74.2413 31.873 73.9143 30.9677 69.4377 30.1629C66.1934 29.5844 61.8676 29.2575 57.2652 29.2575Z" fill="white"/>
      <path d="M57.8938 43.6431C57.6926 43.6431 57.4663 43.6431 57.2399 43.6431C48.6136 43.4671 44.1118 40.5497 41.8735 38.1353C39.4088 35.4695 39.0316 32.8288 39.0064 32.7282C38.9561 32.3761 39.2076 32.0743 39.5597 32.024C39.9118 31.9737 40.2136 32.2252 40.2639 32.5521C40.2639 32.5773 40.616 34.9665 42.8543 37.3305C45.8471 40.4994 50.8267 42.2347 57.2651 42.3605C63.7285 42.4862 68.7082 40.6755 71.6758 37.1294C73.889 34.4886 74.2411 31.7976 74.2411 31.7725C74.2914 31.4204 74.5932 31.1689 74.9453 31.2192C75.2974 31.2695 75.5489 31.5713 75.4986 31.9234C75.4734 32.0491 75.1213 35.0168 72.6567 37.9341C70.4686 40.5749 66.1429 43.6431 57.8938 43.6431Z" fill="white"/>
      <path d="M69.337 53.7029C64.5837 53.7029 60.1322 53.3509 56.7622 52.7473C51.682 51.8167 51.0784 50.6347 51.0784 49.8299C51.0784 49.0251 51.6568 47.8431 56.7622 46.9125C60.1322 46.2838 64.5837 45.9568 69.337 45.9568C74.0903 45.9568 78.5418 46.3089 81.9119 46.9125C86.9921 47.8431 87.5957 49.0251 87.5957 49.8299C87.5957 50.6347 87.0172 51.8167 81.9119 52.7473C78.5418 53.3509 74.0903 53.7029 69.337 53.7029ZM69.337 47.2143C64.7598 47.2143 60.434 47.5413 57.1646 48.1197C52.6879 48.9245 52.361 49.8299 52.361 49.8299C52.361 49.8299 52.6879 50.7353 57.1646 51.5401C60.434 52.1185 64.7598 52.4455 69.337 52.4455C73.9143 52.4455 78.24 52.1185 81.5095 51.5401C85.9861 50.7353 86.3131 49.8299 86.3131 49.8299C86.3131 49.8299 85.9861 48.9245 81.5095 48.1197C78.2652 47.5161 73.9394 47.2143 69.337 47.2143Z" fill="white"/>
      <path d="M69.9656 61.5999C69.7644 61.5999 69.538 61.5999 69.3117 61.5999C60.6854 61.4239 56.1836 58.5065 53.9453 56.0921C51.4806 53.4263 51.1033 50.7855 51.0782 50.6849C51.0279 50.3328 51.2794 50.0311 51.6315 49.9808C51.9836 49.9305 52.2854 50.182 52.3357 50.5089C52.3357 50.5341 52.6877 52.9233 54.9261 55.2873C57.9189 58.4562 62.8985 60.1915 69.3368 60.3173C75.7752 60.443 80.78 58.6322 83.7476 55.0861C85.9608 52.4454 86.3129 49.7544 86.3129 49.7293C86.3632 49.3772 86.665 49.1257 87.0171 49.176C87.3692 49.2263 87.6207 49.5281 87.5704 49.8802C87.5452 50.0059 87.1931 52.9736 84.7284 55.8909C82.5404 58.5316 78.2147 61.5999 69.9656 61.5999Z" fill="white"/>
    </g>
</template>
<template>
  <div class="description" v-html="description"></div>
</template>

<script>
export default {
  name: "Description",
  props: ['description']
}
</script>

<style scoped>

</style>
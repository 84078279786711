import {api} from "@/shared/services/api";

const Cart = {
    namespaced: true,
    state: {
        cart: {
            totalAmount: 0,
            totalQuantity: 0,
            items: []
        }
    },
    mutations: {
        setCart: function (state, payload) {
            state.cart.totalAmount = payload.totalAmount;
            state.cart.totalQuantity = payload.totalQuantity;
            state.cart.items = payload.items;
        }
    },
    actions: {
        FETCH_MY_CART: async function ({commit}) {
            await api.lensmark.cart.get().then(rs => {
                commit('setCart', rs.data);
            })
        }
    },
    getters: {
        cartItem: state => id => {
            return state.cart.items.filter(item => {
                return item.item_id === id;
            });
        }
    }
}

export default Cart
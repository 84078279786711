<template>
  <section class="error-page">
    <div class="error-page_image">
      <svg width="187" height="157" viewBox="0 0 187 157" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M76.6257 104.874L83.2071 98.2929C83.3946 98.1054 83.649 98 83.9142 98H117.032C117.931 98 118.373 99.0925 117.728 99.7179L103.808 113.217C103.514 113.502 103.716 114 104.125 114C111.763 114 114.266 124.249 107.488 127.77L75.2479 144.515C74.2333 145.042 73.2715 143.729 74.0799 142.92L87.0234 129.977C87.5683 129.432 87.1824 128.5 86.4118 128.5C74.082 128.5 67.9072 113.593 76.6257 104.874Z"
            fill="#FFEAAA" stroke="#F2C94C"/>
        <mask id="path-2-inside-1" fill="white">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M30 109H10.5C4.70101 109 0 104.299 0 98.5C0 92.701 4.70101 88 10.5 88H14.0246C14.5456 77.4189 23.2896 69 34 69C37.043 69 39.9272 69.6796 42.5093 70.8953C40.879 65.7207 40 60.213 40 54.5C40 24.4005 64.4005 0 94.5 0C120.103 0 141.582 17.6547 147.429 41.4542C149.241 41.1554 151.103 41 153 41C171.778 41 187 56.2223 187 75C187 93.7777 171.778 109 153 109H94.5H34H30.5H30Z"/>
        </mask>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M30 109H10.5C4.70101 109 0 104.299 0 98.5C0 92.701 4.70101 88 10.5 88H14.0246C14.5456 77.4189 23.2896 69 34 69C37.043 69 39.9272 69.6796 42.5093 70.8953C40.879 65.7207 40 60.213 40 54.5C40 24.4005 64.4005 0 94.5 0C120.103 0 141.582 17.6547 147.429 41.4542C149.241 41.1554 151.103 41 153 41C171.778 41 187 56.2223 187 75C187 93.7777 171.778 109 153 109H94.5H34H30.5H30Z"
              fill="#FAFAFA"/>
        <path
            d="M14.0246 88V89H14.9765L15.0234 88.0492L14.0246 88ZM42.5093 70.8953L42.0833 71.8L44.1492 72.7727L43.463 70.5948L42.5093 70.8953ZM147.429 41.4542L146.458 41.6928L146.678 42.5914L147.591 42.4409L147.429 41.4542ZM30 108H10.5V110H30V108ZM10.5 108C5.25329 108 1 103.747 1 98.5H-1C-1 104.851 4.14873 110 10.5 110V108ZM1 98.5C1 93.2533 5.25329 89 10.5 89V87C4.14873 87 -1 92.1487 -1 98.5H1ZM10.5 89H14.0246V87H10.5V89ZM15.0234 88.0492C15.5183 77.9979 23.8254 70 34 70V68C22.7538 68 13.5729 76.8398 13.0258 87.9508L15.0234 88.0492ZM34 70C36.8927 70 39.6317 70.6457 42.0833 71.8L42.9352 69.9905C40.2227 68.7134 37.1933 68 34 68V70ZM43.463 70.5948C41.8631 65.5163 41 60.1099 41 54.5H39C39 60.3161 39.895 65.9251 41.5555 71.1957L43.463 70.5948ZM41 54.5C41 24.9528 64.9528 1 94.5 1V-1C63.8482 -1 39 23.8482 39 54.5H41ZM94.5 1C119.632 1 140.719 18.3298 146.458 41.6928L148.4 41.2157C142.446 16.9795 120.574 -1 94.5 -1V1ZM147.591 42.4409C149.351 42.1509 151.157 42 153 42V40C151.048 40 149.132 40.1599 147.266 40.4676L147.591 42.4409ZM153 42C171.225 42 186 56.7746 186 75H188C188 55.67 172.33 40 153 40V42ZM186 75C186 93.2254 171.225 108 153 108V110C172.33 110 188 94.33 188 75H186ZM153 108H94.5V110H153V108ZM94.5 108H34V110H94.5V108ZM34 108H30.5V110H34V108ZM30.5 108H30V110H30.5V108Z"
            fill="#C4C4C4" mask="url(#path-2-inside-1)"/>
        <circle cx="84.5775" cy="70.5775" r="2.57752" fill="#878787"/>
        <circle cx="100.779" cy="70.5775" r="2.57752" fill="#878787"/>
        <path d="M96 79C96 76.7909 94.2091 75 92 75C89.7909 75 88 76.7909 88 79" stroke="#878787" stroke-width="2"
              stroke-linecap="round" stroke-linejoin="round"/>
        <ellipse opacity="0.3" cx="93" cy="154" rx="26" ry="3" fill="#C4C4C4"/>
      </svg>
    </div>
    <div class="error-page_title">
      Что-то не так...
    </div>
    <div class="error-page_message">
      Проверьте настройки сети<br>или попробуйте обновить экран
    </div>
    <button class="error-page_action">Обновить</button>
  </section>
</template>

<script>
export default {
  name: "Error"
}
</script>

<style lang="scss" scoped>
.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;

  &_image {
    text-align: center;
    margin-top: 186px;
    margin-bottom: 18px;
  }

  &_title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #2F2F2F;
    text-align: center;
    margin-bottom: 10px;
  }

  &_message {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #878787;
    margin-bottom: 40px;
  }

  &_action {
    display: flex;
    justify-items: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: #029D95;
    border-radius: 6px;
    width: 210px;
    height: 44px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    letter-spacing: -0.24px;
    color: #FFFFFF;
    border: none;
  }
}
</style>
<template>
  <el-skeleton animated>
    <template #template>
      <div>
        <el-skeleton-item variant="h1" style="width: 100px" />
      </div>
      <div>
        <el-skeleton-item variant="button" style="width: 130px; margin-right: 8px;" />
        <el-skeleton-item variant="button" style="width: 130px" />
      </div>
    </template>
  </el-skeleton>
</template>

<script>
export default {
  name: "SkeletonFilterItem"
}
</script>

<style scoped>

</style>
<template>
  <div class="address">
    <el-autocomplete
        v-model="value"
        :fetch-suggestions="searchAddress"
        v-on:focusout="focusOut"
        v-on:select="selectAddress"
        placeholder="Введите адрес"
        :disabled="disabled"
        :class="{error: (errorStreet || errorBuild) && !focusState}"
        v-on:focus="focus"
        class="address-select"/>
    <span v-if="(errorStreet || errorBuild) && !focusState" class="el-form-item__error">Укажите (улицу, номер дома)</span>
  </div>
</template>

<script>
import {api} from "@/shared/services/api";

export default {
  name: "InputAddress",
  props: {
    disabled: Boolean,
    propCity: String,
    errorStreet: Boolean,
    errorBuild: Boolean,
    propAddressFullAddress: String,
    propAddressStreet: String,
    propAddressBuild: String,
    propAddressCoordinate: Array
  },
  data() {
    return {
      focusState: false,
      loading: false,
      value: this.propAddressFullAddress ?? '',
      city: this.propCity,
      address: {
        fullAddress: this.propAddressFullAddress,
        street: this.propAddressStreet,
        build: this.propAddressBuild,
        coordinate: this.propAddressCoordinate
      },
      list: []
    }
  },
  methods: {
    searchAddress: function (query, cb) {
      let result = [];

      if (query !== '') {
        api.lensmark.geo.search({
          address: `Казахстан, г. ${this.propCity}, ${query}`
        }).then(rs => {
          result = rs.data.data.map((item) => {
            return {value: item.address};
          });

          this.list = rs.data.data.map((item) => {
            return {
              fullAddress: item.address,
              street: item.street,
              build: item.build,
              coordinate: item.coordinate
            }
          });

          cb(result);
        });
      }
    },
    selectAddress: function (address) {
      let selectedAddress = this.list.find(item => {
        return item.value === address.fullAddress;
      });

      if (!selectedAddress) {
        return false;
      }

      this.address = {
        fullAddress: selectedAddress.fullAddress,
        street: selectedAddress.street,
        build: selectedAddress.build,
        coordinate: selectedAddress.coordinate
      };

      this.$emit('selectAddress', this.address);
    },
    focusOut: function () {
      if (!this.address.street) {
        this.value = '';
      }
      if (!this.address.build) {
        this.value = '';
      }
      this.focusState = false;
    },
    focus: function () {
      this.focusState = true;
    }
  }
}
</script>

<style lang="scss">
.address {
  position: relative;

  &-select {
    display: block;
    width: 100%;

    &.error {
      .el-input__inner {
        background-color: #fde2e2 !important;
        border-color: #F56C6C !important;;
        color: white !important;;
      }
    }
  }
}
</style>
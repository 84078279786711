<template>
  <section class="cabinet-index-page">
    <div class="cabinet-index-page_profile">
      <div class="cabinet-index-page_profile__avatar"></div>
      <div class="cabinet-index-page_profile__info">
        <div class="full-name">{{ user.fullName }}</div>
        <div class="email">{{ user.email }}</div>
        <div class="phone">{{ $filters.phoneFormat(user.phone) }}</div>
      </div>
      <div class="cabinet-index-page_profile__edit">
        <router-link :to="{name: 'ProfileIndex'}">
          <img src="../../assets/icons/icon-pencil.svg" alt="Редактировать">
        </router-link>
      </div>
    </div>
    <div class="cabinet-index-page_items">
      <router-link :to="{name: 'CabinetAddress'}" class="cabinet-index-page_items__item">
        <!--        <span class="icon"></span>-->
        <span class="title">
            <span class="title-first">Мои адреса</span>
            <span class="title-sub"
                  v-if="mainAddress">{{
                `${mainAddress.city.name}, ${mainAddress.street}, ${mainAddress.build}`
              }}</span>
          </span>
        <span class="icon to-next"><img src="../../assets/icons/icon-to-next.svg" alt="Далее"></span>
      </router-link>
      <router-link :to="{name: 'CabinetOrderIndex'}" class="cabinet-index-page_items__item">
        <!--        <span class="icon"></span>-->
        <span class="title">
            <span class="title-first">Мои заказы</span>
            <span class="title-sub">Всего {{ ordersCount }}</span>
        </span>
        <span class="icon to-next"><img src="../../assets/icons/icon-to-next.svg" alt="Далее"></span>
      </router-link>
      <order-widget v-if="ordersCount > 0"/>
      <router-link :to="{name: 'CabinetSupportIndex'}" class="cabinet-index-page_items__item">
        <!--        <span class="icon"></span>-->
        <span class="title">
            <span class="title-first">Обратная связь</span>
            <span class="title-sub">Whatsapp, Контакты, FAQ</span>
          </span>
        <span class="icon to-next"><img src="../../assets/icons/icon-to-next.svg" alt="Далее"></span>
      </router-link>
    </div>
  </section>
</template>

<script>
import {mapState, mapGetters, mapActions} from "vuex";
import OrderWidget from "@/views/cabinet/components/OrderWidget";

export default {
  name: "CabinetIndexPage",
  components: {OrderWidget},
  computed: {
    ...mapState({
      user: state => state.user.user,
      ordersCount: state => state.order.meta.size
    }),
    ...mapGetters({
      mainAddress: 'address/SELECTED_ADDRESS'
    })
  },
  beforeMount() {
    this.getAddress();
    this.fetchOrders({
      page: {
        limit: 5,
        offset: 0
      },
      filter: {
        user_id: this.user.id
      }
    });
  },
  methods: {
    ...mapActions({
      getAddress: 'address/FETCH_ADDRESS_LIST',
      fetchOrders: 'order/FETCH_ORDERS_BY_API'
    })
  }
}
</script>

<style lang="scss" scoped>
.cabinet-index-page {
  &_profile {
    background-color: #029D95;
    display: flex;
    flex-direction: row;
    padding: 21px 16px;

    &__avatar {
      border-radius: 48px;
      width: 48px;
      height: 48px;
      background-color: #FFFBFB;
    }

    &__info {
      flex: 1;
      margin-left: 16px;

      .full-name {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: #FFFFFF;
        margin-bottom: 4px;
      }

      .email {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        margin-bottom: 10px;
      }

      .phone {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        color: #FFFFFF;
      }
    }

    &__edit {
      width: 28px;
      height: 28px;
    }
  }

  &_items {
    display: flex;
    flex-direction: column;

    &__item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      justify-items: flex-start;
      align-items: center;
      align-content: center;
      padding: 11px 16px;
      text-decoration: none;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: #000000;
      //border-top: 1px solid #C4C4C4;
      border-bottom: 1px solid #c4c4c44d;

      &:nth-child(2n) {
        border-bottom: none;
      }

      span {
        display: block;
      }

      .icon {
        width: 20px;
        margin-right: 20px;

        &.to-next {
          width: 14px;
        }
      }

      .title {
        flex: 1;
        display: flex;
        flex-direction: column;

        &-first {
          display: block;
          margin-bottom: 4px;
        }

        &-sub {
          display: block;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #818C99;
        }
      }
    }
  }
}
</style>
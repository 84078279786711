<template>
  <div class="review">
    <div class="review_customer">
      <div class="review_head">
        <span class="review_head__name">{{ comment.name }}, </span>
        <span class="review_head__date">{{ comment.createdAt }}</span>
      </div>
      <div class="review_body" v-html="comment.comment">
      </div>
    </div>
    <div class="review_answer" v-if="comment.answer">
      <div class="review_head">
        <span class="review_head__name">Lensmark, </span>
        <span class="review_head__date">{{ comment.answeredAt }}</span>
      </div>
      <div class="review_body" v-html="comment.answer">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Comment",
  props: ['comment']
}
</script>

<style lang="scss" scoped>
.review {
  margin-bottom: 38px;

  &_head {
    display: block;

    &__name {
      position: relative;
      display: inline;
      font-size: 17px;
      color: #357184;
      line-height: 1.5;
      text-align: left;
    }

    &__date {
      display: inline;
      font-size: 13px;
      color: #596467;
    }
  }

  &_body {
    padding-top: 8px;
    font-size: 15px;
    color: #6d7f85;
    line-height: 1.4;
    text-align: left;
  }
}
</style>
<template>
  <section class="main-top-bar-navigation">
    <div class="container">
      <div class="main-top-bar-navigation_items">
        <div class="logo">
          <img src="../../assets/logo.svg" width="147" alt="Lensmark">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MainTopBar"
}
</script>

<style lang="scss" scoped>
.main-top-bar-navigation {
  flex: 1;

  &_items {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;

    button {
      border: none;
      background-color: transparent;
      margin: 0 8px 0 0;
      padding: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
export default function (client) {
    return {
        getList: async function () {
            return await client.get('/personal/address');
        },
        fetchOne: async function (id) {
            return await client.get(`/personal/address/${id}`);
        },
        add: async function (data) {
            return await client.post('/personal/address', data);
        },
        update: async function (id, data) {
            return await client.put(`/personal/address/${id}`, data);
        },
        drop: async function (id) {
            return await client.delete(`/personal/address/${id}`);
        },
        choose: async function (id, isSelected) {
            return await client.put(`/personal/address/choose/${id}`, {
                is_selected: isSelected
            });
        }
    }
}
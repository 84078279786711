<template>
  <div class="user-info checkout-block bg-danger bg-opacity-10">
    <div class="user-info_header">Контактные данные:</div>
    <div class="user-info_footer">
      <router-link :to="{name: 'ProfileIndex'}" class="link">Заполнить контактные данные</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserInfo"
}
</script>

<style lang="scss" scoped>
.user {
  &-info {
    &_header {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 10px;
    }

    &_footer {
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .link {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #029D95;
    }
  }
}
</style>
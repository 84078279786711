import {createStore} from 'vuex';
import Cart from '@/store/modules/cart';
import Order from '@/store/modules/order';
import {Catalog} from "@/store/modules/catalog/index";
import User from "@/store/modules/user";
import {Banner} from "@/store/modules/banner";
import Address from "@/store/modules/personal/address";
import City from "@/store/modules/geo/city";

export default createStore({
    state: {
        pageMeta: {
            title: ""
        },
        isAuthorized: false
    },
    mutations: {
        SET_AUTH_STATE: function (state, value) {
            state.isAuthorized = value;
        },
        SET_PAGE_META: function (state, payload) {
            state.pageMeta = payload;
        }
    },
    actions: {
        SET_PAGE_META: function ({commit}, payload) {
            commit("SET_PAGE_META", payload);
        },
        INIT_AUTHORIZED_FLOW: function (ctx) {
            ctx.dispatch('user/FETCH_PROFILE').then(() => {
                ctx.commit('SET_AUTH_STATE', true);
                ctx.dispatch('address/FETCH_ADDRESS_LIST');
                ctx.dispatch('cart/FETCH_MY_CART');
            });
        }
    },
    modules: {
        user: User,
        cart: Cart,
        address: Address,
        order: Order,
        catalog: Catalog,
        banner: Banner,
        city: City
    }
})

<template>
  <div class="basket" v-show="cart.items.length > 0 && !['CartIndex', 'CheckoutIndex', 'ProfileIndex'].includes($route.name)">
    <router-link :to="{name: 'CartIndex'}" class="btn">
      <span class="to-cart">В корзину</span>
      <span class="total-amount">{{$filters.priceFormat(cart.totalAmount)}} ₸</span>
    </router-link>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "BasketBottomWidget",
  computed: {
    ...mapState({
      cart: state => state.cart.cart
    })
  }
}
</script>

<style lang="scss" scoped>
.basket {
  background-color: white;
  position: fixed;
  z-index: 998;
  bottom: 60px;
  left: 0;
  right: 0;
  padding: 16px;
  box-shadow: 0px -4px 10px #00000033;
  border-radius: 15px 15px 0 0;
  transition: .3s linear;

  .btn {
    position: relative;
    background-color: #029D95;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    font-style: normal;
    font-weight: bolder;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    padding: 14px 16px;

    .to-cart {
    }
    .total-amount {
      position: absolute;
      right: 16px;
    }
  }
}
</style>
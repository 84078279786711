<template>
  <div class="profile_choose-page">
    <div class="alert-box">
      <el-alert title="У вас на сайте Lensmark.kz зарегистрированно несколько аккаунтов по данному номеру."
                type="warning"
                :closable="false"></el-alert>
    </div>
    <div class="profile_choose-page_items">
      <div class="profile_choose-page_items__item"
           v-for="(account, key) in accounts"
           :key="key"
           v-on:click="choose(account.id, account.externalId)">
        <el-avatar :size="50" src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"></el-avatar>
        <div class="info">
          <div class="name">{{ `${account.firstName} ${account.lastName}` }}</div>
          <div class="email">{{ account.email }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {AuthToken} from "@/shared/services/storage/models/AuthToken";
import {mapState, mapActions} from "vuex";
import {api} from "@/shared/services/api";
import {NotifyCenter} from "@/shared/services/notify";

export default {
  name: "CabinetProfileChoose",
  computed: {
    ...mapState({
      accounts: state => state.user.accounts
    })
  },
  methods: {
    ...mapActions({
      initAuthorizedFlow: "INIT_AUTHORIZED_FLOW"
    }),
    choose: function (id, externalId) {
      api.lensmark.user.chooseAccount({
        user_id: id,
        external_id: externalId,
        access_token: AuthToken.getAccessToken(),
        refresh_token: AuthToken.getRefreshToken(),
        token_type: AuthToken.getTokenType(),
        expire_in: AuthToken.getExpireIn()
      }).then((rs) => {
        NotifyCenter.push('success', rs.data.message, true);
        this.initAuthorizedFlow();
        this.$router.push('/cabinet');
      });
    }
  },
  beforeMount() {
    console.log(this.accounts);
  }
}
</script>

<style lang="scss" scoped>
.profile_choose-page {
  .alert-box {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  &_title {
    text-align: center;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 16px;
  }

  &_subtitle {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &_items {
    padding-top: 16px;

    &__item {
      padding: 10px 16px;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 16px;
      grid-row-gap: 16px;
      box-shadow: inset 0px -0.3px 0px #C4C4C4;

      &.active {
        box-shadow: inset 0px -1px 0px #029D95;
      }

      .name {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
      }

      .email {
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        margin-top: 6px;
      }
    }
  }
}
</style>
<template>
  <div class="names">
    <el-tag
        v-for="(tag, i) in names"
        :key="i"
        :effect="tag.active ? `dark` : `plain`"
        v-on:click="selectName(tag.label)"
        :hit="true"
        class="names-tag">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="tag.icon" v-html="tag.icon">
      </svg>
      {{ tag.label }}
    </el-tag>
    <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="small"
        :input-style="{height: '36px', borderColor: '#029D95', fontWeight: 'normal', fontSize: '14px'}"
        @keyup.enter="handleInputConfirm"
        @blur="handleInputConfirm"
    >
    </el-input>
    <el-button v-else class="names-tag button-new-name" size="small" @click="showInput">
      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 15L7 12.82L2 15V2H12V15ZM12 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18L7 15L14 18V2C14 0.89 13.1 0 12 0Z" fill="#029D95"/>
      </svg>
      Другой
    </el-button>
  </div>
</template>

<script>
export default {
  name: "InputNames",
  props: ['propName'],
  data() {
    return {
      names: [
        {
          label: 'Дом',
          icon: '<path d="M10 2.69L15 7.19V15H13V9H7V15H5V7.19L10 2.69ZM10 0L0 9H3V17H9V11H11V17H17V9H20L10 0Z"/>',
          active: true
        },
        {
          label: 'Работа',
          icon: '<path d="M18 4C18.58 4 19.05 4.2 19.42 4.59C19.8 5 20 5.45 20 6V17C20 17.55 19.8 18 19.42 18.41C19.05 18.8 18.58 19 18 19H2C1.42 19 0.95 18.8 0.58 18.41C0.2 18 0 17.55 0 17V6C0 5.45 0.2 5 0.58 4.59C0.95 4.2 1.42 4 2 4H6V2C6 1.42 6.2 0.95 6.58 0.58C6.95 0.2 7.42 0 8 0H12C12.58 0 13.05 0.2 13.42 0.58C13.8 0.95 14 1.42 14 2V4H18ZM2 6V17H18V6H2ZM12 4V2H8V4H12Z"/>',
          active: false
        }
      ],
      inputVisible: false,
      inputValue: ''
    }
  },
  methods: {
    selectName: function (label) {
      this.names.forEach((tag) => {
        tag.active = tag.label === label;
      });
      this.$emit('selectName', label);
    },
    handleClose(tag) {
      this.names.splice(this.names.indexOf(tag), 1)
    },
    showInput() {
      this.inputVisible = true
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue && !this.names.find((name) => {
        return name.label === inputValue
      })) {
        this.resetActiveNames(inputValue);

        this.names.push({
          label: inputValue,
          icon: '',
          active: true
        });
        this.$emit('selectName', inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    resetActiveNames: function (inputValue) {
      this.names.forEach((name) => {
        if (name.active && name.label !== inputValue) {
          name.active = false;
        }
      });
    }
  },
  created() {
    let activeName = this.names.find((name) => {
      return name.active;
    });
    this.$emit('selectName', activeName.label ?? null);
    if (this.propName && !this.names.find((name) => {
      return name.label === this.propName;
    })) {
      this.resetActiveNames(this.propName);

      this.names.push({
        label: this.propName,
        icon: '',
        active: true
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.names {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;

  &-tag {
    padding: 8px;
    border-color: #029D95 !important;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #029D95;
    height: 36px;

    svg {
      fill: #029D95;
    }

    &.el-tag--dark {
      background-color: #029D95;
      color: white;
      border-color: white !important;

      svg {
        fill: white;
      }
    }
  }

  .input-new-tag {
    width: auto;
  }
}
</style>